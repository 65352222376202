import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getBusinesses } from '../../../services/business/getBusinesses';
import { getLocations } from '../../../services/location/getLocations';
import { getHuntItems } from '../../../services/huntitem/getHuntItems';
import { updateLocation } from '../../../services/location/updateLocation';
import { deleteHuntItem } from '../../../services/huntitem/deleteHuntItem';
import { Location } from '../../../types/Location';
import { Business } from '../../../types/Business';
import { HuntItem } from '../../../types/HuntItem';
import { HuntItemsTab } from './HuntItemsTab';

export const BusinessLocations: React.FC = () => {
  const { t } = useTranslation();
  const [business, setBusiness] = useState<Business | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [huntItems, setHuntItems] = useState<HuntItem[]>([]);

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const businessesData = await getBusinesses(1, 1, undefined, undefined, undefined);
        if (businessesData.businesses.length > 0) {
          setBusiness(businessesData.businesses[0]);
        }
      } catch (error) {
        console.error('Error fetching business:', error);
      }
    };

    fetchBusiness();
  }, []);

  useEffect(() => {
    const fetchBusinessLocation = async () => {
      try {
        const locationsData = await getLocations(1, 1, undefined, undefined, undefined, [business?.id || 999999]);
        if (locationsData.locations.length > 0) {
          setLocation(locationsData.locations[0]);
        }
      } catch (error) {
        console.error('Error fetching business location:', error);
      }
    };

    fetchBusinessLocation();
  }, [business]);

  useEffect(() => {
    if (location) {
      const fetchHuntItems = async () => {
        try {
          const huntItemsData = await getHuntItems(1, 1000, undefined, undefined, 'ASC', location.id);
          setHuntItems(huntItemsData.huntItems);
        } catch (error) {
          console.error('Error fetching hunt items:', error);
        }
      };

      fetchHuntItems();
    }
  }, [location]);

  const handleHuntItemsChange = async (updatedHuntItems: HuntItem[]) => {
    if (!location) return;

    try {
      // Find deleted hunt items
      const deletedHuntItems = huntItems.filter(item => !updatedHuntItems.some(updatedItem => updatedItem.id === item.id));

      // Delete hunt items from the backend
      for (const deletedItem of deletedHuntItems) {
        if (deletedItem.id) {
          await deleteHuntItem(deletedItem.id);
        }
      }

      // Update the location with the new hunt items
      const updatedLocation = {
        ...location,
        huntItems: updatedHuntItems
      };

      await updateLocation(location.id, updatedLocation, null);

      // Fetch updated hunt items
      const huntItemsData = await getHuntItems(1, 1000, undefined, undefined, 'ASC', location.id);
      setHuntItems(huntItemsData.huntItems);
    } catch (error) {
      console.error('Error updating hunt items:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  if (!location) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" fontStyle="italic" textAlign="center">
          {t('admin.locations.pleaseSetupBusiness')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {location?.name}
      </Typography>
      <HuntItemsTab
        huntItems={huntItems}
        onHuntItemsChange={handleHuntItemsChange}
        location={location || undefined}
      />
    </Box>
  );
};
