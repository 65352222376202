import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { User } from '../types/User';
import { useNavigate } from 'react-router-dom';
import TokenExpirationDialog from '../components/common/TokenExpirationDialog';
import { jwtDecode, JwtPayload } from 'jwt-decode';

type AuthContextType = {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
  loginWithRedirect: (username: string, password: string) => Promise<User>;
  signInWithRedirect: (username: string, password: string) => Promise<User>;
  loginWithGoogle: (tokenId: string) => Promise<User>;
  logout: (options?: { logoutParams?: { returnTo: string } }) => void;
  setUserAndToken: (user: User, token: string) => void;
};

const initialAuthState: AuthContextType = {
  isAuthenticated: false,
  user: null,
  isLoading: true,
  loginWithRedirect: async () => ({} as User),
  signInWithRedirect: async () => ({} as User),
  loginWithGoogle: async () => ({} as User),
  logout: () => {},
  setUserAndToken: () => {},
};

const AuthContext = createContext<AuthContextType>(initialAuthState);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showExpirationDialog, setShowExpirationDialog] = useState(false);
  const baseApiUrl = process.env.REACT_APP_ENVIRONMENT_NAME === 'local' ? 'http://localhost:3102/' : '/';
  const navigate = useNavigate();
  const tokenCheckInterval = useRef<NodeJS.Timeout | null>(null);
  const handleTokenExpiration = useCallback(() => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    setShowExpirationDialog(true);
    if (tokenCheckInterval.current) {
      clearInterval(tokenCheckInterval.current);
      tokenCheckInterval.current = null;
    }
  }, []);

  const checkAuthStatus = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await fetch(`${baseApiUrl}api/validate-token`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
          setIsAuthenticated(true);
          const userData: User = await response.json();
          setUser(userData);
        } else {
          console.log('Token validation failed');
          localStorage.removeItem('token');
          handleTokenExpiration();        }
      } else {
        console.log('No token found, user is not authenticated');
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsAuthenticated(!!localStorage.getItem('token'));
    } finally {
      setIsLoading(false);
    }
  }, [baseApiUrl, handleTokenExpiration]);

  const checkTokenExpiration = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode<JwtPayload>(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          handleTokenExpiration();
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        handleTokenExpiration();
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
    setIsLoading(false);
  }, [handleTokenExpiration]);

  const startTokenCheckInterval = useCallback(() => {
    if (tokenCheckInterval.current) {
      clearInterval(tokenCheckInterval.current);
    }
    tokenCheckInterval.current = setInterval(() => {
      checkTokenExpiration();
    }, 5000); // Check every 5 seconds
  }, [checkTokenExpiration]);

  const logout = useCallback((options?: { logoutParams?: { returnTo: string } }) => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    if (tokenCheckInterval.current) {
      clearInterval(tokenCheckInterval.current);
      tokenCheckInterval.current = null;
    }
    navigate('/');

    if (options?.logoutParams?.returnTo) {
      window.location.href = options.logoutParams.returnTo;
    }
  }, [navigate]);

  // To be used by the resetPassword component
  const setUserAndToken = useCallback((user: User, token: string) => {
    setUser(user);
    setIsAuthenticated(true);
    localStorage.setItem('token', token);
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await fetch(`${baseApiUrl}api/user`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
          const userData: User = await response.json();
          setUser(userData);
        } else {
          console.error('Failed to fetch user data');
          // If the token is invalid, we should log out the user
          if (response.status === 401) {
            logout();
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [baseApiUrl, logout]);

  useEffect(() => {
    const initAuth = async () => {
      await checkAuthStatus(); // Check auth status immediately on mount
      startTokenCheckInterval(); // Start the interval for subsequent checks
    };

    initAuth();

    return () => {
      if (tokenCheckInterval.current) {
        clearInterval(tokenCheckInterval.current);
      }
    };
  }, [checkAuthStatus, startTokenCheckInterval]);

  // Refetch user data if the user is authenticated and we are missing data
  useEffect(() => {
    if (isAuthenticated && (!user || !user.email || !user.role)) {
      fetchUserData();
    }
  }, [isAuthenticated, user, fetchUserData]);

  const loginWithRedirect = async (username: string, password: string): Promise<User> => {
    try {
      const response = await fetch(`${baseApiUrl}api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, isGoogleLogin: false }),
      });
      if (response.ok) {
        const { token, user }: { token: string; user: User } = await response.json();
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        setUser(user);
        startTokenCheckInterval();
        return user;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const signInWithRedirect = async (email: string, password: string): Promise<User> => {
    try {
      const response = await fetch(`${baseApiUrl}api/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, isGoogleSignup: false }),
      });
      if (response.ok) {
        const { token, user }: { token: string; user: User } = await response.json();
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        setUser(user);
        startTokenCheckInterval();
        return user;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      console.error('Sign up error:', error);
      throw error;
    }
  };

  const loginWithGoogle = async (tokenId: string): Promise<User> => {
    try {
      const response = await fetch(`${baseApiUrl}api/auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tokenId, isGoogleSignup: true }),
      });
      if (response.ok) {
        const { token, user }: { token: string; user: User } = await response.json();
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        setUser(user);
        startTokenCheckInterval();
        return user;
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  };

  const value: AuthContextType = {
    isAuthenticated,
    user,
    loginWithRedirect,
    signInWithRedirect,
    loginWithGoogle,
    logout,
    setUserAndToken,
    isLoading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      <TokenExpirationDialog
        open={showExpirationDialog}
        onClose={() => setShowExpirationDialog(false)}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);