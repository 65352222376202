import React, { useState, useEffect } from 'react';
import { Control, FieldErrors, Controller, UseFormGetValues, UseFormSetValue, UseFormTrigger, useForm } from 'react-hook-form';
import { Paper, Typography, Box, MenuItem, Tooltip, IconButton } from '@mui/material';
import { ResponsiveTextField, ImageUploadModal } from '../../../../components/common';
import { StateSelector } from '../../../../components/common/StateSelector';
import { MapCoordinatesPicker } from '../../../../components/common/MapCoordinatesPicker';
import { HAWAIIAN_ISLANDS } from '../../../../types/Location';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import { BusinessFormData } from './types';
import { useTranslation } from 'react-i18next';

type LocationInfoFormProps = {
  control: Control<BusinessFormData>;
  errors: FieldErrors<BusinessFormData>;
  originalValues: BusinessFormData | null;
  setValue: UseFormSetValue<BusinessFormData>;
  trigger: UseFormTrigger<BusinessFormData>;
  getValues: UseFormGetValues<BusinessFormData>;
  onLocationImageFileChange: (file: File | null) => void;
  isAdminModal?: boolean;
};

export const LocationInfoForm: React.FC<LocationInfoFormProps> = ({
  control,
  errors,
  originalValues,
  setValue,
  trigger,
  getValues,
  onLocationImageFileChange,
  isAdminModal = false,
}) => {
  const { t } = useTranslation();
  const [locationImagePreview, setLocationImagePreview] = useState<string | undefined>(
    originalValues?.location?.locationImage as string | undefined
  );

  useEffect(() => {
    setLocationImagePreview(originalValues?.location?.locationImage as string | undefined);
  }, [originalValues]);

  const handleLocationImageCapture = (file: File | null) => {
    setValue('location.locationImage', file as File | null, { shouldDirty: true });

    onLocationImageFileChange(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setLocationImagePreview(e.target?.result as string);
      reader.readAsDataURL(file);
    } else {
      setLocationImagePreview(undefined);
    }
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    const shouldMarkDirty =
      lat !== originalValues?.location?.latitude ||
      lng !== originalValues?.location?.longitude;

    setValue('location.latitude', lat, { shouldDirty: shouldMarkDirty });
    setValue('location.longitude', lng, { shouldDirty: shouldMarkDirty });
    trigger(['location.latitude', 'location.longitude']);
  };

  const getAddressString = () => {
    const values = getValues();
    return `${values.location.streetAddress}, ${values.location.city}, ${values.location.state} ${values.location.zipCode}`;
  };

  const { watch } = useForm();
  const coordinates = watch(['location.latitude', 'location.longitude']);

  const content = (
    <>
      <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))' }}>
        <Controller
          name="location.name"
          control={control}
          render={({ field }) => (
            <ResponsiveTextField
              {...field}
              label={t('admin.businesses.locationName')}
              fullWidth
              required
              error={!!errors.location?.name}
              value={field.value || ''}
              helperText={errors.location?.name?.message}
            />
          )}
        />
        <Controller
          name="location.streetAddress"
          control={control}
          render={({ field }) => (
            <ResponsiveTextField
              {...field}
              label={t('admin.businesses.streetAddress')}
              fullWidth
              required
              value={field.value || ''}
              error={!!errors.location?.streetAddress}
              helperText={errors.location?.streetAddress?.message}
            />
          )}
        />
        <Controller
          name="location.city"
          control={control}
          render={({ field }) => (
            <ResponsiveTextField
              {...field}
              label={t('admin.businesses.city')}
              fullWidth
              required
              value={field.value || ''}
              error={!!errors.location?.city}
              helperText={errors.location?.city?.message}
            />
          )}
        />
        <Controller
          name="location.state"
          control={control}
          render={({ field }) => (
            <StateSelector
              {...field}
            />
          )}
        />
        <Controller
          name="location.zipCode"
          control={control}
          render={({ field }) => (
            <ResponsiveTextField
              {...field}
              label={t('admin.businesses.zipCode')}
              fullWidth
              required
              value={field.value || ''}
              error={!!errors.location?.zipCode}
              helperText={errors.location?.zipCode?.message}
            />
          )}
        />
        <Controller
          name="location.areaName"
          control={control}
          render={({ field }) => (
            <ResponsiveTextField
              {...field}
              select
              label={t('admin.locations.islandName')}
              fullWidth
              required
              error={!!errors.location?.areaName}
              helperText={errors.location?.areaName?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={t('admin.locations.islandNameTooltip')}>
                      <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                        <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              {HAWAIIAN_ISLANDS.map((island) => (
                <MenuItem key={island} value={island}>
                  {island}
                </MenuItem>
              ))}
            </ResponsiveTextField>
          )}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Controller
          name="location.description"
          control={control}
          render={({ field }) => (
            <ResponsiveTextField
              {...field}
              label={t('admin.businesses.locationDescription')}
              fullWidth
              required
              multiline
              rows={4}
              value={field.value || ''}
              error={!!errors.location?.description}
              helperText={errors.location?.description?.message}
            />
          )}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <MapCoordinatesPicker
          latitude={Number(coordinates[0]) || Number(originalValues?.location?.latitude) || 0}
          longitude={Number(coordinates[1]) || Number(originalValues?.location?.longitude) || 0}
          onCoordinatesChange={handleCoordinatesChange}
          title={t('admin.businesses.locationCoordinates')}
          getAddressString={getAddressString}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Controller
          name="location.locationImage"
          control={control}
          render={({ field }) => (
            <Box>
              <Tooltip title={t('admin.businesses.locationImageTooltip')}>
                <InfoOutlinedIcon color="action" sx={{ mb: -10, ml: 2, cursor: 'pointer' }} />
              </Tooltip>
              <ImageUploadModal
                {...field}
                title={t('admin.businesses.locationImage')}
                onImageCapture={handleLocationImageCapture}
                existingImageUrl={locationImagePreview}
              />
            </Box>
          )}
        />
        {errors.location?.locationImage && <Typography color="error">{t('validation.required')}</Typography>}
      </Box>
    </>
  );

  if (isAdminModal) {
    return content;
  }

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('admin.businesses.locationInfo')}
      </Typography>
      {content}
    </Paper>
  );
};
