import React from 'react';
import { useRole } from '../../../hooks/useRole';
import { Roles } from '../../../types/Roles';
import { AdminLocations } from './adminIndex';
import { BusinessLocations } from './businessIndex';

export const Locations: React.FC = () => {
  const role = useRole();

  if (role === Roles.Business) {
    return <BusinessLocations />;
  }

  return <AdminLocations />;
};
