import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton, Box, Stack, Tooltip } from '@mui/material';
import { Close as CloseIcon, CheckCircle as CheckCircleIcon, Cancel as CancelIcon, LocationOn, QrCode2, Quiz, Facebook, Instagram, Twitter } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ItemType } from '../../types/HuntItem';

interface CompletionDialogProps {
  open: boolean;
  onClose: () => void;
  itemCompleted: boolean;
  huntCompleted: boolean;
  itemTitle: string;
  itemType: ItemType;
}

const CompletionDialog: React.FC<CompletionDialogProps> = ({
  open,
  onClose,
  itemCompleted,
  huntCompleted,
  itemTitle,
  itemType,
}) => {
  const { t } = useTranslation();

  const getDialogContent = () => {
    if (huntCompleted) {
      return t('activeHunt.huntCompleted');
    } else if (itemCompleted) {
      return t('activeHunt.itemCompleted', { title: itemTitle });
    } else {
      switch (itemType) {
        case ItemType.GPSLocation:
          return t('activeHunt.incorrectLocation');
        case ItemType.QRCode:
          return t('activeHunt.incorrectQRCode');
        case ItemType.Question:
          return t('activeHunt.incorrectAnswer');
        default:
          return t('activeHunt.incorrectSolution');
      }
    }
  };

  const getIcon = () => {
    if (huntCompleted || itemCompleted) {
      return <CheckCircleIcon sx={{ fontSize: 48, color: 'white' }} />;
    } else {
      switch (itemType) {
        case ItemType.GPSLocation:
          return <LocationOn sx={{ fontSize: 48, color: 'white' }} />;
        case ItemType.QRCode:
          return <QrCode2 sx={{ fontSize: 48, color: 'white' }} />;
        case ItemType.Question:
          return <Quiz sx={{ fontSize: 48, color: 'white' }} />;
        default:
          return <CancelIcon sx={{ fontSize: 48, color: 'white' }} />;
      }
    }
  };

  const isSuccess = itemCompleted || huntCompleted;

  const handleShare = (platform: 'twitter' | 'facebook' | 'instagram') => {
    const message = encodeURIComponent(t('social.shareMessage'));
    const hashtags = encodeURIComponent('HulaHunt,Hawaii,Scavenger');

    const urls = {
      twitter: `https://twitter.com/intent/tweet?text=${message}&hashtags=${hashtags}&url=https://hulahunt.com&via=HuntHula71556`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=https://hulahunt.com`,
      instagram: `https://www.instagram.com/hulahuntman/`, // Direct link as Instagram doesn't support sharing via URL
    };

    window.open(urls[platform], '_blank', 'width=600,height=400');
  };

  const renderSocialButtons = () => {
    if (!huntCompleted) return null;

    return (
      <Box sx={{ mt: 2, mb: 1 }}>
        <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
          {t('social.shareYourSuccess')}
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Tooltip title={t('social.shareOnTwitter')}>
            <IconButton
              onClick={() => handleShare('twitter')}
              sx={{
                color: '#1DA1F2',
                '&:hover': { backgroundColor: 'rgba(29, 161, 242, 0.1)' }
              }}
            >
              <Twitter />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('social.shareOnFacebook')}>
            <IconButton
              onClick={() => handleShare('facebook')}
              sx={{
                color: '#4267B2',
                '&:hover': { backgroundColor: 'rgba(66, 103, 178, 0.1)' }
              }}
            >
              <Facebook />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('social.followOnInstagram')}>
            <IconButton
              onClick={() => handleShare('instagram')}
              sx={{
                color: '#E4405F',
                '&:hover': { backgroundColor: 'rgba(228, 64, 95, 0.1)' }
              }}
            >
              <Instagram />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{mx: 2}}
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
        }
      }}
    >
      <DialogTitle sx={{
        m: 0,
        p: 1,
        backgroundColor: isSuccess ? 'success.main' : 'primary.main',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {getIcon()}
            <Typography variant="h6" sx={{ ml: 1 }}>
              {isSuccess ? t('common.success') : t('common.tryAgain')}
            </Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2, textAlign: 'center' }}>
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {getDialogContent()}
        </Typography>
        {renderSocialButtons()}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
        <Button
          onClick={onClose}
          variant="contained"
          color={isSuccess ? "success" : "primary"}
          sx={{
            minWidth: 120,
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: isSuccess ? 'success.dark' : 'primary.dark',
            }
          }}
        >
          {isSuccess ? t('common.continue') : t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompletionDialog;