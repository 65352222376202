// Any routes added to this file also need to be added to frontend/serve.json
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import FindHunts from '../pages/FindHunts';
import AdminPage from '../pages/Admin';
import LoginSignup from '../pages/LoginSignup';
import Profile from '../pages/Profile';
import MyHunts from '../pages/MyHunts';
import PasswordReset from '../pages/ResetPassword';
import PurchaseCancel from '../pages/PurchaseCancel';
import PurchaseSuccess from '../pages/PurchaseSuccess';
import MyCoupons from '../pages/MyCoupons';
import ProtectedRoute from '../components/ProtectedRoute';
import { ActiveHuntPage } from '../pages/ActiveHunt';
import PrivacyContent from '../components/PrivacyContent';
import TermsContent from '../components/TermsContent';
import { useTranslation } from 'react-i18next';
import FeaturedBusinesses from '../pages/FeaturedBusinesses';

const AppRoutes: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route path="/privacy" element={
        <>
          <h1>{t('footer.privacyTitle')}</h1>
          <div dangerouslySetInnerHTML={{ __html: PrivacyContent }} />
        </>
        } />
      <Route path="/terms" element={
        <>
          <h1>{t('footer.termsTitle')}</h1>
          <div dangerouslySetInnerHTML={{ __html: TermsContent }} />
        </>
        } />
      <Route path = "/admin"
        element={
          <ProtectedRoute requiredRole="business">
            <AdminPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <LandingPage />
        }
      />
      <Route path="/resetPassword" element={<PasswordReset />} />
      <Route path="/login" element={<LoginSignup />} />
      <Route path="/signup" element={<LoginSignup />} />
      <Route path='/purchase-cancel' element={<PurchaseCancel />} />
      <Route path='/purchase-success' element={<PurchaseSuccess />} />
      <Route path='/activehunt/:huntId' element={<ActiveHuntPage />} />
      <Route path='/my-coupons' element={<MyCoupons />} />
      <Route path='/featuredbusinesses' element={<FeaturedBusinesses />} />
      <Route
        path='/myhunts'
        element={
          <ProtectedRoute>
            <MyHunts />
          </ProtectedRoute>
        }
      />
      {/* Consumer landing page is available to all users */}
      <Route
        path="/findhunts"
        element={
          <FindHunts/>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;