import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getUsers, SortColumn, SortOrder } from '../../../services/user/getUsers';
import { deleteUser } from '../../../services/user/deleteUser';
import { User } from '../../../types/User';
import { ConfirmationDialog, ResponsiveTextField, PaginationContainer } from '../../../components/common';
import { useAuth } from '../../../context/AuthContext';
import { RoleType } from '../../../types/Roles';
import { CreateUserModal } from './CreateUserModal';
import { EditUserModal } from './EditUserModal';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { format } from 'date-fns';

const getUserFromId = (id: number, users: User[]): User | null => {
  return users.find((user) => user.id === id) || null;
};

export const Users: React.FC = () => {
  const isMobile = useIsMobile();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 7 : 10);
  const [totalUsers, setTotalUsers] = useState(1);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{open: boolean, user: User | null}>({
    open: false,
    user: null,
  });
  const [role, setRole] = useState<string>('all');
  const [userSearchString, setUserSearchString] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<SortColumn | ''>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>(1);
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

  const calculateRowsPerPage = useCallback(() => {
    const tableHeaderHeight = isMobile ? 250 : 300; // Approximate height of the table header
    const tableRowHeight = isMobile ? 48 : 66.8; // Approximate height of a table row
    const otherComponentsHeight = 300; // Approximate height of other components (search bar, pagination, etc.)

    const availableHeight = window.innerHeight - otherComponentsHeight;
    const maxRows = Math.floor((availableHeight - tableHeaderHeight) / tableRowHeight);

    return Math.max(5, Math.min(10, maxRows));
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(calculateRowsPerPage());
    };

    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateRowsPerPage]);

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setIsEditUserModalOpen(true);
  };

  const handleEditUserModalClose = () => {
    setIsEditUserModalOpen(false);
    setSelectedUser(null);
  };

  const handleUserUpdated = () => {
    setRefetchTrigger(prev => prev + 1);
  };

  const handleAddUser = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleCreateUserModalClose = () => {
    setIsCreateUserModalOpen(false);
  };

  const handleUserCreated = () => {
    setRefetchTrigger(prev => prev + 1);
  };

  useEffect(() => {
    getUsers(
      page,
      rowsPerPage,
      role === 'all' ? undefined : role as RoleType,
      userSearchString,
      sortColumn || undefined,
      sortOrder
    ).then((data) => {
      setUsers(data.users);
      setTotalUsers(data.totalUsers);
    });
  }, [page, rowsPerPage, refetchTrigger, role, userSearchString, sortColumn, sortOrder]);

  const handleDeleteConfirmation = (user: User | null) => {
    setDeleteConfirmation({ open: true, user });
  };

  const handleDeleteUser = () => {
    if (deleteConfirmation.user?.id) {
      deleteUser(deleteConfirmation.user.id).then(() => {
        console.log(`Deleted user with id: ${deleteConfirmation.user?.id}`);
        setRefetchTrigger(prev => prev + 1);
        setDeleteConfirmation({ open: false, user: null });
      }).catch(error => {
        console.error("Error deleting user:", error);
        // Handle error (e.g., show an error message to the user)
      });
    }
  };

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 1 ? -1 : 1);
    } else {
      setSortColumn(column);
      setSortOrder(1);
    }
  };

  return (
    <Box sx={{ p: { xs: 0, sm: 1 } }}>
      <Box sx={{ mb: 3, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddUser}
          sx={{
            width: { xs: '100%', sm: '300px' },
            alignSelf: 'flex-start'
          }}
        >
          Add User
        </Button>

        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'row' },
          width: { xs: '100%', sm: 'calc(100% - 300px)' },
          gap: 2
        }}>
          <ResponsiveTextField
            label="Search"
            variant="outlined"
            value={userSearchString}
            onChange={(e) => setUserSearchString(e.target.value)}
            sx={{
              flexGrow: 1,
              width: { xs: 'calc(100% - 120px)', sm: 'auto' }
            }}
          />
          <FormControl variant="outlined" size="small" sx={{ width: '120px' }}>
            <InputLabel>Role</InputLabel>
            <Select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              label="Role"
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="business">Business</MenuItem>
              <MenuItem value="consumer">Consumer</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              {!isMobile && (
                <>
                  <TableCell onClick={() => handleSort('name')} sx={{ cursor: 'pointer', fontSize: '0.85rem', px: 1 }}>
                    {t('admin.users.name')} {sortColumn === 'name' && (sortOrder === 1 ? '↑' : '↓')}
                  </TableCell>
                  <TableCell sx={{ fontSize: '0.85rem', px: 1 }}>
                    {t('admin.users.lastActive')}
                  </TableCell>
                </>
              )}
              <TableCell
                onClick={() => handleSort('email')}
                sx={{ maxWidth: { xs: '120px', sm: '200px' }, cursor: 'pointer', fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.users.email')} {sortColumn === 'email' && (sortOrder === 1 ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('role')}
                sx={{ maxWidth: { xs: '66px', sm: '90px' }, cursor: 'pointer', fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.users.role')} {sortColumn === 'role' && (sortOrder === 1 ? '↑' : '↓')}
              </TableCell>
              {/*{!isMobile && <TableCell>{t('admin.users.authProvider')}</TableCell>}*/}
              {!isMobile && <TableCell sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.users.business')}</TableCell>}
              <TableCell align="right" sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.users.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                {!isMobile && (
                  <>
                    <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{user.firstName} {user.lastName}</TableCell>
                    <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{user.lastActive ? format(new Date(user.lastActive), 'MM/dd/yyyy') : ''}</TableCell>
                  </>
                )}

                <TableCell sx={{
                  maxWidth: { xs: '120px', sm: '200px' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '0.75rem',
                  px: 1
                }}>
                  {user.email}
                </TableCell>
                <TableCell sx={{ maxWidth: { xs: '66px', sm: '90px' }, fontSize: '0.75rem', px: 1 }}>{user.role}</TableCell>
                {/*{!isMobile && <TableCell>{user.auth_provider}</TableCell>}*/}
                {!isMobile && <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{user.businessName}</TableCell>}
                <TableCell align="right" sx={{ fontSize: '0.75rem', px: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <IconButton onClick={() => handleEditUser(user)} size="small">
                      <EditIcon />
                    </IconButton>
                    {currentUser?.id !== user.id &&
                      user?.role !== 'admin' && (
                      <IconButton onClick={() => handleDeleteConfirmation(getUserFromId(user.id, users))} size="small">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationContainer
        totalItems={totalUsers}
        itemType="Users"
        role={role}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, newPage) => setPage(newPage)}
      />

      <CreateUserModal
        open={isCreateUserModalOpen}
        onClose={handleCreateUserModalClose}
        onUserCreated={handleUserCreated}
      />
      <EditUserModal
        open={isEditUserModalOpen}
        onClose={handleEditUserModalClose}
        onUserUpdated={handleUserUpdated}
        user={selectedUser}
      />
      <ConfirmationDialog
        open={deleteConfirmation.open}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${deleteConfirmation.user?.email}?`}
        onConfirm={handleDeleteUser}
        onCancel={() => setDeleteConfirmation({ open: false, user: null })}
      />
    </Box>
  );
};
