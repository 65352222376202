import * as z from 'zod';
import { allowedBusinessTypes } from '../../../../types/Business';
export const createBusinessSchema = (t: (key: string) => string) => z.object({
  name: z.string().min(3, { message: t('admin.businesses.validation.name') }),
  contactEmail: z.string().email({ message: t('common.validation.invalidEmail') }),
  phoneNumber: z.string().min(10, { message: t('admin.businesses.validation.phoneNumber') }),
  website: z.string().url({ message: t('common.validation.invalidUrl') }).optional().or(z.literal('')).or(z.null()),
  description: z.string().min(10, { message: t('admin.businesses.validation.description') }),
  welcomeMessage: z.string().min(10, { message: t('admin.businesses.validation.welcomeMessage') }),
  businessType: z.enum(allowedBusinessTypes as [string, ...string[]])
    .default('Other')
    .transform(val => (!val || val === '') ? 'Other' : val),
  businessImage: z.any().optional(),
  location: z.object({
    name: z.string().min(3, { message: t('admin.businesses.validation.location.name') }),
    streetAddress: z.string().min(6, { message: t('admin.businesses.validation.location.streetAddress') }),
    city: z.string().min(3, { message: t('admin.businesses.validation.location.city') }),
    state: z.string().min(2, { message: t('admin.businesses.validation.location.state') }),
    zipCode: z.string().min(5, { message: t('admin.businesses.validation.location.zipCode') }),
    areaName: z.string().min(3, { message: t('common.validation.required') }),
    description: z.string().min(10, { message: t('admin.businesses.validation.location.description') }),
    latitude: z.union([z.number(), z.string()]).transform(Number),
    longitude: z.union([z.number(), z.string()]).transform(Number),
    locationImage: z.any().optional(),
  }),
});
