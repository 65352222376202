import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReportIssueModal from './ReportIssueModal';
import PrivacyTermsModal from './PrivacyTermsModal';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const handleReportIssueClick = () => {
    setIsReportModalOpen(true);
  };

  const handleCloseReportModal = () => {
    setIsReportModalOpen(false);
  };

  const handlePrivacyClick = () => {
    setPrivacyModalOpen(true);
  };

  const handleTermsClick = () => {
    setTermsModalOpen(true);
  };

  return (
    <footer className="text-center align-text-bottom text-hulahunt-teal-700 text-sm pt-1 mt-2">
      <div className="flex justify-between items-center mb-2">
        <p className="text-left">{t('footer.copyright')}</p>
        <div className="flex space-x-5">
          <a
            href="https://x.com/HuntHula71556"
            target="_blank"
            rel="noopener noreferrer"
            className="text-hulahunt-teal-600 hover:text-hulahunt-teal-800"
            aria-label="Twitter"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://www.facebook.com/people/HulaHunt/61567696931047/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-hulahunt-teal-600 hover:text-hulahunt-teal-800"
            aria-label="Facebook"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/hulahuntman/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-hulahunt-teal-600 hover:text-hulahunt-teal-800"
            aria-label="Instagram"
          >
            <InstagramIcon />
          </a>
        </div>
      </div>
      <div className="mt-1 text-xs">
        <button
          onClick={handlePrivacyClick}
          className="text-hulahunt-teal-600 hover:text-hulahunt-teal-800 mr-2"
        >
          {t('footer.privacy')}
        </button>
        <button
          onClick={handleTermsClick}
          className="text-hulahunt-teal-600 hover:text-hulahunt-teal-800 mr-2"
        >
          {t('footer.terms')}
        </button>
        <button
          onClick={handleReportIssueClick}
          className="text-hulahunt-teal-600 hover:text-hulahunt-teal-800 underline"
        >
          {t('footer.reportIssue')}
        </button>
      </div>
      <ReportIssueModal open={isReportModalOpen} onClose={handleCloseReportModal} />
      <PrivacyTermsModal
        open={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        type="privacy"
      />
      <PrivacyTermsModal
        open={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        type="terms"
      />
    </footer>
  );
};

export default Footer;
