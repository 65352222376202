import axios from 'axios';
import { User } from '../../types/User';
import { getApiPath } from '../getApiPath';

interface ResetPasswordResponse {
  token: string | null;
  user: User | null;
}

export const resetPassword = async (
  resetToken: string,
  newPassword: string,
  confirmPassword: string
): Promise<ResetPasswordResponse> => {

  try {
    const response = await axios.post<ResetPasswordResponse>(
      getApiPath(`/passwordReset/resetPassword/${resetToken}`),
      { newPassword, confirmPassword },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    const { token, user } = response.data;

    return { token, user };

  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.error || 'An error occurred while resetting the password');
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    } else {
      console.error('Unexpected error:', error);
    }
  }
  return { token: null, user: null };
};