import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Business } from '../../../../types/Business';
import { createBusiness } from '../../../../services/business/createBusiness';
import { updateBusiness } from '../../../../services/business/updateBusiness';
import { TabPanel } from '../../TabPanel';
import { useForm } from 'react-hook-form';
import { BusinessFormData } from '../shared/types';
import { BusinessInfoForm } from '../shared/BusinessInfoForm';
import { LocationInfoForm } from '../shared/LocationInfoForm';
import { CouponInfoForm } from '../shared/CouponInfoForm';
import { Coupon, NewCoupon } from '../../../../types/Coupon';
import { createBusinessSchema } from '../shared/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldErrors } from 'react-hook-form';

interface BusinessFormModalProps {
  open: boolean;
  onClose: () => void;
  onBusinessSaved: () => void;
  business?: Business | null;
}

export const BusinessFormModal: React.FC<BusinessFormModalProps> = ({
  open,
  onClose,
  onBusinessSaved,
  business
}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [businessImageFile, setBusinessImageFile] = useState<File | null>(null);
  const [locationImageFile, setLocationImageFile] = useState<File | null>(null);
  const [currentCoupons, setCurrentCoupons] = useState<(Coupon | NewCoupon)[]>([]);

  const businessSchema = createBusinessSchema(t);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset
  } = useForm<BusinessFormData>({
    resolver: zodResolver(businessSchema),
    defaultValues: {
      businessType: 'Other',
      location: {
        state: 'HI',
        areaName: 'Hawaii (Big Island)',
      },
    }
  });

  useEffect(() => {
    if (open) {
      setCurrentCoupons(business?.coupons || []);

      if (business) {
        reset({
          name: business.name,
          contactEmail: business.contactEmail,
          phoneNumber: business.phoneNumber,
          website: business.website,
          description: business.description,
          welcomeMessage: business.welcomeMessage,
          businessType: business.businessType,
          businessImage: business.imageUrl,
          location: business.locations?.[0] ? {
            name: business.locations[0].name,
            streetAddress: business.locations[0].streetAddress,
            city: business.locations[0].city,
            state: business.locations[0].state,
            zipCode: business.locations[0].zipCode,
            areaName: business.locations[0].areaName || 'Hawaii (Big Island)',
            description: business.locations[0].description,
            latitude: business.locations[0].latitude,
            longitude: business.locations[0].longitude,
            locationImage: business.locations[0].imageUrl,
          } : {
            state: 'HI',
            areaName: 'Hawaii (Big Island)',
          },
          coupons: business.coupons || [],
        });
      } else {
        reset({
          businessType: 'Other',
          location: {
            state: 'HI',
            areaName: 'Hawaii (Big Island)',
          },
          coupons: [],
        });
      }
    }
  }, [business, reset, open]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const findFirstTabWithError = (errors: FieldErrors<BusinessFormData>) => {
    if (errors.name || errors.contactEmail || errors.phoneNumber ||
        errors.website || errors.description || errors.welcomeMessage ||
        errors.businessType || errors.businessImage) {
      return 0; // Business Info tab
    }
    if (errors.location) {
      return 1; // Location Info tab
    }
    if (errors.coupons) {
      return 2; // Coupons tab
    }

    return -1; // No errors found
  };

  const onSubmit = async (data: BusinessFormData) => {
    if (Object.keys(errors).length > 0) {
      const tabWithError = findFirstTabWithError(errors);
      if (tabWithError !== -1 && tabWithError !== tabValue) {
        setTabValue(tabWithError);
        return; // Prevent form submission
      }
    }

    try {
      setIsLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append('business', JSON.stringify({
        ...data,
        locations: [{
          ...data.location,
        }],
      }));

      if (businessImageFile) {
        formDataToSend.append('businessImage', businessImageFile);
      }
      if (locationImageFile) {
        formDataToSend.append('locationImage', locationImageFile);
      }

      if (business?.id) {
        await updateBusiness(business.id, formDataToSend);
      } else {
        await createBusiness(formDataToSend);
      }
      onBusinessSaved();
      onClose();
    } catch (error) {
      console.error('Error saving business:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setTabValue(0);
    setBusinessImageFile(null);
    setLocationImageFile(null);
    reset({
      businessType: 'Other',
      location: {
        state: 'HI',
        areaName: 'Hawaii (Big Island)',
      },
      coupons: [],
    });
    onClose();
  };

  const transformedBusinessData: BusinessFormData | null = business ? {
    name: business.name,
    contactEmail: business.contactEmail || '',
    phoneNumber: business.phoneNumber || '',
    website: business.website || '',
    description: business.description || '',
    welcomeMessage: business.welcomeMessage || '',
    businessType: business.businessType || 'Other',
    businessImage: business.imageUrl,
    location: {
      name: business.locations?.[0]?.name || '',
      streetAddress: business.locations?.[0]?.streetAddress || '',
      city: business.locations?.[0]?.city || '',
      state: business.locations?.[0]?.state || 'HI',
      zipCode: business.locations?.[0]?.zipCode || '',
      areaName: business.locations?.[0]?.areaName || '',
      description: business.locations?.[0]?.description || '',
      latitude: business.locations?.[0]?.latitude || 0,
      longitude: business.locations?.[0]?.longitude || 0,
      locationImage: business.locations?.[0]?.imageUrl,
    }
  } : null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit, (errors) => {
        // This error callback will run when validation fails
        const tabWithError = findFirstTabWithError(errors);
        if (tabWithError !== -1 && tabWithError !== tabValue) {
          setTabValue(tabWithError);
        }
      })}>
        <DialogTitle>
          {business ? t('admin.businesses.editBusiness') : t('admin.businesses.createBusiness')}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="business form tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label={t('admin.businesses.businessInfo')} />
              <Tab label={t('admin.businesses.locationInfo')} />
              <Tab label={t('admin.businesses.coupons')} />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <BusinessInfoForm
              control={control}
              errors={errors}
              onBusinessImageFileChange={setBusinessImageFile}
              originalValues={transformedBusinessData}
              isAdminModal={true}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <LocationInfoForm
              control={control}
              errors={errors}
              originalValues={transformedBusinessData}
              setValue={setValue}
              trigger={trigger}
              getValues={getValues}
              onLocationImageFileChange={setLocationImageFile}
              isAdminModal={true}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <CouponInfoForm
              t={t}
              coupons={currentCoupons}
              business={business?.id ? { id: business.id } : null}
              onCouponsChange={(newCoupons) => {
                setCurrentCoupons(newCoupons);
                setValue('coupons', newCoupons);
              }}
            />
          </TabPanel>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : business ? (
              t('common.update')
            ) : (
              t('common.create')
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};