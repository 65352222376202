import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Tab,
  Box,
  Tabs,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Hunt } from '../../../types/Hunt';
import { createHunt } from '../../../services/hunt/createHunt';
import { updateHunt } from '../../../services/hunt/updateHunt';
import { useGoogleMapsScript } from '../../../hooks/useGoogleMapsScript';
import { getHuntItems } from '../../../services/huntitem/getHuntItems';
import { HuntItem } from '../../../types/HuntItem';
import { Location } from '../../../types/Location';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import { HuntForm } from './HuntForm';
import { HuntItemsTable } from './HuntItemsTable';


interface HuntModalProps {
  open: boolean;
  onClose: () => void;
  onHuntSaved: () => void;
  hunt?: Hunt | null;
  mode: 'create' | 'edit';
}

export const HuntModal: React.FC<HuntModalProps> = ({
  open,
  onClose,
  onHuntSaved,
  hunt,
  mode
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Partial<Hunt>>({});
  const [huntImageFile, setHuntImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapMarker, setMapMarker] = useState<{ lat: number; lng: number } | null>(null);
  const [availableHuntItems, setAvailableHuntItems] = useState<HuntItem[]>([]);
  const [selectedHuntItemId, setSelectedHuntItemId] = useState<number | ''>('');
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const [addHuntItemIds, setAddHuntItemIds] = useState<(number | undefined)[]>([]);
  const [removeHuntItemIds, setRemoveHuntItemIds] = useState<number[]>([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);

  const { isLoaded, loadError } = useGoogleMapsScript();

  const fetchAvailableHuntItems = useCallback(async () => {
    try {
      const response = await getHuntItems();
      const allHuntItems = response.huntItems;
      const currentHuntItemIds = new Set(formData.huntItems?.map(item => item.id) || []);
      const availableItems = allHuntItems.filter(item => !currentHuntItemIds.has(item.id || 0));
      setAvailableHuntItems(availableItems);

      // Extract unique locations
      const locationMap = new Map();
      availableItems.forEach(item => {
        if (item.location && item.location.id) {
          locationMap.set(item.location.id, item.location);
        }
      });
      const uniqueLocations = Array.from(locationMap.values());
      setLocations([{ id: 0, name: "No Location" } as Location, ...uniqueLocations]);
    } catch (error) {
      console.error('Error fetching hunt items:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetState = useCallback(() => {
    if (mode === 'edit' && hunt) {
      setFormData({ ...hunt });
      if (hunt.latitude && hunt.longitude) {
        const lat = parseFloat(hunt.latitude.toString());
        const lng = parseFloat(hunt.longitude.toString());
        if (!isNaN(lat) && !isNaN(lng)) {
          setMapCenter({ lat, lng });
          setMapMarker({ lat, lng });
        }
      }
    } else {
      setFormData({price: 9.99});
      // Get user's current location when creating a new hunt
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setMapCenter({ lat: latitude, lng: longitude });
            setMapMarker({ lat: latitude, lng: longitude });
            setFormData((prev) => ({ ...prev, latitude, longitude }));
          },
          (error) => {
            console.error("Error getting user's location:", error);
          }
        );
      }
    }
    setHuntImageFile(null);
    setIsLoading(false);
    setActiveTab(0);
    setSelectedHuntItemId('');
    setSelectedLocationId(null);
    setAddHuntItemIds([]);
    setRemoveHuntItemIds([]);
    fetchAvailableHuntItems();
  }, [mode, hunt, fetchAvailableHuntItems]);

  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open, hunt, resetState]);

  useEffect(() => {
    fetchAvailableHuntItems();
  }, [fetchAvailableHuntItems]);

  const getActualHuntItemCount = () => {
    return formData.huntItems?.length || 0;
  };

  const handleHuntImageCapture = (file: File | null) => {
    setHuntImageFile(file);
    if (file) {
      setFormData((prev) => ({ ...prev, imageUrl: URL.createObjectURL(file) }));
    } else {
      setFormData((prev) => ({ ...prev, imageUrl: undefined }));
    }
  };

  const handleAddHuntItem = (newItem: HuntItem) => {
    setFormData(prev => ({
      ...prev,
      huntItems: prev.huntItems ? [...prev.huntItems, newItem] : [newItem]
    }));
    setAddHuntItemIds(prev => [...prev, newItem.id]);
    setAvailableHuntItems(prev => prev.filter(item => item.id !== newItem.id));
  };

  const handleRemoveHuntItem = (itemId: number | undefined) => {
    if (itemId) {
      setItemToDelete(itemId);
      setConfirmationDialogOpen(true);
    }
  };

  const confirmRemoveHuntItem = () => {
    if (itemToDelete !== null) {
      const removedItem = formData.huntItems?.find(item => item.id === itemToDelete);
      if (removedItem) {
        setFormData(prev => ({
          ...prev,
          huntItems: prev.huntItems?.filter(item => item.id !== itemToDelete) || []
        }));
        setRemoveHuntItemIds(prev => [...prev, itemToDelete]);
        setAvailableHuntItems(prev => prev.filter(item => item.id !== itemToDelete));
      }
      setItemToDelete(null);
      setConfirmationDialogOpen(false);
    }
  };

  const cancelRemoveHuntItem = () => {
    setItemToDelete(null);
    setConfirmationDialogOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const updatedFormData = { ...formData };

      if (addHuntItemIds) {
        updatedFormData.addHuntItemIds = JSON.stringify(addHuntItemIds);
      }
      if (removeHuntItemIds) {
        updatedFormData.removeHuntItemIds = JSON.stringify(removeHuntItemIds);
      }

      if (mode === 'create') {
        await createHunt(updatedFormData, huntImageFile);
      } else {
        await updateHunt(hunt!.id, updatedFormData, huntImageFile);
      }
      onHuntSaved();
      onClose();
    } catch (error) {
      console.error(`Error ${mode === 'create' ? 'creating' : 'updating'} hunt:`, error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'background.paper',
            zIndex: 10,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          {t(`admin.hunts.${mode}Hunt`)}
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="large"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 0, mt: 1 }}>
            {mode === 'edit' && hunt?.isInUse && (
              <Alert
                severity="warning"
                sx={{
                  my: 1,
                  border: '1px dashed red',
                  '& .MuiAlert-icon': {
                    color: 'red',
                  },
                }}
              >
                {t('admin.hunts.inUseWarning')}
              </Alert>
            )}
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="hunt modal tabs">
              <Tab label={t('admin.hunts.huntData')} />
              <Tab label={t('admin.hunts.huntItems')} />
            </Tabs>
            <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <HuntForm
                formData={formData}
                setFormData={setFormData}
                mapCenter={mapCenter}
                mapMarker={mapMarker}
                setMapCenter={setMapCenter}
                setMapMarker={setMapMarker}
                getActualHuntItemCount={getActualHuntItemCount}
                handleHuntImageCapture={handleHuntImageCapture}
                hunt={hunt}
              />
            )}
            {activeTab === 1 && (
              <HuntItemsTable
                formData={formData}
                setFormData={setFormData}
                availableHuntItems={availableHuntItems}
                setAvailableHuntItems={setAvailableHuntItems}
                onAddHuntItem={handleAddHuntItem}
                locations={locations}
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                selectedHuntItemId={selectedHuntItemId}
                setSelectedHuntItemId={setSelectedHuntItemId}
                handleRemoveHuntItem={handleRemoveHuntItem}
              />
            )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('common.cancel')}</Button>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : t(`common.${mode === 'create' ? 'create' : 'update'}`)}
            </Button>        </DialogActions>
        </form>
      </Dialog>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={t('admin.hunts.confirmDeleteTitle')}
        message={t('admin.hunts.confirmRemoveHuntItem')}
        onConfirm={confirmRemoveHuntItem}
        onCancel={cancelRemoveHuntItem}
      />
    </>
  );
};
