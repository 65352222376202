import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Paper,
  useTheme,
  IconButton
} from '@mui/material';
import {
  People as UsersIcon,
  Business as BusinessesIcon,
  LocationOn as LocationsIcon,
  EmojiEvents as HuntsIcon,
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Users } from './Users';
import { Businesses } from './Businesses';
import { Hunts } from './Hunts';
import { Locations } from './Locations';
import PageLayout from '../../components/common/PageLayout';
import { TabPanel } from './TabPanel';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useRole } from '../../hooks/useRole';
import { Roles } from '../../types/Roles';

function a11yProps(index: number) {
  return {
    id: `admin-tab-${index}`,
    'aria-controls': `admin-tabpanel-${index}`,
  };
}

const AdminPage: React.FC = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const role = useRole();
  const tabsRef = useRef<HTMLDivElement>(null);
  const [showLeftChevron, setShowLeftChevron] = useState(false);
  const [showRightChevron, setShowRightChevron] = useState(false);

  const isBusinessUser = role === Roles.Business;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (tabsRef.current) {
      const container = tabsRef.current;
      const scrollAmount = container.clientWidth / 2;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const updateChevronVisibility = () => {
    if (tabsRef.current) {
      const container = tabsRef.current;
      setShowLeftChevron(container.scrollLeft > 0);
      setShowRightChevron(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    const container = tabsRef.current;
    if (container) {
      container.addEventListener('scroll', updateChevronVisibility);
      window.addEventListener('resize', updateChevronVisibility);
      updateChevronVisibility();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', updateChevronVisibility);
      }
      window.removeEventListener('resize', updateChevronVisibility);
    };
  }, []);

  const ScrollButton = ({ direction }: { direction: 'left' | 'right' }) => (
    <IconButton
      onClick={() => handleScroll(direction)}
      sx={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        [direction]: 8,
        zIndex: 2,
        color: 'text.primary',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
      }}
    >
      {direction === 'left' ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );

  const tabs = useMemo(() =>
    isBusinessUser
      ? [{ icon: <BusinessesIcon />, label: t('admin.tabs.yourBusiness'), component: <Businesses /> }]
      : [
          { icon: <UsersIcon />, label: t('admin.tabs.users'), component: <Users /> },
          { icon: <BusinessesIcon />, label: t('admin.tabs.businesses'), component: <Businesses /> },
          { icon: <LocationsIcon />, label: t('admin.tabs.locations'), component: <Locations /> },
          { icon: <HuntsIcon />, label: t('admin.tabs.hunts'), component: <Hunts /> },
        ],
    [isBusinessUser, t]
  );

  return (
    <PageLayout title={isBusinessUser ? t('admin.businessUserTitle') : t('admin.title')}>
      <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}>
        <Paper
          elevation={0}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 100,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box sx={{ position: 'relative', width: '100%' }} >
            {isMobile && showLeftChevron && <ScrollButton direction="left" />}
            {!isBusinessUser && (
              <Tabs
                ref={tabsRef}
                value={value}
                onChange={handleChange}
                aria-label="admin tabs"
                variant="scrollable"
                scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiTabs-flexContainer': {
                  justifyContent: isMobile ? 'flex-start' : 'center',
                },
                '& .MuiTabs-scrollButtons.Mui-disabled': {
                  opacity: 0.3,
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} icon={tab.icon} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>)}
            {isMobile && showRightChevron && <ScrollButton direction="right" />}
          </Box>
        </Paper>
        <Box sx={{ flexGrow: 1, overflow: 'visible', backgroundColor: theme.palette.background.paper }}>
          {tabs.map((tab, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              hideTab={isBusinessUser}
            >
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default AdminPage;
