import axios from 'axios';
import { User } from '../../types/User';
import { RoleType } from '../../types/Roles';
import { getApiPath } from '../getApiPath';

interface GetUsersResponse {
  users: User[];
  currentPage: number;
  totalPages: number;
  totalUsers: number;
}

export type SortColumn = 'role' | 'name' | 'email';
export type SortOrder = 1 | -1;

export const getUsers = async (
  page: number = 1,
  limit: number = 10,
  role?: RoleType,
  userSearchString?: string,
  sortColumn?: SortColumn,
  sortOrder: SortOrder = 1
): Promise<GetUsersResponse> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await axios.get<GetUsersResponse>(getApiPath('/user/getUsers'), {
      params: {
        page,
        limit,
        role,
        userSearchString,
        sortColumn,
        sortOrder
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching users');
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      // Something else happened
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};