import React, { useState, useRef, forwardRef } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { stateCodes } from '../../constants/stateCodes';

interface StateSelectorProps extends Omit<SelectProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
}

export const StateSelector = forwardRef<HTMLDivElement, StateSelectorProps>(
  ({ value, onChange, required = false, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const selectRef = useRef<HTMLSelectElement>(null);
    const { t } = useTranslation();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!focused) return;

      const key = e.key.toLowerCase();
      if (key.length === 1 && key >= 'a' && key <= 'z') {
        e.preventDefault();
        const matchingStates = stateCodes.filter(state =>
          state.toLowerCase().startsWith(key)
        );

        if (matchingStates.length > 0) {
          const currentIndex = matchingStates.indexOf(value);
          const nextState = matchingStates[(currentIndex + 1) % matchingStates.length];
          onChange(nextState);
          if (selectRef.current) {
            selectRef.current.value = nextState;
          }
        }
      }
    };

    return (
      <FormControl fullWidth>
        <InputLabel id="state-select-label">{t('admin.locations.state')}</InputLabel>
        <Select
          labelId="state-select-label"
          value={value}
          onChange={(e) => onChange(e.target.value as string)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyDown={handleKeyDown}
          inputRef={selectRef}
          label={t('admin.locations.state')}
          required={required}
          ref={ref}
          {...props}
        >
          {stateCodes.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

StateSelector.displayName = 'StateSelector';

export default StateSelector;
