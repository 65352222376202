import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile';

export const ResponsiveTextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    const isMobile = useIsMobile();
    return <TextField {...props} ref={ref} size={isMobile ? 'small' : 'medium'} />;
  }
);

ResponsiveTextField.displayName = 'ResponsiveTextField';

export default ResponsiveTextField;