import axios from 'axios';
import { Hunt } from '../../types/Hunt';
import { getApiPath } from '../getApiPath';

export const createHunt = async (huntData: Partial<Hunt>, huntImageFile: File | null): Promise<Hunt> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const formData = new FormData();

  // Append each field of huntData to formData
  Object.entries(huntData).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      // Special handling for arrays (like tags)
      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value.toString());
      }
    }
  });

  if (huntImageFile) {
    formData.append('huntImage', huntImageFile);
  }

  try {
    const response = await axios.post(getApiPath('/hunt/createHunt'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating hunt:', error);
    throw error;
  }
};