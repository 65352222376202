import React, { useState } from 'react';
import { Scanner, IDetectedBarcode } from '@yudiel/react-qr-scanner';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface QRCodeScannerProps {
  open: boolean;
  onClose: () => void;
  onScan: (result: string) => void;
}

const QRCodeScanner: React.FC<QRCodeScannerProps> = ({ open, onClose, onScan }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleScan = (detectedCodes: IDetectedBarcode[]) => {
    if (detectedCodes.length > 0) {
      onScan(detectedCodes[0].rawValue);
      onClose();
    }
  };

  const handleError = (error: unknown) => {
    console.error('QR Scanner error:', error);
    setError(t('qrCodeScanner.error'));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('qrCodeScanner.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <p>{error}</p>
        ) : (
          <Scanner
            onScan={handleScan}
            onError={handleError}
            constraints={{ facingMode: 'environment' }}
            formats={['qr_code']}
            scanDelay={500}
            components={{
              audio: false,
              torch: true,
            }}
            styles={{
              container: { width: '100%', height: '300px' },
              finderBorder: 4,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default QRCodeScanner;