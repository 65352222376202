import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import {
  Close as CloseIcon,
  EmojiEvents as EmojiEventsIcon,
  AccessTime as AccessTimeIcon,
  Flag as FlagIcon,
  CheckCircle as CheckCircleIcon,
  Place as PlaceIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ActiveHunt } from '../../types/ActiveHunt';
import { getHuntInformation } from '../../services/activehunt/getHuntInformation';

interface ViewHuntStatsDialogProps {
  huntId: number;
  open: boolean;
  onClose: () => void;
}

const ViewHuntStatsDialog: React.FC<ViewHuntStatsDialogProps> = ({ huntId, open, onClose }) => {
  const { t } = useTranslation();
  const [activeHunt, setActiveHunt] = useState<ActiveHunt | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActiveHunt = async () => {
      if (open && huntId) {
        try {
          setLoading(true);
          const huntData = await getHuntInformation(huntId);
          setActiveHunt(huntData);
        } catch (error) {
          console.error('Error fetching active hunt:', error);
          // Handle error (e.g., show error message)
        } finally {
          setLoading(false);
        }
      }
    };

    console.log('fetching active hunt for huntId', huntId);
    fetchActiveHunt();
  }, [open, huntId]);

  const formatDate = (date: Date | string | null) => {
    if (!date) return t('common.notAvailable');
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(date).toLocaleString(undefined, options);
  };

  const calculateCompletionRate = () => {
    if (!activeHunt) return 0;
    const completedItemCount = activeHunt.huntItems.filter(item => item.status === 'complete').length;
    return (completedItemCount / activeHunt.huntItems.length) * 100;
  };

  const isFullyCompleted = calculateCompletionRate() === 100;

  const calculateTimeProgress = () => {
    if (!activeHunt || !activeHunt.huntStartedAt || !activeHunt.huntEndDate) return 0;
    const start = new Date(activeHunt.huntStartedAt).getTime();
    const end = new Date(activeHunt.huntEndDate).getTime();
    const finished = activeHunt.finishedTime ? new Date(activeHunt.finishedTime).getTime() : Date.now();
    return Math.min(((finished - start) / (end - start)) * 100, 100);
  };

  const formatTimeTaken = () => {
    if (!activeHunt || !activeHunt.huntStartedAt || !activeHunt.finishedTime) return '';
    const start = new Date(activeHunt.huntStartedAt).getTime();
    const finished = new Date(activeHunt.finishedTime).getTime();
    const timeTaken = finished - start;
    const hours = Math.floor(timeTaken / (1000 * 60 * 60));
    const minutes = Math.floor((timeTaken % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (!activeHunt) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pr: 6 }} textAlign="center">
        {t('myHunts.viewHuntStats')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <img
                src={activeHunt.imageUrl || '/api/placeholder/400/300'}
                alt={activeHunt.name || t('common.huntImage')}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '300px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
            </Box>
            <Typography variant="h5" align="center" gutterBottom>
              {activeHunt.name || t('common.unknownHunt')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                <b>{t('myHunts.startedAt')}:</b> {formatDate(activeHunt.huntStartedAt)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <FlagIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                <b>{t('myHunts.finishedAt')}:</b> {formatDate(activeHunt.finishedTime)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
              <PlaceIcon sx={{ mr: 1 }} />
              <Typography variant="body1">
                {activeHunt.locationAreaName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ position: 'relative', mb: 2 }}>
              <LinearProgress
                variant="determinate"
                value={calculateTimeProgress()}
                sx={{ height: 20, borderRadius: 1 }}
              />
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                {t('myHunts.timeTaken')}: {formatTimeTaken()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
              {isFullyCompleted ? (
                <EmojiEventsIcon sx={{ fontSize: 48, color: 'gold', mr: 2 }} />
              ) : (
                <CheckCircleIcon sx={{ fontSize: 48, color: 'primary.main', mr: 2 }} />
              )}
              <Typography variant="h6">
                {isFullyCompleted
                  ? t('myHunts.huntFullyCompleted')
                  : t('myHunts.huntPartiallyCompleted', {
                      completed: activeHunt.huntItems.filter(item => item.status === 'complete').length,
                      total: activeHunt.huntItems.length,
                    })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewHuntStatsDialog;
