import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface ImageOverlayTitleProps extends TypographyProps {
  text: string;
}

export const ImageOverlayTitle: React.FC<ImageOverlayTitleProps> = ({ text, ...props }) => (
  <Typography
    variant="h5"
    {...props}
    sx={{
      position: 'absolute',
      top: 4,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'white',
      textShadow: '0 0 3px black, 0 0 3px black, 0 0 3px black',
      zIndex: 1,
      px: 2,
      fontWeight: 'bold',
      ...props.sx,
    }}
  >
    {text}
  </Typography>
);