import axios from 'axios';
import { getApiPath } from '../getApiPath';

export const deleteBusiness = async (businessId: number): Promise<void> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  await axios.delete(getApiPath(`/business/deleteBusiness/${businessId}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};