import React from 'react';
import { Typography, Box } from '@mui/material';

interface PageLayoutProps {
  title?: string;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <Box sx={{ pt: .75, pb: .75 }}>
        {title && (
          <Typography
            variant="h1"
            gutterBottom
            className="hulahunt-page-title"
            sx={{ textAlign: 'center' }}
          >
            {title}
          </Typography>
        )}
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'auto', px: 0, pb: 0 }}>
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
