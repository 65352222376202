import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { Construction as ConstructionIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface FeatureNotImplementedDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
}

const FeatureNotImplementedDialog: React.FC<FeatureNotImplementedDialogProps> = ({
  open,
  onClose,
  title,
  message,
}) => {
  const { t } = useTranslation();
  title = title || t('featureNotImplemented.title');
  message = message || t('featureNotImplemented.message');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="feature-not-implemented-dialog-title"
      aria-describedby="feature-not-implemented-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
      }}
    >
      <DialogTitle id="feature-not-implemented-dialog-title" sx={{ pb: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <ConstructionIcon sx={{ fontSize: 48, color: 'warning.main', mb: 1 }} />
          <Typography variant="h5" component="span" fontWeight="bold" textAlign="center">
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="feature-not-implemented-dialog-description"
          sx={{ textAlign: 'center', color: 'text.secondary', mt: 1 }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          sx={{
            minWidth: 120,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeatureNotImplementedDialog;