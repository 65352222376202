import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Tab,
  Box,
  Tabs,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Location, CreateLocationData } from '../../../types/Location';
import { deleteHuntItem } from '../../../services/huntitem/deleteHuntItem';
import { useGoogleMapsScript } from '../../../hooks/useGoogleMapsScript';
import { LocationForm } from './LocationForm';
import { createLocation } from '../../../services/location/createLocation';
import { updateLocation } from '../../../services/location/updateLocation';
import { HuntItemsTab } from './HuntItemsTab';
import { HuntItem } from '../../../types/HuntItem';

interface LocationModalProps {
  open: boolean;
  onClose: () => void;
  onLocationSaved: () => void;
  location?: Location | null;
  mode: 'create' | 'edit';
}

export const LocationModal: React.FC<LocationModalProps> = ({
  open,
  onClose,
  onLocationSaved,
  location,
  mode
}) => {
  const { t } = useTranslation();
  const [originalHuntItems, setOriginalHuntItems] = useState<HuntItem[]>([]);
  const [huntItems, setHuntItems] = useState<HuntItem[]>([]);
  const [formData, setFormData] = useState<CreateLocationData>({
    name: '',
    description: '',
    imageUrl: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
    businessId: 0,
    huntItems: []
  });
  const [locationImageFile, setLocationImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapMarker, setMapMarker] = useState<{ lat: number; lng: number } | null>(null);

  const { isLoaded, loadError } = useGoogleMapsScript();

  const resetState = () => {
    if (mode === 'edit' && location) {
      setFormData({
        ...location,
        businessId: location.business?.id,
      });
      setHuntItems(location.huntItems || []);
      setOriginalHuntItems(location.huntItems || []);
      if (location.latitude && location.longitude) {
        const lat = parseFloat(location.latitude.toString());
        const lng = parseFloat(location.longitude.toString());
        if (!isNaN(lat) && !isNaN(lng)) {
          setMapCenter({ lat, lng });
          setMapMarker({ lat, lng });
        }
      }
    } else {
      setFormData({
        name: '',
        description: '',
        imageUrl: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        latitude: 0,
        longitude: 0,
        businessId: 0,
        huntItems: []
      });
      setHuntItems([]);
      setOriginalHuntItems([]);
      // Get user's current location when creating a new location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setMapCenter({ lat: latitude, lng: longitude });
            setMapMarker({ lat: latitude, lng: longitude });
            setFormData((prev) => ({ ...prev, latitude, longitude }));
          },
          (error) => {
            console.error("Error getting user's location:", error);
          }
        );
      }
    }
    setLocationImageFile(null);
    setIsLoading(false);
    setActiveTab(0);
  };

  useEffect(() => {
    if (open) {
      resetState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, location]);

  const handleLocationImageCapture = (file: File | null) => {
    setLocationImageFile(file);
    if (file) {
      setFormData((prev) => ({ ...prev, imageUrl: URL.createObjectURL(file) }));
    } else {
      setFormData((prev) => ({ ...prev, imageUrl: '' }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const updatedFormData = { ...formData, huntItems };

      if (mode === 'edit' && location?.business?.id) {
        updatedFormData.businessId = location.business.id;
      }

      if (updatedFormData.huntItems) {
        // Identify removed hunt items
        const removedHuntItems = originalHuntItems.filter(
          item => !updatedFormData.huntItems?.some(updatedItem => updatedItem.id === item.id)
        );

        // Delete removed hunt items
        const deletePromises = removedHuntItems.map(item => deleteHuntItem(item.id!));
        await Promise.all(deletePromises);
      }


      if (mode === 'create') {
        await createLocation(updatedFormData, locationImageFile);
      } else {
        await updateLocation(location!.id, updatedFormData, locationImageFile);
      }
      onLocationSaved();
      onClose();
    } catch (error) {
      console.error(`Error ${mode === 'create' ? 'creating' : 'updating'} location:`, error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleHuntItemsChange = (updatedHuntItems: HuntItem[]) => {
    setHuntItems(updatedHuntItems);
    setFormData((prev) => ({ ...prev, huntItems: updatedHuntItems }));
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'background.paper',
          zIndex: 10,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" component="span">
            {t(`admin.locations.${mode}Location`)}
          </Typography>
          <Typography variant="h6" fontWeight="normal" color="text.secondary" component="span">
            ({mode === 'edit' && location?.business ? location.business.name : t('admin.locations.noAttachedBusiness')})
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 0, mt: 1 }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="location modal tabs">
            <Tab label={t('admin.locations.locationData')} />
            {mode === 'edit' && (<Tab label={t('admin.locations.huntItems')} />)}
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <LocationForm
                formData={formData}
                setFormData={setFormData}
                mapCenter={mapCenter}
                mapMarker={mapMarker}
                setMapCenter={setMapCenter}
                setMapMarker={setMapMarker}
                handleLocationImageCapture={handleLocationImageCapture}
                location={location}
              />
            )}
            {activeTab === 1 && mode === 'edit' && (
              <HuntItemsTab
                huntItems={huntItems}
                onHuntItemsChange={handleHuntItemsChange}
                location={location || undefined}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : t(`common.${mode === 'create' ? 'create' : 'update'}`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};