import React from 'react';
import {
  Grid,
  MenuItem,
  Tooltip,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Location, CreateLocationData, HAWAIIAN_ISLANDS } from '../../../types/Location';
import { ResponsiveTextField, ImageUploadModal } from '../../../components/common';
import { MapCoordinatesPicker } from '../../../components/common/MapCoordinatesPicker';
import InfoIcon from '@mui/icons-material/Info';
import { StateSelector } from '../../../components/common/StateSelector';

interface LocationFormProps {
  formData: Partial<Location>;
  setFormData: React.Dispatch<React.SetStateAction<CreateLocationData>>;
  mapCenter: { lat: number; lng: number };
  mapMarker: { lat: number; lng: number } | null;
  setMapCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>;
  setMapMarker: React.Dispatch<React.SetStateAction<{ lat: number; lng: number } | null>>;
  handleLocationImageCapture: (file: File | null) => void;
  location?: Location | null;
}

export const LocationForm: React.FC<LocationFormProps> = ({
  formData,
  setFormData,
  setMapCenter,
  setMapMarker,
  handleLocationImageCapture,
  location,
}) => {
  const { t } = useTranslation();

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const zipRegex = /^\d{0,5}(-\d{0,4})?$/;
    if (zipRegex.test(value)) {
      setFormData((prev) => ({ ...prev, zipCode: value }));
    }
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setFormData((prev) => ({ ...prev, latitude: lat, longitude: lng }));
    setMapCenter({ lat, lng });
    setMapMarker({ lat, lng });
  };

  const getAddressString = () => {
    return `${formData.streetAddress}, ${formData.city}, ${formData.state} ${formData.zipCode}`;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="name"
          label={t('admin.locations.name')}
          value={formData.name || ''}
          onChange={handleTextChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="description"
          label={t('admin.locations.description')}
          value={formData.description || ''}
          onChange={handleTextChange}
          fullWidth
          multiline
          rows={4}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative' }}>
          <ResponsiveTextField
            select
            label={t('admin.locations.islandName')}
            name="areaName"
            value={formData.areaName || ''}
            onChange={handleTextChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={t('admin.locations.islandNameTooltip')}>
                    <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                      <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            {HAWAIIAN_ISLANDS.map((island) => (
              <MenuItem key={island} value={island}>
                {island}
              </MenuItem>
            ))}
          </ResponsiveTextField>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="streetAddress"
          label={t('admin.locations.streetAddress')}
          value={formData.streetAddress || ''}
          onChange={handleTextChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="city"
          label={t('admin.locations.city')}
          value={formData.city || ''}
          onChange={handleTextChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <StateSelector
          value={formData.state || ''}
          onChange={(value) => setFormData((prev) => ({ ...prev, state: value }))}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="zipCode"
          label={t('admin.locations.zipCode')}
          value={formData.zipCode || ''}
          onChange={handleZipCodeChange}
          fullWidth
          inputProps={{
            pattern: "\\d{5}(-\\d{4})?",
            title: "Enter a valid ZIP code (e.g., 12345 or 12345-6789)"
          }}
          helperText="Enter a 5-digit ZIP code or 9-digit ZIP+4"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <MapCoordinatesPicker
          latitude={Number(formData.latitude)}
          longitude={Number(formData.longitude)}
          onCoordinatesChange={handleCoordinatesChange}
          title={t('admin.locations.coordinates')}
          getAddressString={getAddressString}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUploadModal
          title={t('admin.locations.locationImage')}
          onImageCapture={handleLocationImageCapture}
          existingImageUrl={location?.imageUrl}
        />
      </Grid>
    </Grid>
  );
};