import axios from 'axios';
import { Location, CreateLocationData } from '../../types/Location';
import { getApiPath } from '../getApiPath';

/**
 * Creates a new location with optional hunt items.
 *
 * This function sends a POST request to create a new location. It can include
 * location details, an image file, and optional hunt items. All data is sent
 * as FormData to support file upload and complex nested objects.
 *
 * @param locationData - The data for the new location, including optional hunt items
 * @param locationImageFile - An optional image file for the location
 * @returns A promise that resolves to the created Location object
 */
export const createLocation = async (locationData: CreateLocationData, locationImageFile: File | null): Promise<Location> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const formData = new FormData();

  // Append each field of locationData to formData
  Object.entries(locationData).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (key === 'huntItems') {
        // Convert huntItems array to JSON string
        formData.append(key, JSON.stringify(value));
      } else if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value.toString());
      }
    }
  });

  if (locationImageFile) {
    formData.append('locationImage', locationImageFile);
  }

  try {
    const response = await axios.post(getApiPath('/location/createLocation'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating location:', error);
    throw error;
  }
};