import React, { useState, useEffect } from 'react';
import { Control, FieldErrors, Controller } from 'react-hook-form';
import { Paper, Typography, Box, MenuItem, Tooltip } from '@mui/material';
import { ResponsiveTextField, ImageUploadModal } from '../../../../components/common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { allowedBusinessTypes } from '../../../../types/Business';
import { useTranslation } from 'react-i18next';
import { BusinessFormData } from './types';

type BusinessInfoFormProps = {
  control: Control<BusinessFormData>;
  errors: FieldErrors<BusinessFormData>;
  onBusinessImageFileChange: (file: File | null) => void;
  originalValues: BusinessFormData | null;
  isAdminModal?: boolean;
};

export const BusinessInfoForm: React.FC<BusinessInfoFormProps> = ({
  control,
  errors,
  onBusinessImageFileChange,
  originalValues,
  isAdminModal = false,
}) => {
    const { t } = useTranslation();
    const [businessImagePreview, setBusinessImagePreview] = useState<string | undefined>(
        originalValues?.businessImage as string | undefined
    );

    useEffect(() => {
        setBusinessImagePreview(originalValues?.businessImage as string | undefined);
    }, [originalValues]);

    const handleBusinessImageCapture = (file: File | null) => {
        onBusinessImageFileChange(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => setBusinessImagePreview(e.target?.result as string);
            reader.readAsDataURL(file);
        } else {
            setBusinessImagePreview(undefined);
        }
    };

    const content = (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
                }}
            >
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            label={t('admin.businesses.name')}
                            fullWidth
                            required
                            error={!!errors.name}
                            value={field.value || ''}
                            helperText={errors.name?.message}
                        />
                    )}
                />
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            label={t('admin.businesses.contactEmail')}
                            type="email"
                            fullWidth
                            required
                            value={field.value || ''}
                            error={!!errors.contactEmail}
                            helperText={errors.contactEmail?.message}
                        />
                    )}
                />
                <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            label={t('admin.businesses.phoneNumber')}
                            fullWidth
                            required
                            value={field.value || ''}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber?.message}
                        />
                    )}
                />
                <Controller
                    name="website"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            label={t('admin.businesses.website')}
                            fullWidth
                            value={field.value || ''}
                            error={!!errors.website}
                            helperText={errors.website?.message}
                        />
                    )}
                />
                <Controller
                    name="businessType"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            select
                            label={t('admin.businesses.businessType')}
                            fullWidth
                            required
                            error={!!errors.businessType}
                            helperText={errors.businessType?.message}
                            value={field.value || 'Other'}
                        >
                            {allowedBusinessTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </ResponsiveTextField>
                    )}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            label={t('admin.businesses.description')}
                            fullWidth
                            required
                            multiline
                            rows={4}
                            value={field.value || ''}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                        />
                    )}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Controller
                    name="welcomeMessage"
                    control={control}
                    render={({ field }) => (
                        <ResponsiveTextField
                            {...field}
                            label={t('admin.businesses.welcomeMessage')}
                            fullWidth
                            required
                            multiline
                            rows={3}
                            value={field.value || ''}
                            error={!!errors.welcomeMessage}
                            helperText={errors.welcomeMessage?.message}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip title={t('admin.businesses.welcomeMessageTooltip')}>
                                        <InfoOutlinedIcon color="action" sx={{ ml: 1, cursor: 'pointer' }} />
                                    </Tooltip>
                                ),
                            }}
                        />
                    )}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Controller
                    name="businessImage"
                    control={control}
                    render={({ field }) => (
                        <Box>
                            <Tooltip title={t('admin.businesses.businessImageTooltip')}>
                                <InfoOutlinedIcon color="action" sx={{ mb: -10, ml: 1, cursor: 'pointer' }} />
                            </Tooltip>
                            <ImageUploadModal
                                title={t('admin.businesses.businessImage')}
                                onImageCapture={(file) => {
                                    field.onChange(file);
                                    handleBusinessImageCapture(file);
                                }}
                                existingImageUrl={businessImagePreview}
                            />
                        </Box>
                    )}
                />
                {errors.businessImage && (
                    <Typography color="error">{t('validation.required')}</Typography>
                )}
            </Box>
        </>
    );

    if (isAdminModal) {
        return content;
    }

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                {t('admin.businesses.businessInfo')}
            </Typography>
            {content}
        </Paper>
    );
};
