import axios from 'axios';
import { getApiPath } from '../getApiPath';

interface SubmitSolutionParams {
  huntId: number;
  huntItemId: number;
  latitude?: number;
  longitude?: number;
  qrCodeUuid?: string;
  answer?: string;
}

interface SubmitSolutionResult {
  itemCompleted: boolean;
  huntCompleted: boolean;
}

export const submitHuntItemSolution = async (params: SubmitSolutionParams): Promise<SubmitSolutionResult> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const { huntId, huntItemId, ...solutionData } = params;

    const response = await axios.post<SubmitSolutionResult>(
      getApiPath(`/activehunt/submitHuntItemSolution/${huntId}/${huntItemId}`),
      solutionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while submitting the solution');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};