import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Link,
  Paper,
  IconButton,
} from '@mui/material';
import { BusinessWithActiveCoupons } from '../../services/business/getBusinessesWithActiveCoupons';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';

interface ShowBusinessModalProps {
  business: BusinessWithActiveCoupons | null;
  onClose: () => void;
}

const ShowBusinessModal: React.FC<ShowBusinessModalProps> = ({ business, onClose }) => {
  const { t } = useTranslation();

  if (!business) return null;

  const getGoogleMapsUrl = (location: BusinessWithActiveCoupons['location']) => {
    const address = `${location.streetAddress}, ${location.city}, ${location.state} ${location.zipCode}`;
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  return (
    <Dialog open={!!business} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle align="center" className="bg-hulahunt-teal-100 text-hulahunt-teal-800 p-4">
        {business.name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'hulahunt.teal.800',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="p-4">
        <Box className="flex flex-col items-center space-y-6">
          <Paper elevation={3}
            className="w-full overflow-hidden rounded-lg"
            style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
          >
            {business.imageUrl && business.imageUrl.length > 0 && (
              <img
                src={business.imageUrl}
                alt={business.name}
                width="100%"
                height="auto"
                style={{ borderRadius: '8px', maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}
              />
            )}
          </Paper>

          {business.description && (
            <Typography sx={{ mt: 1 }} align="center" variant="body1" className="mb-4">
              {business.description}
            </Typography>
          )}
          {business.welcomeMessage && (
            <Typography align="center" variant="body2" sx={{ fontStyle: 'italic' }}>
              {business.welcomeMessage}
            </Typography>
          )}

          <Paper elevation={2} sx={{ mt: 1, py: 1, width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2, width: '100%', px: 2 }}>
              {business.location && (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <LocationOnIcon sx={{ mr: 1, color: 'hulahunt.teal.600', flexShrink: 0 }} />
                  <Typography variant="body2" sx={{ textAlign: 'left', wordBreak: 'break-word' }}>
                    <Link href={getGoogleMapsUrl(business.location)} target="_blank" rel="noopener noreferrer">
                      {business.location.streetAddress}, {business.location.city}, {business.location.state} {business.location.zipCode}
                    </Link>
                  </Typography>
                </Box>
              )}
              {business.phoneNumber && (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <PhoneIcon sx={{ mr: 1, color: 'hulahunt.teal.600', flexShrink: 0 }} />
                  <Typography variant="body2">
                    <Link href={`tel:${business.phoneNumber}`}>{business.phoneNumber}</Link>
                  </Typography>
                </Box>
              )}
              {business.website && (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <LanguageIcon sx={{ mr: 1, color: 'hulahunt.teal.600', flexShrink: 0 }} />
                  <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                    <Link href={business.website} target="_blank" rel="noopener noreferrer">{business.website}</Link>
                  </Typography>
                </Box>
              )}
              {business.contactEmail && (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <EmailIcon sx={{ mr: 1, color: 'hulahunt.teal.600', flexShrink: 0 }} />
                  <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                    <Link href={`mailto:${business.contactEmail}`}>{business.contactEmail}</Link>
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions className="justify-center p-4">
        <Button onClick={onClose} color="primary" variant="contained">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowBusinessModal;
