import axios from 'axios';
import { Hunt } from '../../types/Hunt';
import { getApiPath } from '../getApiPath';

export const startHunt = async (huntId: number): Promise<Hunt> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    const response = await axios.post(getApiPath(`/hunt/startHunt/${huntId}`), {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error starting hunt:', error);
    throw error;
  }
};
