import { Location } from "./Location";

export enum ItemType {
  // eslint-disable-next-line no-unused-vars
  QRCode = "QRCode",
  // eslint-disable-next-line no-unused-vars
  Question = "Question",
  // eslint-disable-next-line no-unused-vars
  GPSLocation = "GPSLocation"
}

export interface HuntItem {
  id?: number;
  imageUrl?: string;
  title: string;
  description?: string;
  instructions?: string;
  locationId?: number;
  latitude?: number;
  longitude?: number;
  location?: Location;
  hint1?: string;
  hint2?: string;
  hint3?: string;
  itemType: ItemType;
  question?: string;
  answer?: string;
  qrCodeUuid?: string;
  orderIndex?: number;
  isInUseByActiveHunt?: boolean;
}