import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Box,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Chip
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
  AttachMoney as AttachMoneyIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Category as CategoryIcon,
  NearMe as NearMeIcon,
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getApiPath } from '../../services/getApiPath';
import { Hunt } from '../../types/Hunt';
import { EllipsisTypography } from '../../components/common';
import { useIsMobile } from '../../hooks/useIsMobile';

interface PurchaseDetails {
  itemType: string;
  item: Hunt; // This could be extended to support other item types in the future
}

const PurchaseSuccess: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [purchaseDetails, setPurchaseDetails] = useState<PurchaseDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchPurchaseDetails = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (!sessionId) {
        setError('No session ID found');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(getApiPath(`stripe/purchase/details?session_id=${sessionId}`), {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch purchase details');
        }

        const data: PurchaseDetails = await response.json();
        setPurchaseDetails(data);
      } catch (err) {
        setError(`Failed to load purchase details: ${err}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchaseDetails();
  }, [location]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const renderItemDetails = () => {
    if (!purchaseDetails) return null;
    switch (purchaseDetails.itemType) {
      case 'hunt':
        return renderHuntDetails(purchaseDetails.item);
      // Add more cases for other item types here
      default:
        return <Typography>Unsupported item type: {purchaseDetails.itemType}</Typography>;
    }
  };

  const renderHuntDetails = (hunt: Hunt) => (
    <Card sx={{ mb: 3 }}>
      <CardMedia
        component="img"
        height="200"
        sx={{ objectFit: 'cover', height: isMobile ? 150 : 250 }}
        image={hunt.imageUrl || '/path/to/default/image.jpg'}
        alt={hunt.name}
      />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {hunt.name}
        </Typography>
        <EllipsisTypography
          variant="body2"
          color="text.secondary"
          paragraph
          mobileLineLimit={3}
          text={hunt.description}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">{hunt.locationAreaName}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CategoryIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">{hunt.fakeHuntItemCount} {t('findHunts.items')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">{hunt.estimatedTimeHours} {t('findHunts.hours')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DirectionsWalkIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body2">
                {hunt.totalDistanceMiles !== undefined
                  ? `${Math.round(hunt.totalDistanceMiles)} ${t('findHunts.milesLong')}`
                  : t('findHunts.distanceUnknown')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4, p: 2 }}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h5" component="h2">
            {purchaseDetails?.item.price === 0 ? t('purchaseSuccess.yourHuntIsFree') : t('purchaseSuccess.thankYou')}
          </Typography>
        </Box>
        {renderItemDetails()}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6">{t('purchaseSuccess.totalPaid')}:</Typography>
          <Chip
            icon={<AttachMoneyIcon />}
            label={purchaseDetails?.item.price === 0 ? t('purchaseSuccess.free') : `${purchaseDetails?.item.price}`}
            color="primary"
            variant="outlined"
            size="small"
            sx={{
              '& .MuiChip-label': {
                fontSize: '1rem'
              }
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={() => navigate('/myhunts')}
          startIcon={<NearMeIcon />}
        >
          {t('purchaseSuccess.viewMyHunts')}
        </Button>
      </Paper>
    </Box>
  );
};

export default PurchaseSuccess;