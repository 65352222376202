import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, TextField, Box, IconButton, Divider, Alert, Portal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActiveHuntItem } from '../../types/ActiveHunt';
import { ItemType } from '../../types/HuntItem';
import { QrCode2, Quiz, LocationOn, Close, Lightbulb, Map as MapIcon } from '@mui/icons-material';
import QRCodeScanner from '../../components/common/QRCodeScanner';
import { Dialog as MuiDialog, DialogActions, DialogContent as MuiDialogContent, DialogTitle as MuiDialogTitle } from '@mui/material';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import HuntLocationMap from '../../components/common/HuntLocationMap';
import { getHintForItem } from '../../services/activehunt/getHintForItem';
import { submitHuntItemSolution } from '../../services/activehunt/submitHuntItemSolution';
import Confetti from 'react-confetti';
import CompletionDialog from './CompletionDialog';
import { useNavigate } from 'react-router-dom';
import { useGoogleMapsScript } from '../../hooks/useGoogleMapsScript';

interface HuntItemModalProps {
  open: boolean;
  onClose: () => void;
  item: ActiveHuntItem;
  huntId: number;
}

interface SubmitSolutionParams {
  latitude?: number;
  longitude?: number;
  qrCodeUuid?: string;
  answer?: string;
}

const HuntItemModal: React.FC<HuntItemModalProps> = ({ open, onClose, item, huntId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [qrScannerOpen, setQrScannerOpen] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [mapOpen, setMapOpen] = useState(false);
  const [localItem, setLocalItem] = useState(item);
  const [hintConfirmationOpen, setHintConfirmationOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [answerInput, setAnswerInput] = useState('');
  const [huntCompleted, setHuntCompleted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [itemCompleted, setItemCompleted] = useState(false);

  const { isLoaded, loadError } = useGoogleMapsScript();

  const handleScanQRCode = () => {
    setQrScannerOpen(true);
  };

  const handleSubmitSolution = async (solutionData: SubmitSolutionParams) => {
    try {
      const result = await submitHuntItemSolution({
        huntId,
        huntItemId: item.id,
        ...solutionData,
      });

      setItemCompleted(result.itemCompleted);
      setHuntCompleted(result.huntCompleted);
      setConfirmationDialogOpen(true);

      if (result.itemCompleted || result.huntCompleted) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
      }
    } catch (error) {
      console.error('Error submitting solution:', error);
      setItemCompleted(false);
      setHuntCompleted(false);
      setConfirmationDialogOpen(true);
    }
  };

  const handleQRCodeScanned = (result: string) => {
    setQrScannerOpen(false);
    handleSubmitSolution({ qrCodeUuid: result });
  };

  const handleSubmitAnswer = () => {
    handleSubmitSolution({ answer: answerInput });
  };

  const handleSubmitLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setLocationModalOpen(true);
      },
      (error) => {
        console.error('Error getting current location:', error);
        alert(t('common.errorGettingLocation'));
      }
    );
  };

  const handleCloseConfirmation = () => {
    setConfirmationDialogOpen(false);
    if (huntCompleted) {
      navigate('/myhunts');
    } else if (itemCompleted) {
      onClose();
    }
    // If not completed, do nothing, keeping the HuntItemModal open
  };

  const handleLocationSubmit = () => {
    setLocationModalOpen(false);
    handleSubmitSolution({ latitude: userLocation?.lat, longitude: userLocation?.lng });
  };

  const handleLocationCancel = () => {
    setLocationModalOpen(false);
  };

  const getItemTypeIcon = (itemType: ItemType) => {
    switch (itemType) {
      case ItemType.QRCode:
        return <QrCode2 sx={{ fontSize: 40 }} />;
      case ItemType.Question:
        return <Quiz sx={{ fontSize: 40 }} />;
      case ItemType.GPSLocation:
        return <LocationOn sx={{ fontSize: 40 }} />;
      default:
        return null;
    }
  };

  const getItemTypeDescription = (itemType: ItemType) => {
    switch (itemType) {
      case ItemType.QRCode:
        return t('activeHunt.itemTypeTitles.QRCode');
      case ItemType.Question:
        return t('activeHunt.itemTypeTitles.Question');
      case ItemType.GPSLocation:
        return t('activeHunt.itemTypeTitles.GPSLocation');
      default:
        return "";
    }
  };

  const renderItemTypeContent = () => {
    switch (item.itemType) {
      case ItemType.QRCode:
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<QrCode2 />}
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleScanQRCode}
          >
            {t('activeHunt.scanQRCode')}
          </Button>
        );
      case ItemType.Question:
        return (
          <Box sx={{ mt: 2 }}>
            {item.question && (
              <Typography variant="subtitle1" gutterBottom>
                {item.question}
              </Typography>
            )}
            <TextField
              fullWidth
              variant="outlined"
              label={t('activeHunt.enterAnswer')}
              sx={{ mt: 1, mb: 2 }}
              value={answerInput}
              onChange={(e) => setAnswerInput(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<Quiz />}
              fullWidth
              onClick={handleSubmitAnswer}
            >
              {t('activeHunt.submitAnswer')}
            </Button>
          </Box>
        );
      case ItemType.GPSLocation:
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<LocationOn />}
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSubmitLocation}
          >
            {t('activeHunt.submitCurrentLocation')}
          </Button>
        );
      default:
        return null;
    }
  };

  const itemLatitude = item.latitude || item.location?.latitude;
  const itemLongitude = item.longitude || item.location?.longitude;

  const hasLocation = itemLatitude !== undefined && itemLongitude !== undefined;

  const handleGetHint = async () => {
    setHintConfirmationOpen(true);
  };

  const handleConfirmGetHint = async () => {
    setHintConfirmationOpen(false);
    try {
      const newHints = await getHintForItem(huntId, localItem.id);
      setLocalItem(prevItem => ({
        ...prevItem,
        hints: newHints,
        hintsUsed: (prevItem.hintsUsed || 0) + 1,
        hintsRemaining: (prevItem.hintsRemaining || 0) - 1,
      }));
    } catch (error) {
      console.error('Error fetching hint:', error);
      alert(t('activeHunt.errorFetchingHint'));
    }
  };

  const handleCancelGetHint = () => {
    setHintConfirmationOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{
          textAlign: 'center',
          px: 2,
          pb: 1,
          background: 'linear-gradient(to right, #3da370, #2e8b57)',
          color: 'white',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1, ml: -1 }}>
            {getItemTypeIcon(item.itemType)}
            <Typography variant="h5" sx={{ mx: 1 }}>
              {item.title}
            </Typography>
          </Box>
          {(item.imageUrl || item.location?.imageUrl) && (
            <Box sx={{
              mt: 1,
              mb: 1,
              display: 'flex',
              justifyContent: 'center',
              width: '100%', // Ensure the container takes full width
            }}>
              <Box
                sx={{
                  width: '100%', // Take full width of the parent
                  maxWidth: '100%',
                  height: { xs: '170px', sm: '200px' },
                  overflow: 'hidden',
                  borderRadius: '8px',
                  boxShadow: '0 0 10px rgba(255,255,255,0.3)',
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(255,255,255,0.4)',
                  },
                }}
              >
                <img
                  src={item.imageUrl || item.location?.imageUrl}
                  alt={item.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Box>
          )}
          <Typography variant="body1">
            {getItemTypeDescription(item.itemType)}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            size="medium"
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="body1" paragraph>
            {item.description}
          </Typography>
          {item.instructions && (
            <Typography variant="body2" color="text.secondary" paragraph sx={{ fontStyle: 'italic' }}>
              {item.instructions}
            </Typography>
          )}
          {hasLocation && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<MapIcon />}
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => setMapOpen(true)}
            >
              {t('activeHunt.showItemStartPoint')}
            </Button>
          )}
          {renderItemTypeContent()}
          <Divider sx={{ mt: 2, mb: 1 }} />
          {localItem.hintsRemaining && localItem.hintsRemaining > 0 ? (
            <Button
              sx={{ mb: 1 }}
              startIcon={<Lightbulb />}
              color="secondary"
              fullWidth
              onClick={handleGetHint}
            >
              {t('activeHunt.getHint')}
            </Button>
          ) : localItem.hintsUsed && localItem.hintsUsed > 0 ? (
            <Alert severity="warning" sx={{ mt: 2, border: '1px dotted yellow', borderRadius: '8px', mb: 2 }}>
              {t('activeHunt.noMoreHints')}
            </Alert>
          ) : null}
          {localItem.hints && localItem.hints.length > 0 && (
            <Box sx={{ mb: 2 }}>
              {localItem.hints.map((hint, index) => (
                <Alert
                  severity="info"
                  key={index}
                  sx={{
                    mb: 1,
                    border: '1px dotted lightblue',
                    borderRadius: '8px'
                  }}
                >
                  {hint}
                </Alert>
              ))}
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <MuiDialog open={locationModalOpen} onClose={handleLocationCancel} fullWidth maxWidth="sm">
        <DialogTitle sx={{ textAlign: 'center' }}>{t('activeHunt.confirmLocation')}</DialogTitle>
        <DialogContent>
          {isLoaded && userLocation && (
            <Box sx={{ height: 300, width: '100%', mt: 2 }}>
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={userLocation}
                zoom={15}
              >
                <MarkerF
                  position={userLocation}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    scaledSize: new window.google.maps.Size(40, 40)
                  }}
                />
              </GoogleMap>
            </Box>
          )}
          {loadError && <Typography color="error">{t('common.errorLoadingMap')}</Typography>}
          {!isLoaded && <Typography>{t('common.loadingMap')}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLocationCancel}>{t('common.cancel')}</Button>
          <Button onClick={handleLocationSubmit} variant="contained" color="primary">
            {t('common.submit')}
          </Button>
        </DialogActions>
      </MuiDialog>
      <MuiDialog open={hintConfirmationOpen} onClose={handleCancelGetHint}>
        <MuiDialogTitle>{t('activeHunt.confirmGetHint')}</MuiDialogTitle>
        <MuiDialogContent>
          <Typography>{t('activeHunt.confirmGetHintMessage')}</Typography>
        </MuiDialogContent>
        <DialogActions>
          <Button onClick={handleCancelGetHint}>{t('common.cancel')}</Button>
          <Button onClick={handleConfirmGetHint} variant="contained" color="primary">
            {t('common.confirm')}
          </Button>
        </DialogActions>
      </MuiDialog>
      {showConfetti && (
        <Portal>
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999 }}>
            <Confetti recycle={false} numberOfPieces={200} />
          </div>
        </Portal>
      )}
      <CompletionDialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmation}
        itemCompleted={itemCompleted}
        huntCompleted={huntCompleted}
        itemTitle={item.title}
        itemType={item.itemType}
      />
      <QRCodeScanner
        open={qrScannerOpen}
        onClose={() => setQrScannerOpen(false)}
        onScan={handleQRCodeScanned}
      />
      {hasLocation && (
        <HuntLocationMap
          open={mapOpen}
          onClose={() => setMapOpen(false)}
          latitude={Number(itemLatitude)}
          longitude={Number(itemLongitude)}
          isItemLocation={true}
          showUserLocation={true}
          title={t('activeHunt.itemStartPoint')}
          drawLineFromUserLocation={true}
          showDistanceToUser={true}
          subtitle={item.title}
        />
      )}
    </>
  );
};

export default HuntItemModal;