import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2e8b57', // hulahunt-primary color
    },
    secondary: {
      main: '#4a6741', // hulahunt-secondary color
    },
    info: {
      main: '#adf', // hulahunt-info color
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 5000,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
        },
        contained: {
          backgroundColor: '#2e8b57',
          color: 'white',
          '&:hover': {
            backgroundColor: '#1e5d3a',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.hulahunt-page-title': {
            fontFamily: 'Arial, sans-serif',
            fontWeight: 700,
            fontSize: '1.75rem',
            color: '#2e8b57', // hulahunt-primary color
            textAlign: 'center',
            marginBottom: '0',
            padding: '0.33rem 0.33rem',
            position: 'relative',
            textShadow: '0 0 10px rgba(46, 139, 87, 0.5)', // Glow effect
          },
        },
      },
    },
    // Add more component customizations here
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Pacifico, cursive',
    },
    // Add more typography variants as needed...
  },
});

export default theme;