import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface TokenExpirationDialogProps {
  open: boolean;
  onClose: () => void;
}

const TokenExpirationDialog: React.FC<TokenExpirationDialogProps> = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleOk = () => {
    onClose();
    navigate('/signup');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session has expired. You have been logged out. Please sign up or log in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TokenExpirationDialog;