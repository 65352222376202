import axios from 'axios';
import { getApiPath } from '../getApiPath';

interface HintResponse {
  hint: string[];
}

export const getHintForItem = async (huntId: number, huntItemId: number): Promise<string[]> => {
  try {
    const response = await axios.get<HintResponse>(
      getApiPath(`/activehunt/getHintForItem/${huntId}/${huntItemId}`),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    return response.data.hint;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching hint');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};