import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  CheckCircle as EnabledIcon,
  Cancel as DisabledIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getHunts, SortColumn, SortOrder } from '../../../services/hunt/getHunts';
import { Hunt } from '../../../types/Hunt';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { ResponsiveTextField, PaginationContainer } from '../../../components/common';
import { HuntModal } from './HuntModal'
import { deleteHunt } from '../../../services/hunt/deleteHunt';
import { ConfirmationDialog } from '../../../components/common/ConfirmationDialog';

export const Hunts: React.FC = () => {
  const isMobile = useIsMobile();
  const [hunts, setHunts] = useState<Hunt[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(isMobile ? 7 : 10);
  const [totalHunts, setTotalHunts] = useState(0);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [huntSearchString, setHuntSearchString] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<SortColumn>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
  const [selectedHunt, setSelectedHunt] = useState<Hunt | null>(null);
  const [isEditHuntModalOpen, setIsEditHuntModalOpen] = useState(false);
  const [isCreateHuntModalOpen, setIsCreateHuntModalOpen] = useState(false);
  const { t } = useTranslation();
  const [deleteConfirmation, setDeleteConfirmation] = useState<{open: boolean, hunt: Hunt | null}>({
    open: false,
    hunt: null,
  });


  useEffect(() => {
    getHunts(
      page,
      rowsPerPage,
      huntSearchString,
      sortColumn,
      sortOrder
    ).then((data) => {
      setHunts(data.hunts);
      setTotalHunts(data.totalHunts);
    });
  }, [page, rowsPerPage, refetchTrigger, huntSearchString, sortColumn, sortOrder]);


  const handleAddHunt = () => {
    setIsCreateHuntModalOpen(true);
  };

  const handleCreateHuntModalClose = () => {
    setIsCreateHuntModalOpen(false);
  };

  const handleHuntCreated = () => {
    setRefetchTrigger(prev => prev + 1);
  };

  const handleEditHunt = (hunt: Hunt) => {
    setSelectedHunt(hunt);
    setIsEditHuntModalOpen(true);
  };

  const handleEditHuntModalClose = () => {
    setIsEditHuntModalOpen(false);
    setSelectedHunt(null);
  };

  const handleHuntUpdated = () => {
    setRefetchTrigger(prev => prev + 1);
  };

  const handleDeleteConfirmation = (hunt: Hunt | null) => {
    setDeleteConfirmation({ open: true, hunt });
  };

  const handleDeleteHunt = () => {
    if (deleteConfirmation.hunt?.id) {
      deleteHunt(deleteConfirmation.hunt.id).then(() => {
        setRefetchTrigger((prev) => prev + 1);
        setDeleteConfirmation({ open: false, hunt: null });
      }).catch((error) => {
        console.error('Error deleting hunt:', error);
      });
    }
  };

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortColumn(column);
      setSortOrder('ASC');
    }
  };

  const renderHuntName = (hunt: Hunt) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title={hunt.enabled ? t('admin.hunts.enabledTooltip') : t('admin.hunts.disabledTooltip')}>
        {hunt.enabled ? (
          <EnabledIcon fontSize="small" color="success" />
        ) : (
          <DisabledIcon fontSize="small" color="error" />
        )}
      </Tooltip>
      <span>{hunt.name}</span>
    </Box>
  );

  const truncateDescription = (description: string | undefined, maxLength: number) => {
    if (!description) return '';
    return description.length > maxLength ? `${description.substring(0, maxLength)}...` : description;
  };

  const renderActions = (hunt: Hunt) => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
      <IconButton onClick={() => handleEditHunt(hunt)} size="small">
        <EditIcon />
      </IconButton>
      <Tooltip title={hunt.isInUse ? t('admin.hunts.cannotDeleteInUse') : ''}>
        <span>
          <IconButton
            onClick={() => !hunt.isInUse && handleDeleteConfirmation(hunt)}
            size="small"
            disabled={hunt.isInUse}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );

  return (
    <Box sx={{ p: { xs: 0, sm: 1 } }}>
      <Box sx={{ mb: 3, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddHunt}
          sx={{
            width: { xs: '100%', sm: '300px' },
            alignSelf: 'flex-start'
          }}
        >
          {t('admin.hunts.addHunt')}
        </Button>

        <ResponsiveTextField
          label="Search"
          variant="outlined"
          value={huntSearchString}
          onChange={(e) => setHuntSearchString(e.target.value)}
          sx={{
            flexGrow: 1,
            width: { xs: '100%', sm: 'calc(100% - 300px)' }
          }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
            <TableCell
                onClick={() => handleSort('name')}
                sx={{ cursor: 'pointer', fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.hunts.name')} {sortColumn === 'name' && (sortOrder === 'ASC' ? '↑' : '↓')}
              </TableCell>
              {!isMobile && (
                <TableCell sx={{ fontSize: '0.85rem', px: 1 }}>
                  {t('admin.hunts.description')}
                </TableCell>
               )}
              {!isMobile && (
                <TableCell sx={{ fontSize: '0.85rem', px: 1 }}>
                  # Items
                </TableCell>
              )}
              <TableCell
                onClick={() => handleSort('location_area_name')}
                sx={{ cursor: 'pointer', fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.hunts.locationAreaName')} {sortColumn === 'location_area_name' && (sortOrder === 'ASC' ? '↑' : '↓')}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.hunts.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hunts.map((hunt) => (
              <TableRow key={hunt.id}>
                <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{renderHuntName(hunt)}</TableCell>
                {!isMobile && (
                  <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{truncateDescription(hunt.description, 48)}</TableCell>
                )}
                {!isMobile && (
                  <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>
                    {hunt.huntItems?.length || 0}
                  </TableCell>
                )}
                <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>
                  {hunt.locationAreaName}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '0.75rem', px: 1 }}>
                  {renderActions(hunt)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationContainer
        totalItems={totalHunts}
        itemType="Hunts"
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, newPage) => setPage(newPage)}
      />
      <HuntModal
        open={isEditHuntModalOpen}
        onClose={handleEditHuntModalClose}
        onHuntSaved={handleHuntUpdated}
        hunt={selectedHunt}
        mode="edit"
      />
      <HuntModal
        open={isCreateHuntModalOpen}
        onClose={handleCreateHuntModalClose}
        onHuntSaved={handleHuntCreated}
        mode="create"
      />
      <ConfirmationDialog
        open={deleteConfirmation.open}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${deleteConfirmation.hunt?.name}?`}
        onConfirm={handleDeleteHunt}
        onCancel={() => setDeleteConfirmation({ open: false, hunt: null })}
        />


    </Box>
  );
};
