import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  Avatar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../hooks/useIsMobile';
import { LocalOffer as CouponIcon, LocationOn as LocationIcon, Business as BusinessIcon } from '@mui/icons-material';
import { filterType } from '../../types/CouponList';
import { getUserCoupons } from '../../services/coupon/getUserCoupons';
import { calculateDistance } from '../../utils/calculateDistance';
import SingleCouponDialog from './SingleCouponDialog';
import { Coupon } from '../../types/Coupon';

interface CouponListProps {
  filter?: {
    type: filterType;
    id: number | undefined;
  };
  emptyListComponent?: React.ReactNode;
}

const CouponList: React.FC<CouponListProps> = ({ filter, emptyListComponent }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [selectedCoupon, setSelectedCoupon] = useState<Partial<Coupon> | null>(null);

  useEffect(() => {
    getUserCoupons(1, filter?.type, filter?.id).then((fetchedCoupons) => {
      if (userLocation) {
        const couponsWithDistance = fetchedCoupons.map(coupon => {
          const latitude = coupon.business?.locations?.[0]?.latitude;
          const longitude = coupon.business?.locations?.[0]?.longitude;
          if (latitude && Number(latitude) !== 0 && longitude && Number(longitude) !== 0) {
            const distFromUser = calculateDistance(userLocation.lat, userLocation.lng, Number(latitude), Number(longitude));
            return {
              ...coupon,
              distance: distFromUser
            };
          } else {
            return coupon;
          }
        });
        setCoupons(couponsWithDistance);
      } else {
        setCoupons(fetchedCoupons);
      }
    });
  }, [filter, userLocation]);

  useEffect(() => {
    // Get user's current location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, []);

  const handleViewCoupon = (coupon: Coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleCloseDialog = () => {
    setSelectedCoupon(null);
  };

  if (coupons.length === 0) {
    return <>{emptyListComponent}</>;
  }

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        {coupons.map((coupon) => (
          <Grid item key={coupon.id} xs={12} sm={isMobile ? 12 : 6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#e8f2ff' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={coupon.business?.imageUrl}
                    alt={coupon.business?.name}
                    sx={{ width: 48, height: 48, mr: 2 }}
                  >
                    {coupon.business?.name.split(' ').map(word => word[0]).join('').toUpperCase()}
                  </Avatar>
                  <div>
                    <Typography variant="h6" component="div">
                      {coupon.title}
                    </Typography>
                    {coupon.business && (
                      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        <BusinessIcon sx={{ fontSize: 16, mr: 0.5 }} />
                        {coupon.business.name}
                      </Typography>
                    )}
                  </div>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, textAlign: 'center' }}>
                  {coupon.description}
                </Typography>
                <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Chip
                    icon={<CouponIcon />}
                    label={t('myCoupons.viewCoupon')}
                    color="primary"
                    size="small"
                    onClick={() => handleViewCoupon(coupon)}
                    sx={{ cursor: 'pointer' }}
                  />
                  {coupon.distance && (
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                      <LocationIcon sx={{ fontSize: 16, mr: 0.5 }} />
                      {t('myCoupons.distance', { distance: coupon.distance })}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedCoupon && (
        <SingleCouponDialog
          open={!!selectedCoupon}
          onClose={handleCloseDialog}
          coupon={selectedCoupon}
        />
      )}
    </>
  );
};

export default CouponList;