import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { HuntItem } from '../../../types/HuntItem';
import { HuntItemModal } from './HuntItemModal';
import { Location } from '../../../types/Location';

interface HuntItemsTabProps {
  huntItems: HuntItem[];
  onHuntItemsChange: (huntItems: HuntItem[]) => void;
  location?: Location | undefined;
}

export const HuntItemsTab: React.FC<HuntItemsTabProps> = ({
  huntItems,
  onHuntItemsChange,
  location,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHuntItem, setSelectedHuntItem] = useState<HuntItem | undefined>(undefined);

  const handleRemoveHuntItem = (huntItemId: number | undefined) => {
    if (huntItemId) {
      const updatedHuntItems = huntItems.filter(item => item.id !== huntItemId);
      onHuntItemsChange(updatedHuntItems);
    }
  };

  const handleEditHuntItem = (huntItem: HuntItem) => {
    setSelectedHuntItem(huntItem);
    setIsModalOpen(true);
  };

  const handleCreateHuntItem = () => {
    setSelectedHuntItem(undefined);
    setIsModalOpen(true);
  };

  const handleSaveHuntItem = (huntItem: Partial<HuntItem>) => {
    let updatedHuntItems: HuntItem[];
    if (selectedHuntItem) {
      // Update existing hunt item
      updatedHuntItems = huntItems.map(item =>
        item.id === selectedHuntItem.id ? { ...item, ...huntItem } as HuntItem : item
      );
    } else {
      // Create new hunt item
      const newHuntItem = { ...huntItem, id: Date.now() } as HuntItem; // Temporary ID for new items
      updatedHuntItems = [...huntItems, newHuntItem];
    }
    onHuntItemsChange(updatedHuntItems);
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateHuntItem}
          sx={{
            width: { xs: '100%', sm: '250px' }
          }}
        >
          {t('admin.locations.createHuntItem')}
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('admin.hunts.itemTitle')}</TableCell>
            <TableCell>{t('admin.hunts.itemType')}</TableCell>
            <TableCell align="right">{t('common.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {huntItems.length > 0 ? (
            huntItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell sx={{ px: 1 }}>{t(`admin.huntItems.itemTypes.${item.itemType}`)}</TableCell>
                <TableCell align="right">
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => handleEditHuntItem(item)}
                      sx={{ mr: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <Tooltip title={item.isInUseByActiveHunt ? t('admin.huntItems.cannotDeleteInUse') : ''}>
                      <span>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleRemoveHuntItem(item.id)}
                          disabled={item.isInUseByActiveHunt}
                          sx={{
                            '&.Mui-disabled': {
                              opacity: 0.5,
                              textDecoration: 'line-through',
                            },
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <em>{t('admin.locations.noHuntItems')}</em>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <HuntItemModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveHuntItem}
        huntItem={selectedHuntItem}
        location={location}
      />
    </Box>
  );
};
