import axios from 'axios';
import { getApiPath } from '../getApiPath';

interface BusinessLocation {
  id: number;
  name: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  areaName: string;
}

export interface BusinessWithActiveCoupons {
  id: string;
  name: string;
  description: string;
  imageUrl: string;
  contactEmail: string;
  phoneNumber: string;
  website: string;
  welcomeMessage: string;
  isVerified: boolean;
  activeCouponTitles: string[];
  activeCouponDescriptions: string[];
  location: BusinessLocation;
}

interface GetBusinessesWithActiveCouponsResponse {
  businesses: BusinessWithActiveCoupons[];
  totalBusinesses: number;
}

export const getBusinessesWithActiveCoupons = async (): Promise<GetBusinessesWithActiveCouponsResponse> => {
  try {
    const response = await axios.get<GetBusinessesWithActiveCouponsResponse>(
      getApiPath('/business/getBusinessesWithActiveCoupons'),
      {
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching businesses with active coupons');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};
