import { useAuth } from '../context/AuthContext';

export function useRole() {
  const { user, isAuthenticated, isLoading } = useAuth();

  if (isLoading || !isAuthenticated) {
    return null;
  }

  return user?.role || null;
}