import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/User';
import { createUser } from '../../../services/user/createUser';
import Roles, { RoleType } from '../../../types/Roles';
import { ResponsiveTextField } from '../../../components/common';

interface CreateUserModalProps {
  open: boolean;
  onClose: () => void;
  onUserCreated: () => void;
}

const initialFormData: Partial<User> = {
  firstName: '',
  lastName: '',
  email: '',
  role: Roles.Consumer as RoleType,
};

export const CreateUserModal: React.FC<CreateUserModalProps> = ({ open, onClose, onUserCreated }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Partial<User>>(initialFormData);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (open) {
      // Reset form data when the modal is opened
      setFormData(initialFormData);
      setPassword('');
    }
  }, [open]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (event: SelectChangeEvent<RoleType>) => {
    setFormData((prev) => ({ ...prev, role: event.target.value as RoleType }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createUser({ ...formData, password });
      onUserCreated();
      onClose();
      // Reset form data after successful creation
      setFormData(initialFormData);
      setPassword('');
    } catch (error) {
      console.error('Error creating user:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('admin.users.addUser')}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <ResponsiveTextField
              name="firstName"
              label={t('admin.users.firstName')}
              value={formData.firstName}
              onChange={handleTextChange}
              fullWidth
              required
            />
            <ResponsiveTextField
              name="lastName"
              label={t('admin.users.lastName')}
              value={formData.lastName}
              onChange={handleTextChange}
              fullWidth
              required
            />
            <ResponsiveTextField
              name="email"
              label={t('admin.users.email')}
              type="email"
              value={formData.email}
              onChange={handleTextChange}
              fullWidth
              required
            />
            <ResponsiveTextField
              name="password"
              label={t('admin.users.password')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
            />
            <FormControl fullWidth>
              <InputLabel id="role-label">{t('admin.users.role')}</InputLabel>
              <Select<RoleType>
                labelId="role-label"
                name="role"
                value={formData.role}
                onChange={handleRoleChange}
                fullWidth
                required
              >
                <MenuItem value={Roles.Consumer}>{t('roles.consumer')}</MenuItem>
                <MenuItem value={Roles.Business}>{t('roles.business')}</MenuItem>
                <MenuItem value={Roles.Admin}>{t('roles.admin')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button type="submit" variant="contained">{t('common.create')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};