import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  MenuItem,
  IconButton,
  InputAdornment,
  Chip,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Hunt } from '../../../types/Hunt';
import { ResponsiveTextField, ImageUploadModal } from '../../../components/common';
import { MapCoordinatesPicker } from '../../../components/common/MapCoordinatesPicker';
import InfoIcon from '@mui/icons-material/Info';
import { HAWAIIAN_ISLANDS } from '../../../types/Location';
import { HuntTag } from '../../../types/Hunt';
import AddIcon from '@mui/icons-material/Add';

interface HuntFormProps {
  formData: Partial<Hunt>;
  setFormData: React.Dispatch<React.SetStateAction<Partial<Hunt>>>;
  mapCenter: { lat: number; lng: number };
  mapMarker: { lat: number; lng: number } | null;
  setMapCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>;
  setMapMarker: React.Dispatch<React.SetStateAction<{ lat: number; lng: number } | null>>;
  getActualHuntItemCount: () => number;
  handleHuntImageCapture: (file: File | null) => void;
  hunt?: Hunt | null;
}

export const HuntForm: React.FC<HuntFormProps> = ({
  formData,
  setFormData,
  setMapCenter,
  setMapMarker,
  getActualHuntItemCount,
  handleHuntImageCapture,
  hunt,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!formData.maxDurationHours) {
      setFormData((prev) => ({ ...prev, maxDurationHours: 48 }));
    }
  }, [formData.maxDurationHours, setFormData]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setFormData((prev) => ({ ...prev, latitude: lat, longitude: lng }));
    setMapCenter({ lat, lng });
    setMapMarker({ lat, lng });
  };

  const handleTagsChange = (event: React.SyntheticEvent, newTags: string[]) => {
    const tags: HuntTag[] = newTags.map(name => ({
      id: 0, // temporary ID for new tags
      name: name
    }));
    setFormData((prev) => ({ ...prev, tags }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="name"
          label={t('admin.hunts.name')}
          value={formData.name || ''}
          onChange={handleTextChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative' }}>
          <ResponsiveTextField
            select
            label={t('admin.hunts.locationAreaName')}
            name="locationAreaName"
            value={formData.locationAreaName || ''}
            onChange={handleTextChange}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={t('admin.hunts.locationAreaNameTooltip')}>
                    <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                      <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            {HAWAIIAN_ISLANDS.map((island) => (
              <MenuItem key={island} value={island}>
                {island}
              </MenuItem>
            ))}
          </ResponsiveTextField>
        </div>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveTextField
          name="description"
          label={t('admin.hunts.description')}
          value={formData.description || ''}
          onChange={handleTextChange}
          fullWidth
          multiline
          rows={4}
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Tooltip title={t('admin.hunts.maxDurationHoursTooltip')} arrow>
          <ResponsiveTextField
            name="maxDurationHours"
            label={t('admin.hunts.maxDurationHours')}
            type="number"
            value={formData.maxDurationHours || 48}
            onChange={handleNumberChange}
            fullWidth
            required
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={4}>
        <Tooltip title={t('admin.hunts.estimatedTimeHoursTooltip')} arrow>
          <ResponsiveTextField
            name="estimatedTimeHours"
            label={t('admin.hunts.estimatedTimeHours')}
            type="number"
            value={formData.estimatedTimeHours || ''}
            onChange={handleNumberChange}
            fullWidth
            required
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={4}>
        {getActualHuntItemCount() > 0 ? (
          <ResponsiveTextField
            name="actualHuntItemCount"
            label={t('admin.hunts.actualHuntItemCount')}
            value={getActualHuntItemCount()}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        ) : (
          <Tooltip title={t('admin.hunts.fakeHuntItemCountTooltip')} arrow>
            <ResponsiveTextField
              name="fakeHuntItemCount"
              label={t('admin.hunts.fakeHuntItemCount')}
              type="number"
              value={formData.fakeHuntItemCount || ''}
              onChange={handleNumberChange}
              fullWidth
            />
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="price"
          label={t('admin.hunts.price')}
          type="number"
          value={formData.price ?? ''}
          onChange={handleNumberChange}
          fullWidth
          inputProps={{ step: "0.01" }}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ position: 'relative' }}>
          <ResponsiveTextField
            name="couponCode"
            label={t('admin.hunts.couponCode')}
            value={formData.couponCode || ''}
            onChange={handleTextChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={t('admin.hunts.couponCodeTooltip')}>
                    <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                      <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <ResponsiveTextField
          name="totalDistanceMiles"
          label={t('admin.hunts.totalDistanceMiles')}
          type="number"
          value={formData.totalDistanceMiles || ''}
          onChange={handleNumberChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.enabled || false}
              onChange={handleCheckboxChange}
              name="enabled"
            />
          }
          label={t('admin.hunts.enabled')}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={(formData.tags || []).map(tag => tag.name)}
          onChange={handleTagsChange}
          inputValue={inputValue}
          onInputChange={(event, newValue, reason) => {
            if (reason === 'reset') {
              return;
            }
            setInputValue(newValue);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && inputValue.trim()) {
              event.preventDefault();
              const currentTags = (formData.tags || []).map(tag => tag.name);
              if (!currentTags.includes(inputValue.trim())) {
                handleTagsChange({} as React.SyntheticEvent, [...currentTags, inputValue.trim()]);
                setInputValue('');
              }
            }
          }}
          filterOptions={(x) => x}
          noOptionsText=""
          renderTags={(value: string[], getTagProps) => {
            return value.map((option: string, index: number) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                variant="outlined"
                size="small"
                label={option}
                sx={{
                  m: 0.5,
                  borderRadius: '16px',
                  height: '24px',
                  '& .MuiChip-deleteIcon': {
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'error.main',
                    },
                  },
                }}
              />
            ));
          }}
          renderInput={(params) => (
            <ResponsiveTextField
              {...params}
              label={t('admin.hunts.tags')}
              placeholder={t('admin.hunts.addTagPlaceholder')}
              helperText={t('admin.hunts.tagsHelperText')}
              minRows={2}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Tooltip title={t('admin.hunts.tagsTooltip')}>
                        <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                          <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <div style={{
                    position: 'absolute',
                    bottom: 2,
                    right: 2,
                    background: 'white'
                  }}>
                    <IconButton
                      size="medium"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (inputValue.trim()) {
                          const currentTags = (formData.tags || []).map(tag => tag.name);
                          if (!currentTags.includes(inputValue.trim())) {
                            handleTagsChange({} as React.SyntheticEvent, [...currentTags, inputValue.trim()]);
                            setInputValue('');
                          }
                        }
                      }}
                    >
                      <AddIcon sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }} />
                    </IconButton>
                  </div>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <MapCoordinatesPicker
          latitude={Number(formData.latitude)}
          longitude={Number(formData.longitude)}
          onCoordinatesChange={handleCoordinatesChange}
          title={t('admin.hunts.huntLocation')}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUploadModal
          title={t('admin.hunts.huntImage')}
          onImageCapture={handleHuntImageCapture}
          existingImageUrl={hunt?.imageUrl}
        />
      </Grid>
    </Grid>
  );
};
