import React, { useState } from 'react';
import { Box, Button, Card, CardContent, CardActions, IconButton, Tooltip, Chip, Grid, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { format } from 'date-fns';
import { Coupon, NewCoupon } from '../../../../types/Coupon';
import { CouponModal } from '../shared/CouponModal';
import ConfirmationDialog from '../../../../components/common/ConfirmationDialog';

interface CouponInfoFormProps {
  t: TFunction;
  coupons: (Coupon | NewCoupon)[];
  business: { id: number } | null;
  onCouponsChange: (coupons: (Coupon | NewCoupon)[]) => void;
}

export const CouponInfoForm: React.FC<CouponInfoFormProps> = ({
  t,
  coupons,
  business,
  onCouponsChange,
}) => {
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | NewCoupon | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState<number | null>(null);

  const handleOpenCouponModal = (coupon: Coupon | NewCoupon | null = null) => {
    setSelectedCoupon(coupon);
    setCouponModalOpen(true);
  };

  const handleCloseCouponModal = () => {
    setSelectedCoupon(null);
    setCouponModalOpen(false);
  };

  const handleSaveCoupon = (coupon: Coupon | NewCoupon) => {
    const updatedCoupons = 'id' in coupon
      ? coupons.map((c) => ('id' in c && c.id === coupon.id ? coupon : c))
      : [...coupons, { ...coupon, id: Date.now() }];

    onCouponsChange(updatedCoupons);
    handleCloseCouponModal();
  };

  const handleDeleteCoupon = (couponId: number) => {
    setCouponToDelete(couponId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteCoupon = () => {
    if (couponToDelete !== null) {
      const updatedCoupons = coupons.filter((coupon) =>
        !('id' in coupon) || coupon.id !== couponToDelete
      );
      onCouponsChange(updatedCoupons);
    }
    setDeleteConfirmationOpen(false);
    setCouponToDelete(null);
  };

  const cancelDeleteCoupon = () => {
    setDeleteConfirmationOpen(false);
    setCouponToDelete(null);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Button
          variant="contained"
          onClick={() => handleOpenCouponModal()}
          startIcon={<AddIcon />}
          sx={{
            width: { xs: '100%', sm: '200px' }
          }}
        >
          {t('admin.businesses.createCoupon')}
        </Button>
      </Box>
      {coupons.length > 0 ? (
        <Grid container spacing={2}>
          {coupons.map((coupon) => {
            const now = new Date();
            const isStartDateValid = coupon.startDate ? new Date(coupon.startDate) <= now : true;
            const isEndDateValid = coupon.endDate ? new Date(coupon.endDate) >= now : true;
            const isCurrentlyActive = coupon.isActive && isStartDateValid && isEndDateValid;

            return (
              <Grid item xs={12} sm={6} md={4} key={'id' in coupon ? coupon.id : `temp-${Date.now()}`}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>{coupon.title}</Typography>
                    {coupon.startDate && (
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {t('admin.coupons.startDate')}: {format(new Date(coupon.startDate), 'PP')}
                      </Typography>
                    )}
                    {coupon.endDate && (
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {t('admin.coupons.endDate')}: {format(new Date(coupon.endDate), 'PP')}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                    <Chip
                      label={isCurrentlyActive ? t('admin.coupons.active') : t('admin.coupons.inactive')}
                      color={isCurrentlyActive ? "success" : "error"}
                      icon={isCurrentlyActive ? <CheckCircleIcon /> : <CancelIcon />}
                      size="small"
                    />
                    <Box>
                      <Tooltip title={t('admin.businesses.businessUser.editCoupon')}>
                        <IconButton onClick={() => handleOpenCouponModal(coupon)} size="small">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {'id' in coupon && (
                        <Tooltip title={t('admin.businesses.businessUser.deleteCoupon')}>
                          <IconButton onClick={() => handleDeleteCoupon(coupon.id)} size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <Typography sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
          {t('admin.businesses.noCoupons')}
        </Typography>
      )}

      <CouponModal
        open={couponModalOpen}
        onClose={handleCloseCouponModal}
        onSave={handleSaveCoupon}
        coupon={selectedCoupon || undefined}
        businessId={business?.id || 0}
      />

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        title={t('admin.coupons.deleteConfirmationTitle')}
        message={t('admin.coupons.deleteConfirmationMessage')}
        onConfirm={confirmDeleteCoupon}
        onCancel={cancelDeleteCoupon}
        confirmButtonText={t('common.delete')}
      />
    </Box>
  );
};
