import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../components/common';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getHuntInformation } from '../../services/activehunt/getHuntInformation';
import { ActiveHunt } from '../../types/ActiveHunt';
import { useTranslation } from 'react-i18next';
import HuntInformationCard from './HuntInformationCard';
import HuntItemList from './HuntItemList';
import HuntItemModal from './HuntItemModal';
import { ActiveHuntItem } from '../../types/ActiveHunt';
import { startHuntItem } from '../../services/activehunt/startHuntItem';

export const ActiveHuntPage: React.FC = () => {
  const { huntId } = useParams<{ huntId: string }>();
  const [huntInfo, setHuntInfo] = useState<ActiveHunt | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<ActiveHuntItem | null>(null);

  const fetchHuntInfo = async () => {
    try {
      if (huntId) {
        setLoading(true);
        const info = await getHuntInformation(parseInt(huntId, 10));
        setHuntInfo(info);
      }
    } catch (err) {
      console.error(err);
      setError(t('activeHunt.errorFetchingHunt'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHuntInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [huntId, t]);

  const handleItemClick = (item: ActiveHuntItem) => {
    // If the item is not_started, start it
    if (item.status === 'not_started' && huntId) {
      startHuntItem(parseInt(huntId, 10), item.id);
    }
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    fetchHuntInfo(); // Refresh hunt information when modal is closed
  };

  if (loading) {
    return (
      <PageLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <Typography color="error">{error}</Typography>
      </PageLayout>
    );
  }

  if (!huntInfo) {
    return (
      <PageLayout>
        <Typography>{t('activeHunt.noHuntFound')}</Typography>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Box sx={{ p: { xs: 2, sm: 3 }, maxWidth: '1200px', margin: '0 auto', width: '100%', boxSizing: 'border-box' }}>
        <HuntInformationCard huntInfo={huntInfo} onItemClick={handleItemClick} />
        <HuntItemList
          huntItems={huntInfo.huntItems}
          onItemClick={handleItemClick}
        />
        {selectedItem && (
          <HuntItemModal
            open={!!selectedItem}
            onClose={handleCloseModal}
            item={selectedItem}
            huntId={huntId ? parseInt(huntId, 10) : 0}
          />
        )}
      </Box>
    </PageLayout>
  );
};