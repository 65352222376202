import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  SelectChangeEvent,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/User';
import { Business } from '../../../types/Business';
import { updateUser } from '../../../services/user/updateUser';
import Roles, { RoleType } from '../../../types/Roles';
import { format } from 'date-fns';
import { ResponsiveTextField, ConfirmationDialog } from '../../../components/common';
import { getBusinesses } from '../../../services/business/getBusinesses';

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  onUserUpdated: () => void;
  user: User | null;
}

export const EditUserModal: React.FC<EditUserModalProps> = ({ open, onClose, onUserUpdated, user }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Partial<User>>({});
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [businessList, setBusinessList] = useState<Business[]>([]);
  const [showBusinessConfirmation, setShowBusinessConfirmation] = useState(false);
  const [pendingBusinessId, setPendingBusinessId] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        if (businessList.length === 0) {
          const businesses = await getBusinesses(0, 1000);
          setBusinessList(businesses.businesses);
        }
        setFormData({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role as RoleType,
          lastActive: user.lastActive,
          isVerified: user.isVerified,
          auth_provider: user.auth_provider,
          businessId: user.businessId,
        });
        setNewPassword('');
        setConfirmPassword('');
        setChangePassword(false);
        setPasswordError('');
      }
    };

    fetchData();
  }, [user, businessList]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (event: SelectChangeEvent<RoleType>) => {
    setFormData((prev) => ({ ...prev, role: event.target.value as RoleType }));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords don't match");
      return false;
    }
    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    }
    setPasswordError('');
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (user) {
      if (changePassword && !validatePasswords()) {
        return;
      }
      try {
        const dataToUpdate = { ...formData };
        if (changePassword && newPassword) {
          dataToUpdate.password = newPassword;
        }
        await updateUser(user.id, dataToUpdate);
        onUserUpdated();
        onClose();
      } catch (error) {
        console.error('Error updating user:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handleBusinessChange = (event: SelectChangeEvent<number | ''>) => {
    const newBusinessId = event.target.value === '' ? null : Number(event.target.value);
    if (formData.businessId !== newBusinessId) {
      setPendingBusinessId(newBusinessId);
      setShowBusinessConfirmation(true);
    }
  };

  const handleConfirmBusinessChange = () => {
    setFormData(prev => ({ ...prev, businessId: pendingBusinessId ?? undefined }));
    setShowBusinessConfirmation(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t('admin.users.editUser')}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <ResponsiveTextField
                name="firstName"
                label={t('admin.users.firstName')}
                value={formData.firstName || ''}
                onChange={handleTextChange}
                fullWidth
                required
              />
              <ResponsiveTextField
                name="lastName"
                label={t('admin.users.lastName')}
                value={formData.lastName || ''}
                onChange={handleTextChange}
                fullWidth
                required
              />
              <ResponsiveTextField
                name="email"
                label={t('admin.users.email')}
                type="email"
                value={formData.email || ''}
                onChange={handleTextChange}
                fullWidth
                required
              />
              <FormControl fullWidth>
                <InputLabel id="role-label">{t('admin.users.role')}</InputLabel>
                <Select<RoleType>
                  labelId="role-label"
                  name="role"
                  value={formData.role || ''}
                  onChange={handleRoleChange}
                  fullWidth
                  required
                >
                  <MenuItem value={Roles.Consumer}>{t('roles.consumer')}</MenuItem>
                  <MenuItem value={Roles.Business}>{t('roles.business')}</MenuItem>
                  <MenuItem value={Roles.Admin}>{t('roles.admin')}</MenuItem>
                </Select>
              </FormControl>

              {formData.role === Roles.Business && (
                <FormControl fullWidth>
                  <InputLabel id="business-label">{t('admin.users.business')}</InputLabel>
                  <Select
                    labelId="business-label"
                    name="business"
                    value={formData.businessId || ''}
                    onChange={handleBusinessChange}
                    fullWidth
                  >
                    <MenuItem value="">{t('admin.locations.noBusiness')}</MenuItem>
                    {businessList.map((business) => (
                      <MenuItem key={business.id} value={business.id}>
                        {business.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <ResponsiveTextField
                name="lastActive"
                label={t('admin.users.lastActive')}
                value={formData.lastActive ? format(new Date(formData.lastActive), 'PPpp') : ''}
                InputProps={{ readOnly: true }}
                fullWidth
              />
              {/*
              <ResponsiveTextField
                name="isVerified"
                label={t('admin.users.isVerified')}
                value={formData.isVerified ? t('common.yes') : t('common.no')}
                InputProps={{ readOnly: true }}
                fullWidth
              />*/}
              <ResponsiveTextField
                name="authProvider"
                label={t('admin.users.authProvider')}
                value={formData.auth_provider || ''}
                InputProps={{
                  readOnly: true,
                  disabled: true
                }}
                fullWidth
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={changePassword}
                    onChange={(e) => setChangePassword(e.target.checked)}
                  />
                }
                label={t('admin.users.changePassword')}
              />
              {changePassword && (
                <>
                  <ResponsiveTextField
                    name="newPassword"
                    label={t('admin.users.newPassword')}
                    type="password"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    fullWidth
                    required
                  />
                  <ResponsiveTextField
                    name="confirmPassword"
                    label={t('admin.users.confirmPassword')}
                    type="password"
                    value={confirmPassword}
                    onChange={handlePasswordChange}
                    fullWidth
                    required
                  />
                  {passwordError && (
                    <Typography color="error" variant="body2">
                      {passwordError}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('common.cancel')}</Button>
            <Button type="submit" variant="contained">{t('common.update')}</Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationDialog
        open={showBusinessConfirmation}
        title={t('admin.users.editUser')}
        message={t('admin.businesses.confirmChangeMessage', 'Are you sure you want to change the business for this user?')}
        onConfirm={handleConfirmBusinessChange}
        onCancel={() => setShowBusinessConfirmation(false)}
      />
    </>
  );
};