import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Pagination,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  LinearProgress,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUserHunts } from '../../services/userhunt/getUserHunts';
import { UserHunt, UserHuntStatus } from '../../types/UserHunt';
import { PageLayout } from '../../components/common';
import { useIsMobile } from '../../hooks/useIsMobile';
import FeatureNotImplementedDialog from '../../components/common/FeatureNotImplemented';
import { useNavigate } from 'react-router-dom';
import { startHunt } from '../../services/hunt/startHunt';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { AccessTime, PlayArrow as PlayArrowIcon, CheckCircle as CheckCircleIcon, ShoppingCart as ShoppingCartIcon, LocalOffer as CouponIcon } from '@mui/icons-material';
import CouponsDialog from '../../components/CouponList/CouponsDialog';
import ViewHuntStatsDialog from './ViewHuntStatsDialog';
import { Link } from 'react-router-dom';
import { deleteUserHunt } from '../../services/userhunt/deleteUserHunt';
import { useHasRole } from '../../hooks/useHasRole';
import Roles from '../../types/Roles';

const MyHunts: React.FC = () => {
  const isMobile = useIsMobile();
  const [userHunts, setUserHunts] = useState<UserHunt[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedHunt, setSelectedHunt] = useState<UserHunt | null>(null);
  const navigate = useNavigate();
  const [couponsDialogOpen, setCouponsDialogOpen] = useState(false);
  const [selectedHuntId, setSelectedHuntId] = useState<number | null>(null);
  const [statsDialogOpen, setStatsDialogOpen] = useState(false);
  const [selectedHuntForStats, setSelectedHuntForStats] = useState<UserHunt | null>(null);
  const [deleteHuntId, setDeleteHuntId] = useState<number | null>(null);
  const isAdmin = useHasRole(Roles.Admin);

  useEffect(() => {
    fetchUserHunts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchUserHunts = async () => {
    try {
      const response = await getUserHunts(page, 7);
      setUserHunts(response.userHunts);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching user hunts:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleHuntAction = async (hunt: UserHunt, action: string) => {
    if (action === 'Go to Hunt') {
      navigate(`/activehunt/${hunt.huntId}`);
      return;
    }
    if (action === 'Start Hunt') {
      try {
        await startHunt(hunt.huntId);
        navigate(`/activehunt/${hunt.huntId}`);
        return;
      } catch (error) {
        console.error('Error starting hunt:', error);
        setDialogMessage('Failed to start the hunt. Please try again.');
        setDialogOpen(true);
        return;
      }
    }
    if (action === 'View Hunt Stats') {
      setSelectedHuntForStats(hunt);
      setStatsDialogOpen(true);
      return;
    }
    setDialogMessage(`The "${action}" feature is not yet implemented.`);
    setDialogOpen(true);
  };

  const handleStartHunt = (hunt: UserHunt) => {
    setSelectedHunt(hunt);
    setConfirmDialogOpen(true);
  };

  const handleConfirmStartHunt = async () => {
    if (selectedHunt) {
      try {
        await startHunt(selectedHunt.huntId);
        navigate(`/activehunt/${selectedHunt.huntId}`);
      } catch (error) {
        console.error('Error starting hunt:', error);
        setDialogMessage('Failed to start the hunt. Please try again.');
        setDialogOpen(true);
      }
    }
    setConfirmDialogOpen(false);
  };

  const handleCancelStartHunt = () => {
    setConfirmDialogOpen(false);
    setSelectedHunt(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const calculateTimeRemaining = (hunt: UserHunt) => {
    if (hunt.status !== UserHuntStatus.InProgress || !hunt.endTime) return { timeString: '', percentage: 0 };
    const now = new Date();
    const endTime = new Date(hunt.endTime);
    const startTime = new Date(hunt.startTime || now);
    const totalDuration = endTime.getTime() - startTime.getTime();
    const remainingTime = endTime.getTime() - now.getTime();

    const percentage = Math.max(0, Math.min(100, (remainingTime / totalDuration) * 100));

    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);

    return {
      timeString: parts.join(' ') || t('myHunts.lessThanAMinute'),
      percentage,
    };
  };

  const renderHuntStatus = (hunt: UserHunt) => {
    let icon, label, color;
    switch (hunt.status) {
      case UserHuntStatus.Purchased:
        icon = <ShoppingCartIcon />;
        label = t('myHunts.status.purchased');
        color = 'primary';
        break;
      case UserHuntStatus.InProgress:
        icon = <PlayArrowIcon />;
        label = t('myHunts.status.in_progress');
        color = 'secondary';
        break;
      case UserHuntStatus.Finished:
        icon = <CheckCircleIcon />;
        label = t('myHunts.status.finished');
        color = 'success';
        break;
    }

    return (
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          right: -50,
          transform: 'rotate(45deg)',
          paddingX: 5,
          zIndex: 1,
          backgroundColor: `${color}.main`,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          py: 0.5,
        }}
      >
        {icon}
        <Typography variant="body2" fontWeight="bold">
          {label}
        </Typography>
      </Box>
    );
  };

  const renderTimeRemaining = (hunt: UserHunt) => {
    if (hunt.status !== UserHuntStatus.InProgress) return null;

    const { timeString, percentage } = calculateTimeRemaining(hunt);

    return (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTime sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="body2">
            {t('myHunts.timeRemaining', { time: timeString })}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: 8,
            borderRadius: 4,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            '& .MuiLinearProgress-bar': {
              borderRadius: 4,
            },
          }}
        />
      </Box>
    );
  };

  const handleViewCoupons = (huntId: number) => {
    setSelectedHuntId(huntId);
    setCouponsDialogOpen(true);
  };

  const handleViewHuntStats = (hunt: UserHunt) => {
    console.log(`handleViewHuntStats: ${JSON.stringify(hunt)}`);
    setSelectedHuntForStats(hunt);
    setStatsDialogOpen(true);
  };

  const handleDeleteHunt = async () => {
    if (deleteHuntId) {
      try {
        await deleteUserHunt(deleteHuntId);
        await fetchUserHunts(); // Refresh the list
        setDeleteHuntId(null);
      } catch (error) {
        console.error('Error deleting hunt:', error);
        setDialogMessage('Failed to delete the hunt. Please try again.');
        setDialogOpen(true);
      }
    }
  };

  const renderExistingHuntActions = (hunt: UserHunt) => {
    const now = new Date();
    const endTime = hunt.endTime ? new Date(hunt.endTime) : null;

    switch (hunt.status) {
      case UserHuntStatus.Purchased:
        return (
          <>
            <Alert severity="info" sx={{ mt: 1 }}>
              {t('myHunts.startForCoupons')}
            </Alert>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleStartHunt(hunt)}
              fullWidth
            >
              {t('myHunts.startHunt')}
            </Button>
          </>
        );
      case UserHuntStatus.InProgress:
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleHuntAction(hunt, 'Go to Hunt')}
            fullWidth
          >
            {t('myHunts.goToHunt')}
          </Button>
        );
      case UserHuntStatus.Finished:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {endTime && now < endTime && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleViewCoupons(hunt.huntId)}
                fullWidth
                startIcon={<CouponIcon />}
                sx={{ mb: 2 }}
              >
                {t('myHunts.couponsAvailable')}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewHuntStats(hunt)}
              fullWidth
            >
              {t('myHunts.viewHuntStats')}
            </Button>
          </Box>
        );
    }
  };

  const renderHuntAction = (hunt: UserHunt) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
        {renderExistingHuntActions(hunt)}

        {isAdmin && (
          <Button
            variant="outlined"
            color="error"
            onClick={() => setDeleteHuntId(hunt.id)}
            fullWidth
          >
            {t('myHunts.adminRemoveHunt')}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <PageLayout title={t('myHunts.title')}>
      <Box
        sx={{
          px: { xs: 2, sm: 3 },
          py: 3,
          mx: 'auto',
          maxWidth: '1200px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {userHunts.length > 0 ? (
          <>
            <Grid
              container
              spacing={3}
              justifyContent="center"
            >
              {userHunts.map((hunt) => (
                <Grid item key={hunt.id} xs={12} sm={isMobile ? 12 : 6} md={4}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
                    {renderHuntStatus(hunt)}
                    <CardMedia
                      component="img"
                      sx={{
                        height: 200,
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                      image={hunt.huntImageUrl}
                      alt={hunt.huntName}
                    />
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Typography gutterBottom variant="h5" component="div" align="center">
                        {hunt.huntName}
                      </Typography>
                      {renderTimeRemaining(hunt)}
                    </CardContent>
                    <CardActions sx={{ p: 2, pt: 0 }}>
                      {renderHuntAction(hunt)}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
              />
            </Box>
          </>
        ) : (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="body1" sx={{ fontStyle: 'italic', mb: 3 }}>
              {t('myHunts.noHunts')}
            </Typography>
            <Link
              to="/findhunts"
              style={{
                color: 'primary.main',
                textDecoration: 'underline',
                fontWeight: 'bold'
              }}
            >
              {t('myHunts.findHuntToStart')}
            </Link>
          </Box>
        )}

        <ConfirmationDialog
          open={confirmDialogOpen}
          title={t('myHunts.startHuntConfirmation.title')}
          message={selectedHunt ? t('myHunts.startHuntConfirmation.message', { hours: selectedHunt.maxHuntHours }) : ''}
          onConfirm={handleConfirmStartHunt}
          onCancel={handleCancelStartHunt}
        />
        <FeatureNotImplementedDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          message={dialogMessage}
        />
        <CouponsDialog
          open={couponsDialogOpen}
          title={t('activeHunt.huntCoupons')}
          onClose={() => setCouponsDialogOpen(false)}
          filterType={'hunt'}
          filterId={selectedHuntId || undefined}
        />
        {selectedHuntForStats && (
          <ViewHuntStatsDialog
            huntId={selectedHuntForStats.huntId}
            open={statsDialogOpen}
            onClose={() => {
              setStatsDialogOpen(false);
              setSelectedHuntForStats(null);
            }}
          />
        )}
        <ConfirmationDialog
          open={deleteHuntId !== null}
          title={t('myHunts.removeHuntConfirmation.title')}
          message={t('myHunts.removeHuntConfirmation.message')}
          onConfirm={handleDeleteHunt}
          onCancel={() => setDeleteHuntId(null)}
        />
      </Box>
    </PageLayout>
  );
};

export default MyHunts;
