import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MainLayout from './layouts/MainLayout';
import AppRoutes from './routes';
import theme from './theme';
import GoogleAnalytics from './components/GoogleAnalytics';

function App() {
  const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID || '';

  return (
    <Router>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainLayout>
              <AppRoutes />
            </MainLayout>
            <GoogleAnalytics measurementId={gaTrackingId} />
          </ThemeProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;