import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { filterType } from '../../types/CouponList';
import CouponList from '.';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
interface CouponsDialogProps {
  open: boolean;
  onClose: () => void;
  filterType?: filterType;
  filterId?: number | undefined;
  title: string;
  showAllCouponsButton?: boolean;
}
const CouponsDialog: React.FC<CouponsDialogProps> = ({ open, onClose, filterType, filterId, title, showAllCouponsButton = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pr: 6 }} textAlign={'center'}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {showAllCouponsButton && (
        <Typography variant="body1" sx={{ fontStyle: 'italic', textAlign: 'center', my: 2 }}>
          {t('myCoupons.theseAreHuntCoupons')}
          <Button
            sx={{
              textTransform: 'none',
              p: 0,
              minWidth: 'auto',
              verticalAlign: 'baseline',
              fontStyle: 'italic'
            }}
            onClick={() => navigate('/my-coupons')}
          >
            {t('myCoupons.viewAllCoupons')}
          </Button>
        </Typography>
      )}
      <DialogContent>
        <CouponList
          filter={{ type: filterType, id: filterId }}
          emptyListComponent={
            <Typography variant="body1" sx={{ fontStyle: 'italic', textAlign: 'center', my: 2 }}>
              {t('myCoupons.noCouponsAvailable', 'No Coupons Available')}
            </Typography>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default CouponsDialog;
