import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, List, ListItem, ListItemText, ListItemAvatar, Avatar, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../components/common';
import { getBusinessesWithActiveCoupons, BusinessWithActiveCoupons } from '../../services/business/getBusinessesWithActiveCoupons';
import ShowBusinessModal from '../LandingPage/ShowBusinessModal';
import { calculateDistance } from '../../utils/calculateDistance';
import { useIsMobile } from '../../hooks/useIsMobile';

interface BusinessWithDistance extends BusinessWithActiveCoupons {
  distance?: number;
}

export const FeaturedBusinesses: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [rawBusinesses, setRawBusinesses] = useState<BusinessWithActiveCoupons[]>([]);
  const [displayedBusinesses, setDisplayedBusinesses] = useState<BusinessWithDistance[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<BusinessWithDistance | null>(null);
  const [userLocation, setUserLocation] = useState<GeolocationCoordinates | null>(null);
  const [selectedIsland, setSelectedIsland] = useState<string>('all');

  const fetchBusinesses = useCallback(async () => {
    try {
      const response = await getBusinessesWithActiveCoupons();
      return response.businesses;
    } catch (error) {
      console.error('Error fetching businesses:', error);
      return [];
    }
  }, []);

  // Effect for initial business fetch
  useEffect(() => {
    const loadBusinesses = async () => {
      const fetchedBusinesses = await fetchBusinesses();
      setRawBusinesses(fetchedBusinesses);
    };

    loadBusinesses();
  }, [fetchBusinesses]);

  // Effect for getting user location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setUserLocation(position.coords),
        (error) => console.error('Error getting user location:', error)
      );
    }
  }, []);

  // Effect for updating distances when location or raw businesses change
  useEffect(() => {
    if (!userLocation || rawBusinesses.length === 0) return;

    const businessesWithDistance = rawBusinesses.map(business => ({
      ...business,
      distance: calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        business.location.latitude,
        business.location.longitude
      )
    }));

    businessesWithDistance.sort((a, b) => (a.distance || 0) - (b.distance || 0));
    setDisplayedBusinesses(businessesWithDistance);
  }, [userLocation, rawBusinesses]);

  // Effect for filtering businesses by island
  useEffect(() => {
    if (rawBusinesses.length === 0) return;

    let filteredBusinesses = rawBusinesses;
    if (selectedIsland !== 'all') {
      filteredBusinesses = rawBusinesses.filter(
        business => business.location.areaName === selectedIsland
      );
    }

    const businessesWithDistance = filteredBusinesses.map(business => ({
      ...business,
      distance: userLocation ? calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        business.location.latitude,
        business.location.longitude
      ) : undefined
    }));

    businessesWithDistance.sort((a, b) => (a.distance || 0) - (b.distance || 0));
    setDisplayedBusinesses(businessesWithDistance);
  }, [selectedIsland, userLocation, rawBusinesses]);

  const handleBusinessClick = useCallback((business: BusinessWithDistance) => {
    setSelectedBusiness(business);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedBusiness(null);
  }, []);

  const getUniqueIslands = useCallback(() => {
    const islands = rawBusinesses.map(business => business.location.areaName);
    return ['all', ...Array.from(new Set(islands))];
  }, [rawBusinesses]);

  return (
    <PageLayout title={t('featuredBusinesses.title')}>
      <Box className="max-w-3xl mx-auto flex flex-col h-full">
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 1, fontStyle: 'italic', textAlign: 'center' }}>
          {t('featuredBusinesses.description')}
        </Typography>

        <FormControl className="mb-4 mt-2 mx-auto">
          <InputLabel id="island-select-label">{t('common.island')}</InputLabel>
          <Select
            labelId="island-select-label"
            value={selectedIsland}
            label={t('common.island')}
            onChange={(e) => setSelectedIsland(e.target.value)}
            className="min-w-[300px] md:min-w-[400px] w-full max-h-[40px] md:max-h-[50px]"
          >
            {getUniqueIslands().map((island) => (
              <MenuItem key={island} value={island}>
                {island === 'all' ? t('common.allIslands') : island}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="overflow-y-auto flex-grow">
          <List className="flex flex-col items-center">
            {displayedBusinesses.map((business) => (
              <ListItem
                key={business.id}
                button
                onClick={() => handleBusinessClick(business)}
                className="hover:bg-hulahunt-teal-50 transition-colors duration-300 cursor-pointer w-full max-w-xl"
              >
                <ListItemAvatar>
                  <Avatar
                    src={business.imageUrl}
                    alt={business.name}
                    className="w-16 h-16 mr-4"
                  >
                    {business.name.split(' ').map(word => word[0]).join('').toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className="text-hulahunt-secondary underline"
                    >
                      {business.name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        component="span"
                        className="text-hulahunt-teal-600"
                      >
                        {`${business.location.city}, ${business.location.state} — ${business.location.areaName}`}
                      </Typography>
                      {business.welcomeMessage && (
                        <Typography variant="body2" component="span" className="text-hulahunt-teal-600 italic block mt-1">
                          {business.welcomeMessage.length > (isMobile ? 96 : 220) ?
                            `${business.welcomeMessage.slice(0, isMobile ? 96 : 220)}...` :
                            business.welcomeMessage}
                        </Typography>
                      )}
                      {business.activeCouponDescriptions.length > 0 && (
                        <Typography variant="body2" component="span" className="text-hulahunt-secondary font-semibold block mt-1">
                          {t('featuredBusinesses.currentOffers')}: {business.activeCouponDescriptions.map((description, index) => (
                            <span key={index} className="font-normal fontinline-block rounded px-1 py-0.5 mr-2 mb-1">
                              {description}
                            </span>
                          ))}
                        </Typography>
                      )}
                      {business.distance !== undefined && (
                        <Typography
                          variant="body2"
                          component="span"
                          className="text-hulahunt-teal-600 block mt-1"
                        >
                          {`${business.distance.toFixed(1)} miles away`}
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <ShowBusinessModal
        business={selectedBusiness}
        onClose={handleCloseModal}
      />
    </PageLayout>
  );
};

export default FeaturedBusinesses;
