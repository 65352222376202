/**
 * Generates the full API path by combining the base API path and the relative path.
 *
 * @param relativePath - The relative path to the API endpoint.
 * @returns The full API path.
 */
export const getApiPath = (relativePath: string): string => {
  const baseApiPath = process.env.REACT_APP_ENVIRONMENT_NAME === 'local' ? 'http://localhost:3102/api/' : '/api/';
  // If relativePath begins with a '/' then strip it off
  const strippedRelativePath = relativePath.startsWith('/')
    ? relativePath.substring(1)
    : relativePath;

  return `${baseApiPath}${strippedRelativePath}`
}