const PrivacyContent = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Privacy Policy - HulaHunt LLC</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
    }

    h1,
    h2,
    h3 {
      color: #000;
    }

    h1 {
      font-size: 26px;
    }

    h2 {
      font-size: 19px;
    }

    h3 {
      font-size: 17px;
    }

    .subtitle {
      color: #595959;
      font-size: 14px;
    }

    a {
      color: #3030F1;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 20px;
    }

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }
  </style>
</head>

<body>
  <p class="subtitle">Last updated: October 12, 2024</p>

  <p>This privacy notice for HulaHunt LLC ("we," "us," or "our"), describes how and why we might collect, store, use,
    and/or share ("process") your information when you use our services ("Services"), such as when you:</p>
  <ul>
    <li>Visit our website at <a href="http://www.hulahunt.com" target="_blank">http://www.hulahunt.com</a>, or any
      website of ours that links to this privacy notice</li>
    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
  </ul>

  <h2>Questions or concerns?</h2>
  <p>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our
    policies and practices, please do not use our Services. If you still have any questions or concerns, please contact
    us at chris@hulahunt.com.</p>

  <h2>SUMMARY OF KEY POINTS</h2>
  <p><em>This summary provides key points from our privacy notice, but you can find out more details about any of these
      topics by clicking the link following each key point or by using our table of contents below to find the section
      you are looking for.</em></p>

  <h3>What personal information do we process?</h3>
  <p>When you visit, use, or navigate our Services, we may process personal information depending on how you interact
    with us and the Services, the choices you make, and the products and features you use. Learn more about <a
      href="#personalinfo">personal information you disclose to us</a>.</p>
  <strong>
    Do we process any sensitive personal information?
  </strong>
  We do not process sensitive personal information.
  <br />
  <strong>
    Do we collect any information from third parties?
  </strong>
  We do not collect any information from third parties.
  <br />
  <strong>
    How do we process your information?
  </strong>
  We process your information to provide, improve, and administer our Services, communicate with you, for security and
  fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
  We process your information only when we have a valid legal reason to do so. Learn more about
  <a data-custom-class="link" href="#infouse">
    how we process your information
  </a>
  .<br />
  <strong>
    In what situations and with which
    parties do we share personal information?
  </strong>
  We may share information in specific situations and with specific
  third parties. Learn more about
  <a data-custom-class="link" href="#whoshare">
    when and with whom we share your personal information
  </a>
  .<br />
  <strong>
    How do we keep your information safe?
  </strong>
  We have
  organizational
  and technical processes and procedures in place to protect your personal information. However, no electronic
  transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot
  promise or guarantee that hackers, cybercriminals, or other
  unauthorized
  third parties will not be able to defeat our security and improperly collect, access, steal, or modify your
  information. Learn more about
  <a data-custom-class="link" href="#infosafe">
    how we keep your information safe
  </a>
  .<br />
  <strong>
    What are your rights?
  </strong>
  Depending on where you are located geographically, the applicable privacy law may mean you have certain rights
  regarding your personal information. Learn more about
  <a data-custom-class="link" href="#privacyrights">
    your privacy rights
  </a>
  .<br />
  <strong>
    How do you exercise your rights?
  </strong>
  The easiest way to exercise your rights is by
  submitting a
  <a emailto="chris@hulahunt.com">
    data subject access request
  </a>
  , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
  Want to learn more about what we do with any information we collect?
  <br />
  <a data-custom-class="link" href="#toc">
    Review the privacy notice in full
  </a>
  <br />
  <br />
  <div id="toc">
    <strong>
      TABLE OF CONTENTS
    </strong>
  </div>
  <a data-custom-class="link" href="#infocollect">
    1. WHAT INFORMATION DO WE COLLECT?
  </a><br />
  <a data-custom-class="link" href="#infouse">
    2. HOW DO WE PROCESS YOUR INFORMATION?
  </a><br />
  <a data-custom-class="link" href="#whoshare">
    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
  </a><br />
  <a data-custom-class="link" href="#cookies">
    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
  </a><br />
  <a data-custom-class="link" href="#sociallogins">
    5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
  </a><br />
  <a data-custom-class="link" href="#inforetain">
    6. HOW LONG DO WE KEEP YOUR INFORMATION?
  </a><br />
  <a data-custom-class="link" href="#infosafe">
    7. HOW DO WE KEEP YOUR INFORMATION SAFE?
  </a><br />
  <a data-custom-class="link" href="#infominors">
    8. DO WE COLLECT INFORMATION FROM MINORS?
  </a><br />
  <a data-custom-class="link" href="#privacyrights">
    9. WHAT ARE YOUR PRIVACY RIGHTS?
  </a><br />
  <a data-custom-class="link" href="#DNT">
    10. CONTROLS FOR DO-NOT-TRACK FEATURES
  </a><br />
  <a data-custom-class="link" href="#uslaws">
    11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
  </a><br />
  <a data-custom-class="link" href="#policyupdates">
    12. DO WE MAKE UPDATES TO THIS NOTICE?
  </a><br />
  <a data-custom-class="link" href="#contact">
    13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
  </a><br />
  <a data-custom-class="link" href="#request">
    14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
  </a><br />
  <br /><br />
  <div id="infocollect">
    <span id="control">
      <strong>
        1. WHAT INFORMATION DO WE COLLECT?
      </strong>
    </span>
  </div>
  <div id="personalinfo">
    <strong>
      Personal information you disclose to us
    </strong>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <strong>
  </strong>
  <em>
    We collect personal information that you provide to us.
  </em>
  We collect personal information that you voluntarily provide to us when you
  register on the Services,
  express an interest in obtaining information about us or our products and Services, when you participate in activities
  on the Services, or otherwise when you contact us.
  <strong>
    Personal Information Provided by You.
  </strong>
  The personal information that we collect depends on the context of your interactions with us and the Services, the
  choices you make, and the products and features you use. The personal information we collect may include the
  following:
  <ul>
    <li data-custom-class="body_text">
      names
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      email addresses
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      passwords
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      contact or authentication data
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      phone numbers
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      usernames
    </li>
  </ul>
  <div id="sensitiveinfo">
    <strong>
      Sensitive Information.
    </strong>
    We do not process sensitive information.
  </div>
  <strong>
    Payment Data.
  </strong>
  We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument
  number, and the security code associated with your payment instrument. All payment data is handled and stored by
  Stripe
  . You may find their privacy notice link(s) here:
  <a data-custom-class="link" href="https://stripe.com/privacy" target="_blank">
    https://stripe.com/privacy
  </a>
  .
  <strong>
    Social Media Login Data.
  </strong>
  We may provide you with the option to register with us using your existing social media account details, like your
  Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile
  information about you from the social media provider, as described in the section called
  "
  <a data-custom-class="link" href="#sociallogins">
    HOW DO WE HANDLE YOUR SOCIAL LOGINS?
  </a>
  "
  below.
  All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any
  changes to such personal information.
  <strong>
    Information automatically collected
  </strong>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <strong>
  </strong>
  <em>
    Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is
    collected automatically when you visit our Services.
  </em>
  We automatically collect certain information when you visit, use, or navigate the Services. This information does not
  reveal your specific identity (like your name or contact information) but may include device and usage information,
  such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs,
  device name, country, location, information about how and when you use our Services, and other technical information.
  This information is primarily needed to maintain the security and operation of our Services, and for our internal
  analytics and reporting purposes.
  Like many businesses, we also collect information through cookies and similar technologies.
  The information we collect includes:
  <ul>
    <li data-custom-class="body_text">
      <em>
        Log and Usage Data.
      </em>
      Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically
      collect when you access or use our Services and which we record in log files. Depending on how you interact with
      us, this log data may include your IP address, device information, browser type, and settings and information
      about your activity in the Services
      (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you
      take such as which features you use), device event information (such as system activity, error reports (sometimes
      called
      "crash dumps"
      ), and hardware settings).
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <em>
        Device Data.
      </em>
      We collect device data such as information about your computer, phone, tablet, or other device you use to access
      the Services. Depending on the device used, this device data may include information such as your IP address (or
      proxy server), device and application identification numbers, location, browser type, hardware model, Internet
      service provider and/or mobile carrier, operating system, and system configuration information.
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <em>
        Location Data.
      </em>
      We collect location data such as information about your device's location, which can be either precise or
      imprecise. How much information we collect depends on the type and settings of the device you use to access the
      Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your
      current location (based on your IP address). You can opt out of allowing us to collect this information either by
      refusing access to the information or by disabling your Location setting on your device. However, if you choose to
      opt out, you may not be able to use certain aspects of the Services.
    </li>
  </ul>
  <strong>
    Google API
  </strong>
  Our use of information received from Google APIs will adhere to
  <a data-custom-class="link" href="https://developers.google.com/terms/api-services-user-data-policy"
    rel="noopener noreferrer" target="_blank">
    Google API Services User Data Policy
  </a>
  , including the
  <a data-custom-class="link" href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
    rel="noopener noreferrer" target="_blank">
    Limited Use requirements
  </a>
  .<br /><br />
  <div id="infouse">
    <span id="control">
      <strong>
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    We process your information to provide, improve, and administer our Services, communicate with you, for security and
    fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
  </em>
  <strong>
    We process your personal information for a variety of reasons, depending on how you interact with our Services,
    including:
  </strong>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        To facilitate account creation and authentication and otherwise manage user accounts.
      </strong>
      We may process your information so you can create and log in to your account, as well as keep your account in
      working order.
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        To deliver and facilitate delivery of services to the user.
      </strong>
      We may process your information to provide you with the requested service.
    </li>
  </ul>
  <p>
  <p>
  <p>
  <p>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        To protect our Services.
      </strong>
      We may process your information as part of our efforts to keep our Services safe and secure, including fraud
      monitoring and prevention.
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        To evaluate and improve our Services, products, marketing, and your experience.
      </strong>
      We may process your information when we believe it is necessary to identify usage trends, determine the
      effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and
      your experience.
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        To identify usage trends.
      </strong>
      We may process information about how you use our Services to better understand how they are being used so we can
      improve them.
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        To comply with our legal obligations.
      </strong>
      We may process your information to comply with our legal obligations, respond to legal requests, and exercise,
      establish, or defend our legal rights.
    </li>
  </ul><br /><br />
  <div id="whoshare">
    <span id="control">
      <strong>
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    We may share information in specific situations described in this section and/or with the following
    third parties.
  </em>
  We
  may need to share your personal information in the following situations:
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Business Transfers.
      </strong>
      We may share or transfer your information in connection with, or during negotiations of, any merger, sale of
      company assets, financing, or acquisition of all or a portion of our business to another company.
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        When we use Google Maps Platform APIs.
      </strong>
      We may share your information with certain Google Maps Platform APIs (e.g.
      ,
      Google Maps API, Places API).
      We obtain and store on your device (
      "cache"
      ) your location
      . You may revoke your consent anytime by contacting us at the contact details provided at the end of this
      document.
    </li>
  </ul><br /><br />
  <div id="cookies">
    <span id="control">
      <strong>
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    We may use cookies and other tracking technologies to collect and store your information.
  </em>
  We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you
  interact with our Services. Some online tracking technologies help us maintain the security of our Services
  and your account
  , prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
  We also permit third parties and service providers to use online tracking technologies on our Services for analytics
  and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or
  to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service
  providers use their technology to provide advertising about products and services tailored to your interests which may
  appear either on our Services or on other websites.
  To the extent these online tracking technologies are deemed to be a
  "sale"/"sharing"
  (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can
  opt out of these online tracking technologies by submitting a request as described below under section
  "
  <a data-custom-class="link" href="#uslaws">
    DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
  </a>
  "
  Specific information about how we use such technologies and how you can refuse certain cookies is set out in our
  Cookie Notice
  .
  <strong>
    Google Analytics
  </strong>
  We may share your information with Google Analytics to track and
  analyze
  the use of the Services.
  To opt out of being tracked by Google Analytics across the Services, visit
  <a data-custom-class="link" href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">
    https://tools.google.com/dlpage/gaoptout
  </a>
  .
  For more information on the privacy practices of Google, please visit the
  <a data-custom-class="link" href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">
    Google Privacy &amp; Terms page
  </a>
  .<br /><br />
  <div id="sociallogins">
    <span id="control">
      <strong>
        5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    If you choose to register or log in to our Services using a social media account, we may have access to certain
    information about you.
  </em>
  Our Services offer you the ability to register and log in using your third-party social media account details (like
  your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from
  your social media provider. The profile information we receive may vary depending on the social media provider
  concerned, but will often include your name, email address, friends list, and profile picture, as well as other
  information you choose to make public on such a social media platform.
  We will use the information we receive only for the purposes that are described in this privacy notice or that are
  otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for,
  other uses of your personal information by your third-party social media provider. We recommend that you review their
  privacy notice to understand how they collect, use, and share your personal information, and how you can set your
  privacy preferences on their sites and apps.
  <br /><br />
  <div id="inforetain">
    <span id="control">
      <strong>
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    We keep your information for as long as necessary to
    fulfill
    the purposes outlined in this privacy notice unless otherwise required by law.
  </em>
  We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy
  notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal
  requirements).
  No purpose in this notice will require us keeping your personal information for longer than
  the period of time in which users have an account with us
  .
  When we have no ongoing legitimate business need to process your personal information, we will either delete or
  anonymize
  such information, or, if this is not possible (for example, because your personal information has been stored in
  backup archives), then we will securely store your personal information and isolate it from any further processing
  until deletion is possible.
  <br /><br />
  <div id="infosafe">
    <span id="control">
      <strong>
        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    We aim to protect your personal information through a system of
    organizational
    and technical security measures.
  </em>
  We have implemented appropriate and reasonable technical and
  organizational
  security measures designed to protect the security of any personal information we process. However, despite our
  safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage
  technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
  other
  unauthorized
  third parties will not be able to defeat our security and improperly collect, access, steal, or modify your
  information. Although we will do our best to protect your personal information, transmission of personal information
  to and from our Services is at your own risk. You should only access the Services within a secure environment.
  <br /><br />
  <div id="infominors">
    <span id="control">
      <strong>
        8. DO WE COLLECT INFORMATION FROM MINORS?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    We do not knowingly collect data from or market to
    children under 18 years of age
    .
  </em>
  We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell
  such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or
  guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal
  information from users less than 18 years of age has been collected, we will deactivate the account and take
  reasonable measures to promptly delete such data from our records. If you become aware of any data we may have
  collected from children under age 18, please contact us at
  chris@hulahunt.com
  .<br /><br />
  <div id="privacyrights">
    <span id="control">
      <strong>
        9. WHAT ARE YOUR PRIVACY RIGHTS?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    You may review, change, or terminate your account at any time, depending on your country, province, or state of
    residence.
  </em>
  <div id="withdrawconsent">
    <strong>
      <u>
        Withdrawing your consent:
      </u>
    </strong>
    If we are relying on your consent to process your personal information,
    which may be express and/or implied consent depending on the applicable law,
    you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us
    by using the contact details provided in the section
    "<br /><br />
    <a data-custom-class="link" href="#contact">
      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </a>
    "
    below
    .
  </div>
  However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
  when applicable law allows,
  will it affect the processing of your personal information conducted in reliance on lawful processing grounds other
  than consent.
  <strong>
    Account Information
  </strong>
  If you would at any time like to review or change the information in your account or terminate your account, you can:
  <ul>
    <li data-custom-class="body_text">
      Log in to your account settings and update your user account.
    </li>
  </ul>
  Upon your request to terminate your account, we will deactivate or delete your account and information from our active
  databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with
  any investigations, enforce our legal terms and/or comply with applicable legal requirements.
  <strong>
    <u>
      Cookies and similar technologies:
    </u>
  </strong>
  Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to
  remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain
  features or services of our Services.
  If you have questions or comments about your privacy rights, you may email us at
  chris@hulahunt.com
  .<br /><br />
  <div id="DNT">
    <span id="control">
      <strong>
        10. CONTROLS FOR DO-NOT-TRACK FEATURES
      </strong>
    </span>
  </div>
  Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (
  "DNT"
  ) feature or setting you can activate to signal your privacy preference not to have data about your online browsing
  activities monitored and collected. At this stage, no uniform technology standard for
  recognizing
  and implementing DNT signals has been
  finalized
  . As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates
  your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future,
  we will inform you about that practice in a revised version of this privacy notice.
  California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not
  an industry or legal standard for
  recognizing
  or
  honoring
  DNT signals, we do not respond to them at this time.<br /><br />
  <div id="uslaws">
    <span id="control">
      <strong>
        11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </strong>
    </span>
  </div>
  <strong>
    <em>
      In Short:
    </em>
  </strong>
  <em>
    If you are a resident of
    California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey,
    Oregon, Tennessee, Texas, Utah, or Virginia
    , you may have the right to request access to and receive details about the personal information we maintain about
    you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may
    also have the right to withdraw your consent to our processing of your personal information. These rights may be
    limited in some circumstances by applicable law. More information is provided below.
  </em>
  <strong>
    Categories of Personal Information We Collect
  </strong>
  We have collected the following categories of personal information in the past twelve (12) months:
  <table>
    <tbody>
      <tr>
        <td>
          <strong>
            Category
          </strong>
        </td>
        <td>
          <strong>
            Examples
          </strong>
        </td>
        <td>
          <strong>
            Collected
          </strong>
        </td>
      </tr>
      <tr>
        <td>
          A. Identifiers
        </td>
        <td>
          Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal
          identifier, online identifier, Internet Protocol address, email address, and account name
        </td>
        <td>
          YES
        </td>
      </tr>
    </tbody>
  </table>
  <table>
    <tbody>
      <tr>
        <td>
          B. Personal information as defined in the California Customer Records statute
        </td>
        <td>
          Name, contact information, education, employment, employment history, and financial information
        </td>
        <td>
          NO
        </td>
      </tr>
    </tbody>
  </table>
  <table>
    <tbody>
      <tr>
        <td>
          C
          . Protected classification characteristics under state or federal law
        </td>
        <td>
          Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          D
          . Commercial information
        </td>
        <td>
          Transaction information, purchase history, financial details, and payment information
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          E
          . Biometric information
        </td>
        <td>
          Fingerprints and voiceprints
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          F
          . Internet or other similar network activity
        </td>
        <td>
          Browsing history, search history, online
          behavior
          , interest data, and interactions with our and other websites, applications, systems, and advertisements
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          G
          . Geolocation data
        </td>
        <td>
          Device location
        </td>
        <td>
          YES
        </td>
      </tr>
      <tr>
        <td>
          H
          . Audio, electronic, sensory, or similar information
        </td>
        <td>
          Images and audio, video or call recordings created in connection with our business activities
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          I
          . Professional or employment-related information
        </td>
        <td>
          Business contact details in order to provide you our Services at a business level or job title, work history,
          and professional qualifications if you apply for a job with us
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          J
          . Education Information
        </td>
        <td>
          Student records and directory information
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          K
          . Inferences drawn from collected personal information
        </td>
        <td>
          Inferences drawn from any of the collected personal information listed above to create a profile or summary
          about, for example, an individual’s preferences and characteristics
        </td>
        <td>
          NO
        </td>
      </tr>
      <tr>
        <td>
          L
          . Sensitive personal Information
        </td>
        <td>
          NO
        </td>
      </tr>
    </tbody>
  </table>
  We may also collect other personal information outside of these categories through instances where you interact with
  us in person, online, or by phone or mail in the context of:
  <ul>
    <li data-custom-class="body_text">
      Receiving help through our customer support channels;
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      Participation in customer surveys or contests; and
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      Facilitation in the delivery of our Services and to respond to your inquiries.
    </li>
  </ul>
  We will use and retain the collected personal information as needed to provide the Services or for:
  <ul>
    <li data-custom-class="body_text">
      Category A -
      As long as the user has an account with us
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      Category
      G
      -
      30 days
    </li>
  </ul>
  <strong>
    Sources of Personal Information
  </strong>
  Learn more about the sources of personal information we collect in
  "
  <a data-custom-class="link" href="#infocollect">
    WHAT INFORMATION DO WE COLLECT?
  </a>
  "
  <strong>
    How We Use and Share Personal Information
  </strong>
  Learn about how we use your personal information in the section,
  "
  <a data-custom-class="link" href="#infouse">
    HOW DO WE PROCESS YOUR INFORMATION?
  </a>
  "
  <strong>
    Will your information be shared with anyone else?
  </strong>
  We may disclose your personal information with our service providers pursuant to a written contract between us and
  each service provider. Learn more about how we disclose personal information to in the section,
  "
  <a data-custom-class="link" href="#whoshare">
    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
  </a>
  "
  We may use your personal information for our own business purposes, such as for undertaking internal research for
  technological development and demonstration. This is not considered to be
  "selling"
  of your personal information.
  We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose
  in the preceding twelve (12) months. We
  will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
  <strong>
    Your Rights
  </strong>
  You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain
  cases, we may decline your request as permitted by law. These rights include:
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to know
      </strong>
      whether or not we are processing your personal data
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to access
      </strong>
      your personal data
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to correct
      </strong>
      inaccuracies in your personal data
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to request
      </strong>
      the deletion of your personal data
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to obtain a copy
      </strong>
      of the personal data you previously shared with us
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to non-discrimination
      </strong>
      for exercising your rights
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      <strong>
        Right to opt out
      </strong>
      of the processing of your personal data if it is used for targeted advertising
      (or sharing as defined under California’s privacy law)
      , the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant
      effects (
      "profiling"
      )
    </li>
  </ul>
  Depending upon the state where you live, you may also have the following rights:
  <ul>
    <li data-custom-class="body_text">
      Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted
      by applicable law, including
      California's and Delaware's
      privacy law)
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by
      applicable law, including Oregon’s privacy law)
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including
      California’s privacy law)
    </li>
  </ul>
  <ul>
    <li data-custom-class="body_text">
      Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice
      or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)
    </li>
  </ul>
  <strong>
    How to Exercise Your Rights
  </strong>
  To exercise these rights, you can contact us
  by emailing us at
  chris@hulahunt.com
  ,
  or by referring to the contact details at the bottom of this document.
  Under certain US state data protection laws, you can designate an
  authorized
  agent to make a request on your behalf. We may deny a request from an
  authorized
  agent that does not submit proof that they have been validly
  authorized
  to act on your behalf in accordance with applicable laws.
  <strong>
    Request Verification
  </strong>
  Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we
  have the information in our system. We will only use personal information provided in your request to verify your
  identity or authority to make the request. However, if we cannot verify your identity from the information already
  maintained by us, we may request that you provide additional information for the purposes of verifying your identity
  and for security or fraud-prevention purposes.
  If you submit the request through an
  authorized
  agent, we may need to collect additional information to verify your identity before processing your request and the
  agent will need to provide a written and signed permission from you to submit such request on your behalf.
  <strong>
    Appeals
  </strong>
  Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our
  decision by emailing us at
  chris@hulahunt.com
  . We will inform you in writing of any action taken or not taken in response to the appeal, including a written
  explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state
  attorney general.
  <strong>
    California
    "Shine The Light"
    Law
  </strong>
  California Civil Code Section 1798.83, also known as the
  "Shine The Light"
  law, permits our users who are California residents to request and obtain from us, once a year and free of charge,
  information about categories of personal information (if any) we disclosed to third parties for direct marketing
  purposes and the names and addresses of all third parties with which we shared personal information in the immediately
  preceding calendar year. If you are a California resident and would like to make such a request, please submit your
  request in writing to us by using the contact details provided in the section
  "
  <a data-custom-class="link" href="#contact">
    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
  </a>
  "<br /><br />
  <div id="policyupdates">
    <span id="control">
      <strong>
        12. DO WE MAKE UPDATES TO THIS NOTICE?
      </strong>
    </span>
  </div>
  <em>
    <strong>
      In Short:
    </strong>
    Yes, we will update this notice as necessary to stay compliant with relevant laws.
  </em>
  We may update this privacy notice from time to time. The updated version will be indicated by an updated
  "Revised"
  date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either
  by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review
  this privacy notice frequently to be informed of how we are protecting your information.
  <br /><br />
  <div id="contact">
    <span id="control">
      <strong>
        13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </strong>
    </span>
  </div>
  If you have questions or comments about this notice, you may
  email us at
  chris@hulahunt.com or
  contact us by post at:
  HulaHunt LLC
  87-3207 Carissa Rd
  Captain Cook
  ,
  HI
  96704
  United States<br /><br />
  <div id="request">
    <span id="control">
      <strong>
        14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </strong>
    </span>
  </div>
  Based on the applicable laws of your country
  or state of residence in the US
  , you may
  have the right to request access to the personal information we collect from you, details about how we have processed
  it, correct inaccuracies, or delete your personal information. You may also have the right to
  withdraw your consent to our processing of your personal information. These rights may be limited in some
  circumstances by applicable law. To request to review, update, or delete your personal information, please
  fill out and submit a
  <a mailto="chris@hulahunt.com">
    data subject access request
  </a>
  .
  <style>
    ul {
      list-style-type: square;
    }

    ul>li>ul {
      list-style-type: circle;
    }

    ul>li>ul>li>ul {
      list-style-type: square;
    }

    ol li {
      font-family: Arial;
    }
  </style>
  </p>
  </p>
  </p>
  </p>
</body>

</html>
`;

export default PrivacyContent;