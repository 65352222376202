import { Box } from '@mui/material';
import React from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  hideTab?: boolean;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, hideTab = false, ...other } = props;

  if (hideTab) {
    return <Box sx={{ p: 1, pt: 3 }}>{children}</Box>;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}