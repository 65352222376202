import axios from 'axios';
import { User } from '../../types/User';
import { getApiPath } from '../getApiPath';

interface UpdateUserResponse {
  message: string;
  user: User;
}

export const updateUser = async (
  userId: number,
  userData: Partial<User>
): Promise<User> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await axios.patch<UpdateUserResponse>(
      getApiPath(`/user/updateUser/${userId}`),
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.user;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while updating the user');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};