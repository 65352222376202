// frontend/src/pages/Admin/Businesses/index.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getBusinesses } from '../../../../services/business/getBusinesses';
import { Business, SortColumn, SortOrder } from '../../../../types/Business';
import { BusinessFormModal } from './BusinessFormModal';
import { ConfirmationDialog, PaginationContainer } from '../../../../components/common';
import { deleteBusiness } from '../../../../services/business/deleteBusiness';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { ResponsiveTextField } from '../../../../components/common';

export const AdminUserPage: React.FC = () => {
  const isMobile = useIsMobile();
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(isMobile ? 7 : 10);
  const [totalBusinesses, setTotalBusinesses] = useState(0);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [businessSearchString, setBusinessSearchString] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<SortColumn>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
  const { t } = useTranslation();

  const [businessFormModal, setBusinessFormModal] = useState<{ open: boolean; business: Business | null }>({
    open: false,
    business: null,
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState<{open: boolean, business: Business | null}>({
    open: false,
    business: null,
  });

  useEffect(() => {
    getBusinesses(
      page,
      rowsPerPage,
      businessSearchString,
      sortColumn,
      sortOrder
    ).then((data) => {
      setBusinesses(data.businesses);
      setTotalBusinesses(data.totalBusinesses);
    });
  }, [page, rowsPerPage, refetchTrigger, businessSearchString, sortColumn, sortOrder]);

  const handleOpenBusinessForm = (business: Business | null = null) => {
    setBusinessFormModal({ open: true, business });
  };

  const handleCloseBusinessForm = () => {
    setBusinessFormModal({ open: false, business: null });
  };

  const handleBusinessSaved = () => {
    setRefetchTrigger(prev => prev + 1);
  };

  const handleDeleteConfirmation = (business: Business | null) => {
    setDeleteConfirmation({ open: true, business });
  };

  const handleDeleteBusiness = () => {
    if (deleteConfirmation.business?.id) {
      deleteBusiness(deleteConfirmation.business.id).then(() => {
        setRefetchTrigger((prev) => prev + 1);
        setDeleteConfirmation({ open: false, business: null });
      }).catch((error) => {
        console.error('Error deleting business:', error);
      });
    }
  };

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortColumn(column);
      setSortOrder('ASC');
    }
  };

  return (
    <Box sx={{ p: { xs: 0, sm: 1 } }}>
      <Box sx={{ mb: 3, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenBusinessForm()}
          sx={{
            width: { xs: '100%', sm: '300px' },
            alignSelf: 'flex-start'
          }}
        >
          Add Business
        </Button>

        <ResponsiveTextField
          label="Search"
          variant="outlined"
          value={businessSearchString}
          onChange={(e) => setBusinessSearchString(e.target.value)}
          sx={{
            flexGrow: 1,
            width: { xs: '100%', sm: 'calc(100% - 300px)' }
          }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort('name')}
                sx={{ cursor: 'pointer',fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.businesses.name')} {sortColumn === 'name' && (sortOrder === 'ASC' ? '↑' : '↓')}
              </TableCell>
              {/*<TableCell>{t('admin.businesses.isVerified')}</TableCell>*/}
              {!isMobile && <TableCell sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.businesses.businessType')}</TableCell>}
              <TableCell sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.businesses.email')}</TableCell>
              <TableCell align="right" sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.businesses.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {businesses.map((business) => (
              <TableRow key={business.id}>
                <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{business.name}</TableCell>
                {/*<TableCell>{business.isVerified ? 'Yes' : 'No'}</TableCell>*/}
                {!isMobile && <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{business.businessType || 'Other'}</TableCell>}
                <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>
                  {isMobile ?
                    business.contactEmail && business.contactEmail.length > 10 ?
                      `${business.contactEmail.slice(0,10)}...` :
                      business.contactEmail
                    : business.contactEmail}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: '0.75rem', px: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <IconButton onClick={() => handleOpenBusinessForm(business)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteConfirmation(business)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationContainer
        totalItems={totalBusinesses}
        itemType="Businesses"
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, newPage) => setPage(newPage)}
      />
      <BusinessFormModal
        open={businessFormModal.open}
        onClose={handleCloseBusinessForm}
        onBusinessSaved={handleBusinessSaved}
        business={businessFormModal.business}
      />
      <ConfirmationDialog
        open={deleteConfirmation.open}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${deleteConfirmation.business?.name}?`}
        onConfirm={handleDeleteBusiness}
        onCancel={() => setDeleteConfirmation({ open: false, business: null })}
      />
    </Box>
  );
};
