import React from 'react';
import { Grid, Box } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface PaymentButtonProps {
  handleBuyNow: () => void;
  isMobile: boolean;
  displayPrice: number | undefined;
  t: (key: string) => string;
}

const PaymentButton: React.FC<PaymentButtonProps> = ({
  handleBuyNow,
  isMobile,
  displayPrice,
  t
}) => {
  return (
    <Grid item xs={12}>
      <Box sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        gap: 2,
        mb: isMobile ? 1 : 2,
      }}>
        <button
          onClick={handleBuyNow}
          type="button"
          style={{
            backgroundColor: '#635bff',
            color: 'white',
            padding: isMobile ? '8px 16px' : '10px 20px',
            border: 'none',
            borderRadius: '4px',
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: '600',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            display: 'flex',
            alignItems: 'center',
            gap: isMobile ? '6px' : '8px',
            width: isMobile ? '100%' : 'auto',
            minWidth: isMobile ? undefined : '250px',
            maxWidth: isMobile ? '100%' : '400px',
            justifyContent: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onMouseOver={(e) => {
            const target = e.currentTarget;
            target.style.backgroundColor = '#0a2540';
            target.style.transform = 'translateY(-1px)';
            target.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
          }}
          onMouseOut={(e) => {
            const target = e.currentTarget;
            target.style.backgroundColor = '#635bff';
            target.style.transform = 'translateY(0)';
            target.style.boxShadow = '0 1px 3px rgba(0,0,0,0.1)';
          }}
        >
          <ShoppingCartIcon style={{
            fontSize: isMobile ? '18px' : '20px',
            flexShrink: 0
          }} />
          <span style={{
            flexShrink: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {!displayPrice || Number(displayPrice) === 0
              ? t('findHunts.getFreeHunt')
              : `${t('findHunts.buyHunt')} - $${displayPrice}`
            }
          </span>
          <img
            src="/Powered_by_Stripe-white.svg"
            alt="Secured by Stripe"
            style={{
              height: isMobile ? '16px' : '20px',
              marginLeft: isMobile ? '6px' : '8px',
              flexShrink: 0
            }}
          />
        </button>
      </Box>
    </Grid>
  );
};

export { PaymentButton };