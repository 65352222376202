import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Box, Card, CardContent, CardMedia, LinearProgress, Button, Alert } from '@mui/material';
import { AccessTime, Map as MapIcon, LocalOffer as CouponIcon } from '@mui/icons-material';
import { ActiveHunt } from '../../types/ActiveHunt';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../hooks/useIsMobile';
import HuntLocationMap from '../../components/common/HuntLocationMap';
import { ActiveHuntItem } from '../../types/ActiveHunt';
import CouponsDialog from '../../components/CouponList/CouponsDialog';

interface HuntInformationCardProps {
  huntInfo: ActiveHunt;
  onItemClick: (item: ActiveHuntItem) => void;
}

const HuntInformationCard: React.FC<HuntInformationCardProps> = ({ huntInfo, onItemClick }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [mapOpen, setMapOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({ timeString: '', percentage: 0 });
  const [couponsDialogOpen, setCouponsDialogOpen] = useState(false);

  const calculateTimeRemaining = useMemo(() => () => {
    if (!huntInfo || !huntInfo.huntEndDate) return { timeString: '', percentage: 0 };
    const now = new Date();
    const endTime = new Date(huntInfo.huntEndDate);
    const startTime = new Date(huntInfo.huntStartedAt || now);
    const totalDuration = endTime.getTime() - startTime.getTime();
    const remainingTime = endTime.getTime() - now.getTime();

    const percentage = Math.max(0, Math.min(100, (remainingTime / totalDuration) * 100));

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);

    return {
      timeString: parts.join(' ') || t('activeHunt.lessThanAMinute'),
      percentage,
    };
  }, [huntInfo, t]);

  useEffect(() => {
    const updateTime = () => {
      setTimeRemaining(calculateTimeRemaining());
    };

    updateTime(); // Initial calculation
    const intervalId = setInterval(updateTime, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [calculateTimeRemaining]);

  const progressBarColors = useMemo(() => {
    const { percentage } = timeRemaining;
    const foregroundColor = percentage > 50
      ? `rgb(${200 - (percentage - 50) * 4}, ${200 - (percentage - 50) * 0.8}, 0)`
      : `rgb(200, ${percentage * 4}, 0)`;

    // Set background color to red if less than 15% remaining
    const redComponent = percentage < 15 ? 211 + (44 * (100 - percentage) / 100) : 211;
    const greenBlueComponent = percentage < 15 ? 211 - (44 * (100 - percentage) / 100) : 211;
    const backgroundColor = `rgb(${redComponent}, ${greenBlueComponent}, ${greenBlueComponent})`;

    return { foregroundColor, backgroundColor };
  }, [timeRemaining]);

  const completedItems = useMemo(() => {
    return huntInfo.huntItems.filter(item => item.status === 'complete').length;
  }, [huntInfo.huntItems]);

  const additionalPins = useMemo(() => {
    return huntInfo.huntItems.map(item => ({
      latitude: Number(item.latitude || item.location?.latitude),
      longitude: Number(item.longitude || item.location?.longitude),
      title: item.title,
      objectId: item.id,
      description: item.description,
      status: item.status
    })).filter(pin => pin.latitude && pin.longitude);
  }, [huntInfo.huntItems]);

  const handlePinClick = (objectId: string | number) => {
    console.log(`Pin clicked: ${objectId}`);
    // You can add additional logic here if needed
  };

  const handleGoToItem = (objectId: string | number) => {
    setMapOpen(false);
    const item = huntInfo.huntItems.find(item => item.id === objectId);
    if (item) {
      onItemClick(item);
    }
  };

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardMedia
          component="img"
          height="200"
          image={huntInfo.imageUrl}
          alt={huntInfo.name}
          sx={{
            objectFit: 'cover',
            maxHeight: isMobile ? '150px' : '250px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: 'transparent'
          }}
        />
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">{huntInfo.name}</Typography>
          <Typography variant="body2" color="text.secondary" paragraph>{huntInfo.description}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccessTime sx={{ mr: 1, color: 'primary.main' }} />
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
              <LinearProgress
                variant="determinate"
                value={timeRemaining.percentage}
                sx={{
                  height: 24,
                  borderRadius: 12,
                  backgroundColor: progressBarColors.backgroundColor,
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 12,
                    backgroundColor: progressBarColors.foregroundColor,
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  fontWeight: 'bold',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '90%',
                  textAlign: 'center',
                }}
              >
                {t('activeHunt.timeRemaining', { time: timeRemaining.timeString })}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" align="center" sx={{ mb: 2 }}>
            {t('activeHunt.itemsCompleted', { completed: completedItems, total: huntInfo.huntItems.length })}
          </Typography>
          {timeRemaining.percentage < 10 && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t('activeHunt.nearlyExpired')}
            </Alert>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Button
              startIcon={<MapIcon />}
              onClick={() => setMapOpen(true)}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ flex: 1 }}
            >
              {t('activeHunt.viewLocation')}
            </Button>
            <Button
              startIcon={<CouponIcon />}
              onClick={() => setCouponsDialogOpen(true)}
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{ flex: 1 }}
            >
              {t('activeHunt.viewCoupons')}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <HuntLocationMap
        open={mapOpen}
        subtitle={huntInfo.name}
        onClose={() => setMapOpen(false)}
        latitude={Number(huntInfo.latitude)}
        longitude={Number(huntInfo.longitude)}
        showUserLocation={true}
        drawLineFromUserLocation={true}
        showDistanceToUser={true}
        additionalPins={additionalPins}
        onPinClick={handlePinClick}
        onGoToItem={handleGoToItem}
      />
      <CouponsDialog
        open={couponsDialogOpen}
        title={t('activeHunt.huntCoupons')}
        onClose={() => setCouponsDialogOpen(false)}
        filterType={'hunt'}
        filterId={huntInfo.id}
      />
    </>
  );
};

export default HuntInformationCard;