import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Box, Card, CardContent, Button, List, ListItem, Tooltip, ClickAwayListener } from '@mui/material';
import { Check as CheckIcon, PlayArrow as PlayArrowIcon, QrCode2 as QrCode2Icon, Quiz as QuizIcon, LocationOn as LocationOnIcon, RadioButtonUnchecked as RadioButtonUncheckedIcon, Info as InfoIcon } from '@mui/icons-material';
import { ActiveHuntItem } from '../../types/ActiveHunt';
import { useTranslation } from 'react-i18next';
import { SvgIconProps } from '@mui/material';
import { ItemType } from '../../types/HuntItem';
import { calculateDistance } from '../../utils/calculateDistance';
import { MdiPalmTree } from '../../components/icons/PalmTree';
interface HuntItemListProps {
  huntItems: ActiveHuntItem[];
  onItemClick: (item: ActiveHuntItem) => void;
}

const HuntItemList: React.FC<HuntItemListProps> = ({ huntItems, onItemClick }) => {
  const { t } = useTranslation();
  const [openTooltips, setOpenTooltips] = useState<{ [key: number]: boolean }>({});
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [itemsWithDistance, setItemsWithDistance] = useState<ActiveHuntItem[]>([]);

  useEffect(() => {
    // Get user's current location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, []);

  // Sort hunt items by distance from the user's current location
  // Put completed items at the bottom
  // We want it to be easy for the user to find the next item to complete
  useEffect(() => {
    if (userLocation) {
      const itemsWithDistanceCalculated = huntItems.map(item => {
        if (item.latitude && item.longitude) {
          const distance = calculateDistance(
            userLocation.lat,
            userLocation.lng,
            Number(item.latitude),
            Number(item.longitude)
          );
          return { ...item, distance };
        }
        return { ...item, distance: Infinity };
      });

      // Sort the items
      const sortedItems = itemsWithDistanceCalculated.sort((a, b) => {
        // First, sort completed items to the bottom
        if (a.status === 'complete' && b.status !== 'complete') return 1;
        if (a.status !== 'complete' && b.status === 'complete') return -1;

        // Then sort by distance
        return (a.distance ?? Infinity) - (b.distance ?? Infinity);
      });

      setItemsWithDistance(sortedItems);
    } else {
      setItemsWithDistance(huntItems);
    }
  }, [huntItems, userLocation]);

  const toggleTooltip = useCallback((itemId: number) => {
    setOpenTooltips(prev => ({ ...prev, [itemId]: !prev[itemId] }));
  }, []);

  const handleTooltipClose = useCallback((itemId: number) => {
    setOpenTooltips(prev => ({ ...prev, [itemId]: false }));
  }, []);

  const getItemTypeIcon = useCallback((itemType: ItemType, itemId: number) => {
    const iconProps: SvgIconProps = { fontSize: 'medium' as const };
    let icon;
    let tooltipText;

    switch (itemType) {
      case ItemType.QRCode:
        icon = <QrCode2Icon {...iconProps} />;
        tooltipText = t('activeHunt.itemTypeTitles.QRCode');
        break;
      case ItemType.Question:
        icon = <QuizIcon {...iconProps} />;
        tooltipText = t('activeHunt.itemTypeTitles.Question');
        break;
      case ItemType.GPSLocation:
        icon = <LocationOnIcon {...iconProps} />;
        tooltipText = t('activeHunt.itemTypeTitles.GPSLocation');
        break;
      default:
        icon = <LocationOnIcon {...iconProps} />;
        tooltipText = t('activeHunt.itemTypeTitles.GPSLocation');
    }

    return (
      <ClickAwayListener onClickAway={() => handleTooltipClose(itemId)}>
        <Tooltip
          title={tooltipText}
          open={openTooltips[itemId] || false}
          onClose={() => handleTooltipClose(itemId)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Box
            component="span"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up
              toggleTooltip(itemId);
            }}
          >
            {icon}
          </Box>
        </Tooltip>
      </ClickAwayListener>
    );
  }, [openTooltips, handleTooltipClose, toggleTooltip, t]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'complete':
        return 'success.main';
      case 'in_progress':
        return 'primary.main';
      default:
        return 'text.disabled';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'complete':
        return <CheckIcon fontSize="small" color="success" />;
      case 'in_progress':
        return <PlayArrowIcon fontSize="small" color="primary" />;
      default:
        return <RadioButtonUncheckedIcon fontSize="small" color="disabled" />;
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'complete':
        return t('activeHunt.status.completed');
      case 'in_progress':
        return t('activeHunt.status.in_progress');
      default:
        return t('activeHunt.status.not_started');
    }
  };

  const handleItemClick = (item: ActiveHuntItem) => {
    onItemClick(item);
  };

  return (
    <Card sx={{ mt: 2, boxShadow: 2 }}>
      <CardContent className='cardcontent' sx={{ p: 0, pb: '0 !important' }}>
        <Box sx={{
          color: 'white',
          py: 1.5,
          background: 'linear-gradient(to right, #3da370, #2e8b57)',
          borderBottom: 1,
          borderColor: 'grey.300',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}>
            <Box sx={{ fontSize: 40 }}>
              <MdiPalmTree />
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {t('activeHunt.huntItems')}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1
          }}>
            <Typography variant="caption" sx={{ fontStyle: 'italic', mt: 0.5 }}>
              {t('activeHunt.openItemToStart')}
            </Typography>
            <ClickAwayListener onClickAway={() => handleTooltipClose(998877)}>
              <Tooltip
                title={t('activeHunt.itemSortingInfo')}
                open={openTooltips[998877] || false}
                onClose={() => handleTooltipClose(998877)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <InfoIcon
                  sx={{
                    fontSize: 16,
                    color: 'white',
                    cursor: 'pointer',
                    opacity: 0.8
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTooltip(998877);
                  }}
                />
              </Tooltip>
            </ClickAwayListener>
          </Box>
        </Box>
        <List sx={{ '& > li': { borderBottom: 1, borderColor: 'divider' }, '& > li:last-child': { borderBottom: 0 } }}>
          {itemsWithDistance.map((item: ActiveHuntItem) => (
            <ListItem key={item.id} sx={{ display: 'block', p: 0 }}>
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, flexGrow: 1, minWidth: 0, pr: 2 }}>
                    <Box
                      sx={{ p: 1, color: `${getStatusColor(item.status)}`, opacity: 1, flexShrink: 0 }}
                      data-testid="item-type-icon"
                    >
                      {getItemTypeIcon(item.itemType as ItemType, item.id)}
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {item.title}
                      </Typography>
                      {item.distance !== undefined && (
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          {`${t('activeHunt.away', { distance: item.distance })}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: getStatusColor(item.status), whiteSpace: 'nowrap' }}>
                    {getStatusIcon(item.status)}
                    <Typography variant="caption">
                      {getStatusText(item.status)}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                  {item.description}
                </Typography>
                {item.status !== 'complete' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleItemClick(item)}
                    sx={{ mt: 1.5, width: '100%' }}
                  >
                    {item.status === 'not_started'
                      ? t('activeHunt.startItem')
                      : t('activeHunt.openItem')}
                  </Button>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default HuntItemList;