import axios from 'axios';
import { Hunt } from '../../types/Hunt';
import { getApiPath } from '../getApiPath';

interface GetHuntsResponse {
  hunts: Hunt[];
  currentPage: number;
  totalPages: number;
  totalHunts: number;
}

export type SortColumn = 'name' | 'location_area_name' | 'distance';
export type SortOrder = 'ASC' | 'DESC';
export type FilterColumn = 'distance' | 'fake_hunt_item_count' | 'estimated_time_hours';

export const getHunts = async (
  page: number = 1,
  limit: number = 10,
  huntSearchString?: string,
  sortColumn?: SortColumn,
  sortOrder: SortOrder = 'ASC',
  filterColumn?: FilterColumn,
  filterUpperBound?: number,
  userLatitude?: number,
  userLongitude?: number
): Promise<GetHuntsResponse> => {
  try {

    const response = await axios.get<GetHuntsResponse>(getApiPath('/hunt/getHunts'), {
      params: {
        page,
        limit,
        huntSearchString,
        sortColumn,
        sortOrder,
        filterColumn,
        filterUpperBound,
        userLatitude,
        userLongitude
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching hunts');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};