import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../hooks/useIsMobile';

interface WhatMakesUsDifferentModalProps {
  open: boolean;
  onClose: () => void;
}

const WhatMakesUsDifferentModal: React.FC<WhatMakesUsDifferentModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  // Split the description into paragraphs
  const paragraphs = t('whatMakesUsDifferent.description').split('\n\n');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: isMobile ? 12 : 4,
          margin: isMobile ? 12 : 24,
        },
      }}
    >
      <DialogTitle className="pr-12">
        {t('whatMakesUsDifferent.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {paragraphs.map((paragraph, index) => (
          <Typography
            key={index}
            variant="body1"
            className="text-hulahunt-teal-800 mb-4 last:mb-0"
            style={{ marginBottom: '1rem' }}
          >
            {paragraph}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatMakesUsDifferentModal;