import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Slider,
  Pagination,
  SelectChangeEvent,
  Alert,
  TextField,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getHunts, SortColumn, SortOrder, FilterColumn } from '../../services/hunt/getHunts';
import { getUserHunts } from '../../services/userhunt/getUserHunts';
import { Hunt } from '../../types/Hunt';
import { UserHunt, UserHuntStatus } from '../../types/UserHunt';
import HuntCard from './HuntCard';
import MobileFilterDialog from './MobileFilterDialog';
import { PageLayout } from '../../components/common';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useAuth } from '../../context/AuthContext';

type HuntWithStatus = Hunt & { userHuntStatus?: UserHuntStatus };

export const FindHunts: React.FC = () => {
  const isMobile = useIsMobile();
  const [hunts, setHunts] = useState<HuntWithStatus[]>([]);
  const [userHuntsData, setUserHuntsData] = useState<UserHunt[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [sortColumn, setSortColumn] = useState<SortColumn>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
  const [filterColumn, setFilterColumn] = useState<FilterColumn>('fake_hunt_item_count');
  const [filterUpperBound, setFilterUpperBound] = useState<number>(15);
  const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const userHunts = useMemo(() => userHuntsData, [userHuntsData]);

  const fetchUserHunts = useCallback(async () => {
    try {
      if (isAuthenticated) {
      const response = await getUserHunts(1, 100);
      setUserHuntsData(response.userHunts);
      }
    } catch (error) {
      console.error('Error fetching user hunts:', error);
    }
  }, [isAuthenticated]);

  const fetchHunts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getHunts(
        page,
        isMobile ? 5 : 10,
        searchString,
        sortColumn,
        sortOrder,
        filterColumn,
        filterUpperBound,
        userLocation?.latitude,
        userLocation?.longitude
      );
      const updatedHunts: HuntWithStatus[] = response.hunts.map(hunt => {
        const userHunt = userHunts.find(uh => uh.huntId === hunt.id);
        return {
          ...hunt,
          userHuntStatus: userHunt?.status,
        };
      });
      setHunts(updatedHunts);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching hunts:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, searchString, sortColumn, sortOrder, filterColumn, filterUpperBound, userLocation, userHunts, isMobile]);

  const requestUserLocation = useCallback(() => {
    return new Promise<void>((resolve) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
            setLocationError(null);
            resolve();
          },
          (error) => {
            console.error("Error getting user location:", error);
            setLocationError(t('findHunts.locationError'));
            resolve();
          }
        );
      } else {
        setLocationError(t('findHunts.geolocationNotSupported'));
        resolve();
      }
    });
  }, [t]);

  useEffect(() => {
    const initializeData = async () => {
      await requestUserLocation();
      await fetchUserHunts();
    };
    initializeData();
  }, [requestUserLocation, fetchUserHunts]);

  useEffect(() => {
    fetchHunts();
  }, [fetchHunts]);

  const getFilterLabel = (column: FilterColumn) => {
    switch (column) {
      case 'distance':
        return t('findHunts.filterMaxTotalDistance');
      case 'fake_hunt_item_count':
        return t('findHunts.filterMaxItemCount');
      case 'estimated_time_hours':
        return t('findHunts.filterMaxTimeHours');
      default:
        return t('findHunts.filterUpperBound');
    }
  };

  const getFilterUnit = (column: FilterColumn) => {
    switch (column) {
      case 'distance':
        return t('findHunts.miles');
      case 'fake_hunt_item_count':
        return t('findHunts.items');
      case 'estimated_time_hours':
        return t('findHunts.hours');
      default:
        return '';
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    setPage(1);
  };

  const handleSortChange = (event: SelectChangeEvent<SortColumn>) => {
    const newSortColumn = event.target.value as SortColumn;
    if (newSortColumn === 'distance' && !userLocation) {
      setLocationError(t('findHunts.locationRequiredForDistance'));
      return;
    }
    setSortColumn(newSortColumn);
    setLocationError(null);
    setPage(1);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    setPage(1);
  };

  const handleFilterColumnChange = (event: SelectChangeEvent<FilterColumn>) => {
    const newFilterColumn = event.target.value as FilterColumn;
    if (newFilterColumn === 'distance' && !userLocation) {
      setLocationError(t('findHunts.locationRequiredForDistance'));
      return;
    }
    setFilterUpperBound(newFilterColumn === 'distance' ? 200 : 15);
    setFilterColumn(newFilterColumn);
    setLocationError(null);
    setPage(1);
  };

  const handleFilterUpperBoundChange = (event: Event, newValue: number | number[]) => {
    setFilterUpperBound(newValue as number);
    setPage(1);
  };

  const sortedHunts = useMemo(() => {
    return [...hunts].sort((a, b) => {
      // First, sort InProgress hunts to the top
      if (a.userHuntStatus === UserHuntStatus.InProgress && b.userHuntStatus !== UserHuntStatus.InProgress) {
        return -1;
      }
      if (b.userHuntStatus === UserHuntStatus.InProgress && a.userHuntStatus !== UserHuntStatus.InProgress) {
        return 1;
      }

      // If both hunts have the same status (both InProgress or both not InProgress),
      // then sort by distanceToUserMiles if it's available
      if (a.distanceToUserMiles !== undefined && b.distanceToUserMiles !== undefined) {
        return a.distanceToUserMiles - b.distanceToUserMiles;
      }

      // If distanceToUserMiles is not available for both, maintain their current order
      return 0;
    });
  }, [hunts]);

  return (
    <PageLayout title={t('findHunts.title')}>
    <Box
      sx={{
        pb: { xs: 2, sm: 3 },
        pt: { xs: 0, sm: 1 },
        maxWidth: '1200px',
        margin: '0 auto',
        width: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
        height: 'auto',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >

      {locationError && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {locationError}
        </Alert>
      )}

        <Box
          sx={{
            mb: isMobile ? 1 : 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}
        >
        <TextField
          fullWidth
          label={t('findHunts.search')}
          variant="outlined"
          value={searchString}
          margin="none"
          onChange={handleSearchChange}
          size={'small'}
        />
        {isMobile && (
          <Button
            variant="outlined"
            size="large"
            startIcon={<FilterListIcon />}
            sx={{ "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }}
            onClick={() => setIsFilterDialogOpen(true)}
          >

          </Button>
        )}
      </Box>

      {!isMobile && (
      <Box sx={{ mb: 3, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2 }}>
          <FormControl size={'small'} sx={{ flexGrow: 1 }}>
            <InputLabel>{t('findHunts.sortBy')}</InputLabel>
            <Select<SortColumn>
              value={sortColumn}
              label={t('findHunts.sortBy')}
              onChange={handleSortChange}
            >
              <MenuItem value="name">{t('findHunts.name')}</MenuItem>
              <MenuItem value="location_area_name">{t('findHunts.location')}</MenuItem>
              <MenuItem value="distance">{t('findHunts.distance')}</MenuItem>
            </Select>
          </FormControl>

          <Button onClick={handleSortOrderChange} variant="outlined" sx={{ minWidth: '120px' }}>
            {sortOrder === 'ASC' ? t('findHunts.ascending') : t('findHunts.descending')}
          </Button>
        </Box>

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>{t('findHunts.filterBy')}</InputLabel>
            <Select<FilterColumn>
              value={filterColumn}
              size={'small'}
              label={t('findHunts.filterBy')}
              onChange={handleFilterColumnChange}
            >
              <MenuItem value="distance">{t('findHunts.distance')}</MenuItem>
              <MenuItem value="fake_hunt_item_count">{t('findHunts.itemCount')}</MenuItem>
              <MenuItem value="estimated_time_hours">{t('findHunts.estimatedTime')}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ px: 2 }}>
          <Typography gutterBottom>
            {getFilterLabel(filterColumn)}: {filterUpperBound} {getFilterUnit(filterColumn)}
          </Typography>

          <Slider
            value={filterUpperBound}
            onChange={handleFilterUpperBoundChange}
            min={0}
            max={filterColumn === 'distance' ? 200: 15}
            step={1}
          />
        </Box>
      </Box>
      )}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{
          flexGrow: 1,
          width: '100%',
          margin: 0,
          '& .MuiGrid-item': {
            paddingLeft: { xs: '0 !important', sm: '8px !important' },
            paddingRight: { xs: '0 !important', sm: '8px !important' }
          }
        }}
      >
        {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            sortedHunts.map((hunt) => (
              <Grid item key={hunt.id} xs={12} sm={isMobile ? 12 : 6} pl={0}>
                <HuntCard hunt={hunt} />
              </Grid>
            ))
          )}
        </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>

      {isMobile && (
        <MobileFilterDialog
          isOpen={isFilterDialogOpen}
          onClose={() => setIsFilterDialogOpen(false)}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          filterColumn={filterColumn}
          filterUpperBound={filterUpperBound}
          onSortChange={handleSortChange}
          onSortOrderChange={handleSortOrderChange}
          onFilterColumnChange={handleFilterColumnChange}
          onFilterUpperBoundChange={handleFilterUpperBoundChange}
        />
      )}
    </Box>
    </PageLayout>
  );
};


export default FindHunts;