import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Paper,
  Box,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { ResponsiveTextField } from './ResponsiveTextField';
import { useSnackbar } from 'notistack';
import { useGoogleMapsScript } from '../../hooks/useGoogleMapsScript';
interface MapCoordinatesPickerProps {
  latitude: number | undefined;
  longitude: number | undefined;
  onCoordinatesChange: (lat: number, lng: number) => void;
  title?: string;
  titleTooltip?: string;
  getAddressString?: () => string;
}

export const MapCoordinatesPicker: React.FC<MapCoordinatesPickerProps> = ({
  latitude,
  longitude,
  onCoordinatesChange,
  title,
  titleTooltip,
  getAddressString,
}) => {
  const { t } = useTranslation();
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: latitude || 0, lng: longitude || 0 });
  const [mapMarker, setMapMarker] = useState<{ lat: number; lng: number } | null>(null);
  const [isMapInteractive, setIsMapInteractive] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);

  const { isLoaded, loadError } = useGoogleMapsScript();

  useEffect(() => {
    if (latitude && longitude) {
      setMapCenter({ lat: latitude, lng: longitude });
      setMapMarker({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setMapCenter({ lat, lng });
      setMapMarker({ lat, lng });
      onCoordinatesChange(lat, lng);
    }
  };

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const toggleMapInteraction = () => {
    setIsMapInteractive((prev) => !prev);
    if (mapRef.current) {
      mapRef.current.setOptions({
        scrollwheel: !isMapInteractive,
        gestureHandling: isMapInteractive ? 'cooperative' : 'greedy'
      });
    }
  };

  const handleLatLngChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numValue = Number(value);
    if (name === 'latitude') {
      setMapCenter((prev) => ({ ...prev, lat: numValue }));
      setMapMarker((prev) => prev ? { ...prev, lat: numValue } : { lat: numValue, lng: mapCenter.lng });
      onCoordinatesChange(numValue, mapCenter.lng);
    } else if (name === 'longitude') {
      setMapCenter((prev) => ({ ...prev, lng: numValue }));
      setMapMarker((prev) => prev ? { ...prev, lng: numValue } : { lat: mapCenter.lat, lng: numValue });
      onCoordinatesChange(mapCenter.lat, numValue);
    }
  };

  const getGPSFromAddress = () => {
    if (getAddressString) {
      const geocoder = new google.maps.Geocoder();
      const address = getAddressString();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          const newLat = lat();
          const newLng = lng();
          setMapCenter({ lat: newLat, lng: newLng });
          setMapMarker({ lat: newLat, lng: newLng });
          onCoordinatesChange(newLat, newLng);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setMapMarker({ lat: latitude, lng: longitude });
          onCoordinatesChange(latitude, longitude);
        },
        (error) => {
          console.error('Error getting current location:', error);
          enqueueSnackbar(t('common.errorGettingLocation'), { variant: 'error' });
        }
      );
    } else {
      enqueueSnackbar(t('common.geolocationNotSupported'), { variant: 'error' });
    }
  };

  if (loadError) return <Box>Error loading maps</Box>;
  if (!isLoaded) return <Box>Loading maps...</Box>;

  return (
    <Paper elevation={3} sx={{ padding: 2, marginY: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Typography variant="h6" align="center">
          {title || t('common.gpsCoordinates')}
        </Typography>
        {titleTooltip && (
          <Tooltip title={titleTooltip} placement="top">
            <InfoIcon color="action" fontSize="small" sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Box>
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              marginBottom: isMapInteractive ? 2 : 0
            }}>
              <Button
                variant="contained"
                color={isMapInteractive ? "secondary" : "primary"}
                onClick={toggleMapInteraction}
                fullWidth
              >
                {isMapInteractive ? t('common.disableMapInteraction') : t('common.enableMapInteraction')}
              </Button>
              <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2
              }}>
                {isMapInteractive && (
                  <>
                    {getAddressString && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={getGPSFromAddress}
                        fullWidth
                        sx={{ flex: { md: 1 } }}
                      >
                        {t('common.getGPSFromAddress')}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getCurrentLocation}
                      fullWidth
                      sx={{ flex: { md: 1 } }}
                    >
                      {t('common.getCurrentLocation')}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        <Grid item xs={6} md={6}>
          <ResponsiveTextField
            name="latitude"
            label={t('common.latitude')}
            type="number"
            value={latitude || ''}
            onChange={handleLatLngChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <ResponsiveTextField
            name="longitude"
            label={t('common.longitude')}
            type="number"
            value={longitude || ''}
            onChange={handleLatLngChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 400, width: '100%' }}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={mapCenter}
              zoom={10}
              onClick={handleMapClick}
              onLoad={onMapLoad}
              options={{
                scrollwheel: isMapInteractive,
                gestureHandling: isMapInteractive ? 'greedy' : 'cooperative',
              }}
            >
              {mapMarker && (
                <MarkerF
                  position={{
                    lat: mapMarker.lat,
                    lng: mapMarker.lng
                  }}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    scaledSize: new window.google.maps.Size(40, 40)
                  }}
                />
              )}
            </GoogleMap>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};