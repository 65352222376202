import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface HuntTagProps extends Omit<ChipProps, 'variant'> {
  name: string;
  onDelete?: () => void;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '50px',
  backgroundColor: 'rgb(170, 221, 255)',
  '& .MuiChip-label': {
    lineHeight: 'normal',
    paddingTop: -1,
    marginTop: -1,
    paddingBottom: 0,
  },
  '& .MuiChip-deleteIcon': {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}));

export const HuntTag: React.FC<HuntTagProps> = ({ name, onDelete, ...props }) => {
  return (
    <StyledChip
      variant="outlined"
      size="small"
      label={name}
      onDelete={onDelete}
      {...props}
    />
  );
};