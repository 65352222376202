import axios from 'axios';
import { getApiPath } from '../getApiPath';

export const deleteHunt = async (huntId: number): Promise<void> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  try {
    await axios.delete(getApiPath(`/hunt/deleteHunt/${huntId}`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        throw new Error('Hunt not found');
      } else if (error.response?.status === 400) {
        throw new Error('Invalid hunt ID');
      }
    }
    throw new Error('Failed to delete hunt');
  }
};