import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HuntItem, ItemType } from '../../../types/HuntItem';
import { v4 as uuidv4 } from 'uuid';
import { QRCodeSVG } from 'qrcode.react';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { MapCoordinatesPicker } from '../../../components/common/MapCoordinatesPicker';
import InfoIcon from '@mui/icons-material/Info';
import { Location } from '../../../types/Location';

interface HuntItemModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (huntItem: Partial<HuntItem>) => void;
  huntItem?: HuntItem;
  location?: Location | undefined;
}

export const HuntItemModal: React.FC<HuntItemModalProps> = ({
  open,
  onClose,
  onSave,
  huntItem,
  location
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Partial<HuntItem>>(getInitialFormData());

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });

  const setLocationCoordinates = useCallback(() => {
    if (location) {
      setMapCenter({ lat: Number(location.latitude), lng: Number(location.longitude) });
      setFormData((prev) => ({ ...prev, latitude: location.latitude, longitude: location.longitude }));
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setFormData((prev) => ({ ...prev, latitude, longitude }));
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    }
  }, [location]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.title) {
      newErrors.title = t('admin.huntItems.errors.titleRequired');
    }

    if (!formData.itemType) {
      newErrors.itemType = t('admin.huntItems.errors.itemTypeRequired');
    }

    if (formData.itemType === ItemType.Question) {
      if (!formData.question) {
        newErrors.question = t('admin.huntItems.errors.questionRequired');
      }
      if (!formData.answer) {
        newErrors.answer = t('admin.huntItems.errors.answerRequired');
      }
    }

    if (formData.itemType === ItemType.GPSLocation) {
      if (!formData.latitude) {
        newErrors.latitude = t('admin.huntItems.errors.latitudeRequired');
      }
      if (!formData.longitude) {
        newErrors.longitude = t('admin.huntItems.errors.longitudeRequired');
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => {
      let updatedValue = value;
      if (name === 'title') {
        updatedValue = value.slice(0, 22);
      }
      const updatedFormData = { ...prev, [name]: updatedValue };
      validateForm();
      return updatedFormData;
    });
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(formData);
      onClose();
    }
  };

  const isFormValid = Object.keys(errors).length === 0;

  useEffect(() => {
    if (open) {
      if (huntItem) {
        setFormData(huntItem);
        if (huntItem.latitude && huntItem.longitude) {
          setMapCenter({ lat: Number(huntItem.latitude), lng: Number(huntItem.longitude) });
        }
      } else {
        setFormData(getInitialFormData());
        setLocationCoordinates();
      }
    }
  }, [open, huntItem, setLocationCoordinates]);

  const handleDownloadQRCode = () => {
    const svg = document.getElementById('qr-code-container');
    if (svg) {
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svg);
      const canvas = document.createElement('canvas');
      canvas.width = 250;
      canvas.height = 270;

      const img = new Image();
      const svgBlob = new Blob([
        '<?xml version="1.0" standalone="no"?>\r\n',
        svgStr
      ], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svgBlob);

      img.onload = () => {
        const context = canvas.getContext('2d');
        if (context) {
          context.fillStyle = 'white';
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            if (blob) {
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = `${formData.title || 'hulahunt-qr-code'}.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }
          }, 'image/png', 1.0);
        }
        URL.revokeObjectURL(blobURL);
      };

      img.src = blobURL;
    }
  };

  const handlePrintQRCode = () => {
    const printWindow = window.open('', '', 'height=400,width=400');
    const svg = document.getElementById('qr-code-container');
    if (printWindow && svg) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print QR Code</title>
            <style>
              body { margin: 0; display: flex; flex-direction: column; align-items: center; }
              .qr-container { margin: 20px; }
            </style>
          </head>
          <body>
            <div class="qr-container">
              <h2 style="text-align: center;">${formData.title || 'QR Code'}</h2>
              ${svg.outerHTML}
              <script>
                // Wait for the image to load before printing
                const img = document.querySelector('image');
                if (img) {
                  img.onload = function() {
                    window.print();
                  };
                } else {
                  window.print();
                }
              </script>
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setFormData((prev) => ({ ...prev, latitude: lat, longitude: lng }));
    setMapCenter({ lat, lng });
  };

  const getMapCoordinatesPickerTooltip = () => {
    if (formData.itemType === ItemType.GPSLocation) {
      return t('admin.huntItems.gpsLocationTooltip');
    }
    return t('admin.huntItems.startingPointTooltip');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {huntItem ? t('admin.huntItems.editHuntItem') : t('admin.huntItems.createHuntItem')}
      </DialogTitle>
      <DialogContent>
        {huntItem?.isInUseByActiveHunt && (
          <Alert severity="warning" sx={{ mt: 2, mb: 2, border: '1px dashed red' }}>
            <Typography variant="body2" fontStyle="italic" color="error">
              {t('admin.huntItems.inUseWarning')}
            </Typography>
          </Alert>
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Tooltip title={t('admin.huntItems.titleTooltip')} placement="top-start">
              <TextField
                name="title"
                label={t('admin.huntItems.title')}
                value={formData.title}
                onChange={handleChange}
                fullWidth
                required
                error={!!errors.title}
                helperText={errors.title || `${formData.title?.length || 0}/22`}
                InputProps={{
                  endAdornment: <InfoIcon color="action" fontSize="small" />,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={t('admin.huntItems.descriptionTooltip')} placement="top-start">
              <TextField
                name="description"
                label={t('admin.huntItems.description')}
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  endAdornment: <InfoIcon color="action" fontSize="small" />,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={t('admin.huntItems.instructionsTooltip')} placement="top-start">
              <TextField
                name="instructions"
                label={t('admin.huntItems.instructions')}
                value={formData.instructions}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  endAdornment: <InfoIcon color="action" fontSize="small" />,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              name="itemType"
              label={t('admin.huntItems.itemType')}
              value={formData.itemType}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.itemType}
              helperText={errors.itemType}
            >
              {Object.values(ItemType).map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`admin.huntItems.itemTypes.${type}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="hint1"
              label={t('admin.huntItems.hint1')}
              value={formData.hint1}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          {formData.hint1 && (
            <Grid item xs={12}>
              <TextField
                name="hint2"
                label={t('admin.huntItems.hint2')}
                value={formData.hint2}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          )}
          {formData.hint2 && (
            <Grid item xs={12}>
              <TextField
                name="hint3"
                label={t('admin.huntItems.hint3')}
                value={formData.hint3}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          )}
          {formData.itemType === ItemType.Question && (
            <>
              <Grid item xs={12}>
                <TextField
                  name="question"
                  label={t('admin.huntItems.question')}
                  value={formData.question}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.question}
                  helperText={errors.question}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="answer"
                  label={t('admin.huntItems.answer')}
                  value={formData.answer}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={!!errors.answer}
                  helperText={errors.answer}
                />
              </Grid>
            </>
          )}
          {formData.itemType === ItemType.QRCode && (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" mb={1}>{t('admin.huntItems.qrCode')}</Typography>
              <Box display="flex" flexDirection="column" alignItems="center">
                <svg id="qr-code-container" width="250" height="270">
                  <rect x="0" y="0" width="250" height="270" fill="white" stroke="black" strokeWidth="2" />
                  <g transform="translate(25, 25)">
                    <QRCodeSVG
                      value={`https://hulahunt.com/?qr=${formData.qrCodeUuid || ''}`}
                      size={200}
                      level="M"
                      fgColor="#005522"
                      title="HulaHunt"
                      imageSettings={{
                        src: 'https://images-prod.hulahunt.com/site/logo200.png',
                        width: 64,
                        height: 64,
                        x: undefined,
                        y: undefined,
                        excavate: false,
                      }}
                    />
                  </g>
                  <text x="125" y="250" textAnchor="middle" fontFamily="Arial" fontSize="18">
                    HULAHUNT.COM
                  </text>
                </svg>
                <Box mt={1}>
                  <Tooltip title={t('admin.huntItems.downloadQRCode')}>
                    <IconButton onClick={handleDownloadQRCode}>
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('admin.huntItems.printQRCode')}>
                    <IconButton onClick={handlePrintQRCode}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <MapCoordinatesPicker
              latitude={mapCenter.lat}
              longitude={mapCenter.lng}
              onCoordinatesChange={handleCoordinatesChange}
              title={t('admin.huntItems.gpsLocation')}
              titleTooltip={getMapCoordinatesPickerTooltip()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button onClick={handleSave} variant="contained" disabled={!isFormValid}>
          {huntItem ? t('common.update') : t('common.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function getInitialFormData(): Partial<HuntItem> {
  return {
    id: undefined,
    title: '',
    description: '',
    instructions: '',
    itemType: ItemType.QRCode,
    hint1: '',
    hint2: '',
    hint3: '',
    question: '',
    answer: '',
    qrCodeUuid: uuidv4(),
    latitude: undefined,
    longitude: undefined,
  };
}
