import { HuntItem } from './HuntItem';

export const HAWAIIAN_ISLANDS = [
  'Hawaii (Big Island)',
  'Maui',
  'Oahu',
  'Kauai',
  'Molokai',
  'Lanai',
  'Niihau',
  'Kahoolawe'
] as const;

export type HawaiianIsland = typeof HAWAIIAN_ISLANDS[number];


export interface Location {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  businessId?: number;
  areaName?: HawaiianIsland | null;
  business: {
    id: number;
    name: string;
  } | null;
  huntItems?: HuntItem[];
}

export type CreateLocationData = Omit<Location, 'id' | 'business'> & {
  businessId: number | undefined;
  huntItems?: Omit<HuntItem, 'id'>[];
};

export type UpdateLocationData = Partial<CreateLocationData>;