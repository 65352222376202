import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrivacyContent from '../../components/PrivacyContent';
import TermsContent from '../../components/TermsContent';

interface PrivacyTermsModalProps {
  open: boolean;
  onClose: () => void;
  type: 'privacy' | 'terms';
}

export const PrivacyTermsModal: React.FC<PrivacyTermsModalProps> = ({
  open,
  onClose,
  type
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (open) {
      if (type === 'privacy') {
        setContent(PrivacyContent);
      } else {
        setContent(TermsContent);
      }
    }
  }, [open, type]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="privacy-terms-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="privacy-terms-dialog-title">
        {type === 'privacy' ? t('footer.privacyTitle') : t('footer.termsTitle')}
      </DialogTitle>
      <DialogContent dividers>
          <div
            className="max-h-[60vh] overflow-y-auto"
            dangerouslySetInnerHTML={{ __html: content }}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyTermsModal;
