import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../components/common';
import CouponList from '../../components/CouponList';

const MyCoupons: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout title={t('myCoupons.title')}>
      <Box
        sx={{
          px: { xs: 2, sm: 3 },
          py: 1,
          mx: 'auto',
          maxWidth: '1200px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography variant="subtitle1" gutterBottom sx={{ mb: 4, fontStyle: 'italic', textAlign: 'center' }}>
          {t('myCoupons.description')}
        </Typography>

        <CouponList
          emptyListComponent={
            <Typography variant="h6" align="center">
              {t('myCoupons.startHuntForCoupons')}
            </Typography>
          }
        />
      </Box>
    </PageLayout>
  );
};

export default MyCoupons;
