import axios from 'axios';
import { getApiPath } from '../getApiPath';

export const startHuntItem = async (huntId: number, huntItemId: number): Promise<boolean> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    await axios.post(
      getApiPath(`/activehunt/startHuntItem/${huntId}/${huntItemId}`),
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching hint');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
    return false;
  }
};