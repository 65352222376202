import axios from 'axios';
import { getApiPath } from '../getApiPath';

export const deleteLocation = async (locationId: number): Promise<void> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  await axios.delete(getApiPath(`/location/deleteLocation/${locationId}`), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};