import axios from 'axios';
import { getApiPath } from '../getApiPath';
import { ActiveHunt } from '../../types/ActiveHunt';

export const getHuntInformation = async (huntId: number): Promise<ActiveHunt> => {
  try {
    const response = await axios.get<ActiveHunt>(getApiPath(`/activehunt/getHuntInformation/${huntId}`), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching hunt information');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};
