import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Slider,
  SelectChangeEvent,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SortColumn, SortOrder, FilterColumn } from '../../services/hunt/getHunts';

interface MobileFilterDialogProps {
  isOpen: boolean;
  onClose: () => void;
  sortColumn: SortColumn;
  sortOrder: SortOrder;
  filterColumn: FilterColumn;
  filterUpperBound: number;
  onSortChange: (event: SelectChangeEvent<SortColumn>) => void;
  onSortOrderChange: () => void;
  onFilterColumnChange: (event: SelectChangeEvent<FilterColumn>) => void;
  onFilterUpperBoundChange: (event: Event, newValue: number | number[]) => void;
}

export const MobileFilterDialog: React.FC<MobileFilterDialogProps> = ({
  isOpen,
  onClose,
  sortColumn,
  sortOrder,
  filterColumn,
  filterUpperBound,
  onSortChange,
  onSortOrderChange,
  onFilterColumnChange,
  onFilterUpperBoundChange,
}) => {
  const { t } = useTranslation();

  const getFilterLabel = (column: FilterColumn) => {
    switch (column) {
      case 'distance':
        return t('findHunts.filterMaxTotalDistance');
      case 'fake_hunt_item_count':
        return t('findHunts.filterMaxItemCount');
      case 'estimated_time_hours':
        return t('findHunts.filterMaxTimeHours');
      default:
        return t('findHunts.filterUpperBound');
    }
  };

  const getFilterUnit = (column: FilterColumn) => {
    switch (column) {
      case 'distance':
        return t('findHunts.miles');
      case 'fake_hunt_item_count':
        return t('findHunts.items');
      case 'estimated_time_hours':
        return t('findHunts.hours');
      default:
        return '';
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('findHunts.filterAndSort')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>

        <Grid container spacing={1}>
          <Grid item xs={9}>
            <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel>{t('findHunts.sortBy')}</InputLabel>
              <Select<SortColumn>
                value={sortColumn}
                label={t('findHunts.sortBy')}
                onChange={onSortChange}
                size={'small'}
              >
                <MenuItem value="name">{t('findHunts.name')}</MenuItem>
                <MenuItem value="location_area_name">{t('findHunts.location')}</MenuItem>
                <MenuItem value="distance">{t('findHunts.distance')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button onClick={onSortOrderChange} variant="outlined" fullWidth sx={{ mt: 1, mb: 2, pb: 1, pt: 1, fontSize: '0.75rem' }}>
              {sortOrder === 'ASC' ? t('findHunts.ascending') : t('findHunts.descending')}
            </Button>
          </Grid>
        </Grid>


          <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>{t('findHunts.filterBy')}</InputLabel>
          <Select<FilterColumn>
            value={filterColumn}
            label={t('findHunts.filterBy')}
            onChange={onFilterColumnChange}
            size={'small'}
          >
            <MenuItem value="distance">{t('findHunts.distance')}</MenuItem>
            <MenuItem value="fake_hunt_item_count">{t('findHunts.itemCount')}</MenuItem>
            <MenuItem value="estimated_time_hours">{t('findHunts.estimatedTime')}</MenuItem>
          </Select>
        </FormControl>

        <Typography gutterBottom>
          {getFilterLabel(filterColumn)}: {filterUpperBound} {getFilterUnit(filterColumn)}
        </Typography>
        <Slider
          value={filterUpperBound}
          onChange={onFilterUpperBoundChange}
          min={0}
          max={filterColumn === 'distance' ? 200 : 15}
          step={1}
        />
      </DialogContent>
      <DialogActions sx={{ mt: -1 }}>
        <Button onClick={onClose}>{t('findHunts.apply')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileFilterDialog;