import axios from 'axios';
import { Business } from '../../types/Business';
import { getApiPath } from '../getApiPath';

export const createBusiness = async (formData: FormData): Promise<Business> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }
  const response = await axios.post(getApiPath('/business/createBusiness'), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};