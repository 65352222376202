import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress, Button, TextField } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import PageLayout from '../../components/common/PageLayout';
import { useTranslation } from 'react-i18next';
import { updateUser } from '../../services/user/updateUser';

const Profile: React.FC = () => {
  const { user, isLoading, isAuthenticated, logout } = useAuth();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
    }
  }, [user]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>{t('profile.pleaseLogin')}</Typography>
      </Box>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (user) {
      try {
        await updateUser(user.id, { firstName, lastName });
        setIsEditing(false);
        // TODO: Better success message in Dialog
        alert(t('profile.updateSuccess'));
        // Reload the page, this will get the updated user from the server
        window.location.reload();
      } catch (error) {
        console.error('Error updating user:', error);
        alert(t('profile.updateError'));
      }
    }
  };

  return (
    <PageLayout title={t('profile.title')}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, maxWidth: 400, margin: 'auto' }}>
        {user.picture && <img src={user.picture} alt="Profile" style={{ width: 100, height: 100, borderRadius: '50%' }} />}
        <Typography>{t('profile.email')}: {user.email}</Typography>

        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mT: 1 }}>
            <TextField
              label={t('profile.firstName')}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={!isEditing}
              fullWidth
            />
            <TextField
              label={t('profile.lastName')}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={!isEditing}
              fullWidth
            />

            {isEditing ? (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  {t('profile.save')}
                </Button>
                <Button variant="outlined" onClick={() => setIsEditing(false)} fullWidth>
                  {t('profile.cancel')}
                </Button>
              </Box>
            ) : (
              <Button variant="contained" color="primary" onClick={() => setIsEditing(true)} fullWidth>
                {t('profile.edit')}
              </Button>
            )}
          </Box>
        </form>

        <Button
          variant='contained'
          color='secondary'
          fullWidth
          onClick={() => logout()}
        >
          {t('profile.logout')}
        </Button>
      </Box>
    </PageLayout>
  );
};

export default Profile;