import { getUserHunts } from './getUserHunts';
import { UserHuntStatus } from '../../types/UserHunt';

export const checkUserHuntOwnership = async (userId: number, huntId: number): Promise<boolean> => {
  try {
    const response = await getUserHunts(1, 1, userId, undefined, huntId);

    // Check if there are any user hunts returned
    if (response.userHunts.length > 0) {
      const userHunt = response.userHunts[0];

      // We could just check for the existence of the userHunt, but in the future we may want to allow a user
      // to repurchase a finished hunt...
      return (
        userHunt.status === UserHuntStatus.Purchased ||
        userHunt.status === UserHuntStatus.InProgress ||
        userHunt.status === UserHuntStatus.Finished
      );
    }

    return false;
  } catch (error) {
    console.error('Error checking user hunt ownership:', error);
    throw error;
  }
};