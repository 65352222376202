import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { sendUserIssueReportEmail } from '../../services/sendUserIssueReportEmail/sendUserIssueReportEmail';
import ConfirmationDialog from './ConfirmationDialog';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';

const userIssueSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email address'),
  description: z.string().min(10, 'Issue description must be at least 10 characters long'),
});

type UserIssueFormData = z.infer<typeof userIssueSchema>;

interface ReportIssueModalProps {
  open: boolean;
  onClose: () => void;
}

const ReportIssueModal: React.FC<ReportIssueModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isMobile = useIsMobile();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { control, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<UserIssueFormData>({
    resolver: zodResolver(userIssueSchema),
    defaultValues: {
      name: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}`.trim() : '',
      email: user?.email || '',
      description: '',
    },
  });

  const onSubmit = async (data: UserIssueFormData) => {
    setSubmitError(null);
    try {
      await sendUserIssueReportEmail({
        name: data.name,
        emailFrom: data.email,
        issueDescription: data.description,
      });
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error submitting issue:', error);
      setSubmitError(t('reportIssue.submissionFailed'));
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    reset();
    onClose();
  };

  useEffect(() => {
    if (user?.firstName && user?.lastName) {
      reset({
        name: `${user.firstName} ${user.lastName}`.trim(),
        email: user?.email || '',
        description: '',
      });
    }
  }, [user, reset]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={isMobile ? 'sm' : 'md'}
        PaperProps={{
          style: {
            borderRadius: isMobile ? 12 : 4,
            margin: isMobile ? 12 : 0,
          },
        }}
      >
        <DialogTitle>
          {t('reportIssue.title')}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  margin="dense"
                  label={t('reportIssue.name')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label={t('reportIssue.email')}
                  type="email"
                  fullWidth
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label={t('reportIssue.description')}
                  multiline
                  rows={7}
                  fullWidth
                  variant="outlined"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
            {submitError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {submitError}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('common.cancel')}</Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? t('common.submitting') : t('common.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title={t('reportIssue.confirmationTitle')}
        message={t('reportIssue.confirmationMessage')}
        onConfirm={handleConfirmationClose}
        onCancel={handleConfirmationClose}
        confirmButtonText={t('common.close')}
        showCancelButton={false}
      />
    </>
  );
};

export default ReportIssueModal;