import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useStripePurchase } from '../../hooks/useStripePurchase';
import {
  Alert,
  Button,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tab,
  Tabs,
  CircularProgress,
  Typography,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PageLayout, ResponsiveTextField } from '../../components/common';
import { User } from '../../types/User';
import { checkUserHuntOwnership } from '../../services/userhunt/checkUserHuntOwnership';
import PasswordField  from './PasswordField';
import { forgotPassword } from '../../services/resetPassword/forgotPassword';
import PrivacyTermsModal from '../../components/common/PrivacyTermsModal';
import { Roles } from '../../types/Roles';

const LoginSignup: React.FC = () => {
  const { t } = useTranslation();
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState<string | null>(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [lastAttemptedEmail, setLastAttemptedEmail] = useState<string | null>(null);

  const loginSchema = z.object({
    email: z.string().email({ message: t('loginsignup.emailValidation') }),
    // Less strict for login because admins may create passwords that are unvalidated
    password: z.string().min(4, { message: t('loginsignup.passwordRequired') }),
  });

  const signupSchema = z.object({
    email: z.string().email({ message: t('loginsignup.emailValidation') }),
    password: z.string()
      .min(8, { message: t('loginsignup.passwordValidation.length') }),
    confirmPassword: z.string()
  }).refine((data) => data.password === data.confirmPassword, {
    message: t('loginsignup.passwordValidation.match'),
    path: ["confirmPassword"],
  });

  type LoginFormData = z.infer<typeof loginSchema>;
  type SignupFormData = z.infer<typeof signupSchema>;

  interface LocationState {
    message?: string;
    completePurchaseAfterLogin?: boolean;
    itemId?: number;
    itemType?: string;
    couponCode?: string;
  }

  const LoginForm: React.FC<{ onSubmit: (data: LoginFormData) => void; isLoading: boolean }> = ({ onSubmit, isLoading }) => {
    const { control, handleSubmit, watch, setValue } = useForm<LoginFormData>({
      resolver: zodResolver(loginSchema),
      mode: 'onBlur',
      defaultValues: {
        email: lastAttemptedEmail || '',
      },
    });

    const email = watch('email');

    useEffect(() => {
      if (lastAttemptedEmail && lastAttemptedEmail !== email) {
        setValue('email', lastAttemptedEmail);
        setLastAttemptedEmail(null);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastAttemptedEmail, setValue, email]);

    const handleForgotPassword = async () => {
      if (!email || !loginSchema.shape.email.safeParse(email).success) {
        setForgotPasswordMessage(t('loginsignup.forgotPassword.enterEmail'));
        return;
      }

      try {
        await forgotPassword(email);
        setForgotPasswordMessage(t('loginsignup.forgotPassword.checkEmail', { email }));
      } catch (error) {
        console.error('Error sending forgot password email:', error);
        setForgotPasswordMessage(t('loginsignup.forgotPassword.error'));
      }
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <ResponsiveTextField
              {...field}
              fullWidth
              label={t('loginsignup.email')}
              type="email"
              variant="outlined"
              error={!!error}
              helperText={error?.message}
              autoComplete="username"
              required
              margin="normal"
            />
          )}
        />
        <PasswordField control={control} name="password" label={t('loginsignup.password')} autoComplete="current-password" />
        {showForgotPassword && (
        <Link
          component="button"
          variant="body2"
          onClick={handleForgotPassword}
          sx={{ display: 'block', mb: 2, textAlign: 'right', mt: 1 }}
        >
          {t('loginsignup.forgotPassword.link')}
        </Link>
        )}
        {forgotPasswordMessage && (
          <Typography color="secondary.main" sx={{ mb: 2 }}>
            {forgotPasswordMessage}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          className="bg-hulahunt-primary text-white"
          sx={{ mt: 2 }}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : t('loginsignup.submitLogin')}
        </Button>
      </form>
    );
  };

  const SignupForm: React.FC<{ onSubmit: (data: SignupFormData) => void; isLoading: boolean }> = ({ onSubmit, isLoading }) => {
    const { control, handleSubmit } = useForm<SignupFormData>({
      resolver: zodResolver(signupSchema),
      mode: 'onBlur',
    });

    const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
    const [openTermsModal, setOpenTermsModal] = useState(false);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <ResponsiveTextField
                {...field}
                fullWidth
                label={t('loginsignup.email')}
                variant="outlined"
                type='email'
                error={!!error}
                helperText={error?.message}
                autoComplete="username"
                required
              />
            )}
          />
          <PasswordField control={control} name="password" label={t('loginsignup.password')} autoComplete="new-password" />
          <PasswordField control={control} name="confirmPassword" label={t('loginsignup.confirmPassword')} autoComplete="new-password" />

          <Typography variant="body2" sx={{ mt: 1 }}>
            {t('loginsignup.termsAgreement')}
            <Link component="button" type="button" onClick={() => setOpenTermsModal(true)}>
              {t('loginsignup.termsOfService')}
            </Link>
            {t('loginsignup.and')}
            <Link component="button" type="button" onClick={() => setOpenPrivacyModal(true)}>
              {t('loginsignup.privacyPolicy')}
            </Link>
          </Typography>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            className="bg-hulahunt-primary text-white"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : t('loginsignup.createAccount')}
          </Button>
        </Box>

        <PrivacyTermsModal
          open={openPrivacyModal}
          onClose={() => setOpenPrivacyModal(false)}
          type="privacy"
        />
        <PrivacyTermsModal
          open={openTermsModal}
          onClose={() => setOpenTermsModal(false)}
          type="terms"
        />
      </form>
    );
  };

  const { loginWithRedirect, signInWithRedirect, loginWithGoogle } = useAuth();
  const { isLoading: isPurchaseLoading, error: purchaseError, initiateCheckout } = useStripePurchase();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [openOwnershipDialog, setOpenOwnershipDialog] = useState(false);

  const state = location.state as LocationState;

  useEffect(() => {
    if (purchaseError) {
      setErrorMessage(t('loginsignup.purchaseError'));
      setOpenErrorDialog(true);
    }
  }, [purchaseError, t]);

  const handleAuthSuccess = async (user: User) => {
    if (state?.completePurchaseAfterLogin && state.itemId && state.itemType) {
      try {
        if (state.itemType === 'hunt') {
          const alreadyOwns = await checkUserHuntOwnership(user.id, state.itemId);
          if (alreadyOwns) {
            setOpenOwnershipDialog(true);
            return;
          }
        }
        // Stripe will perform the purchase and redirect back to the app purchase success page
        await initiateCheckout({ id: state.itemId, type: state.itemType }, state.couponCode);
      } catch (error) {
        console.error('Purchase error:', error);
        setErrorMessage(t('loginsignup.failed.description'));
        setOpenErrorDialog(true);
      }
    } else {
      if (user.role === Roles.Business || user.role === Roles.Admin) {
        navigate('/admin');
      } else {
        navigate('/findhunts');
      }
    }
  };

  const handleCloseOwnershipDialog = () => {
    setOpenOwnershipDialog(false);
    navigate('/myhunts');
  };

  const onLoginSubmit = async (data: LoginFormData) => {
    setIsAuthLoading(true);
    setLastAttemptedEmail(data.email);
    try {
      const user = await loginWithRedirect(data.email, data.password);
      await handleAuthSuccess(user);
    } catch (error) {
      console.error('Login failed:', error);
      if (error instanceof Error) {
        if (error.message === 'NO_ACCOUNT') {
          setErrorMessage(t('loginsignup.noAccount'));
          setTabValue(1); // Switch to signup tab
        } else if (error.message === 'USE_GOOGLE_LOGIN') {
          setErrorMessage(t('loginsignup.useGoogleLogin'));
        } else if (error.message === 'INVALID_CREDENTIALS') {
          setErrorMessage(t('loginsignup.invalidCredentials'));
          setShowForgotPassword(true);
        } else {
          setErrorMessage(t('loginsignup.unknownError'));
        }
      } else {
        setErrorMessage(t('loginsignup.unknownError'));
      }
      setOpenErrorDialog(true);
    } finally {
      setIsAuthLoading(false);
    }
  };

  const onSignupSubmit = async (data: SignupFormData) => {
    setIsAuthLoading(true);
    try {
      const validationResult = signupSchema.safeParse(data);
      if (!validationResult.success) {
        console.error('Validation errors:', validationResult.error.errors);
        throw new Error(t('loginsignup.validationFailed'));
      }
      const user = await signInWithRedirect(data.email, data.password);
      await handleAuthSuccess(user);
    } catch (error) {
      console.error('Signup failed:', error);
      if (error instanceof Error && error.message === 'ACCOUNT_EXISTS') {
        setErrorMessage(t('loginsignup.accountExists'));
        setTabValue(0); // Switch to login tab
      } else {
        setErrorMessage(error instanceof Error ? error.message : t('loginsignup.unknownError'));
      }
      setOpenErrorDialog(true);
    } finally {
      setIsAuthLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleGoogleSuccess = async (credentialResponse: any) => {
    setIsAuthLoading(true);
    try {
      const user = await loginWithGoogle(credentialResponse.credential);
      await handleAuthSuccess(user);
    } catch (error) {
      console.error('Google login failed:', error);
      if (error instanceof Error && error.message === 'NO_ACCOUNT') {
        setErrorMessage(t('loginsignup.noGoogleAccount'));
        setTabValue(1); // Switch to signup tab
      } else {
        setErrorMessage(error instanceof Error ? error.message : t('loginsignup.googleLoginFailed'));
      }
      setOpenErrorDialog(true);
    } finally {
      setIsAuthLoading(false);
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const isLoading = isAuthLoading || isPurchaseLoading;

  return (
    <PageLayout>
      <Box sx={{
        maxWidth: { xs: '100%', sm: 400 },
        width: '100%',
        margin: 'auto',
        padding: { xs: 2, sm: 0 }
      }}>
        {state?.message && (
          <Alert
            severity="info"
            sx={{
              mb: 2,
              borderStyle: 'dashed',
              borderWidth: '1px',
              borderColor: "#faa",
            }}
          >
            {state.message}
          </Alert>
        )}
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} centered sx={{ mb: 2 }}>
          <Tab label={t('loginsignup.loginTitle')} />
          <Tab label={t('loginsignup.signupTitle')} />
        </Tabs>
        {tabValue === 0 ? (
          <LoginForm onSubmit={onLoginSubmit} isLoading={isLoading} />
        ) : (
          <SignupForm onSubmit={onSignupSubmit} isLoading={isLoading} />
        )}
        <Divider sx={{ width: '100%', my: 2 }}>{t('loginsignup.orDivider')}</Divider>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={() => {
              setErrorMessage(t('loginsignup.googleLoginFailed'));
              setOpenErrorDialog(true);
            }}
          />
        </Box>
      </Box>
      <Dialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('loginsignup.failed.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} autoFocus>
            {t('loginsignup.okButton')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openOwnershipDialog}
        onClose={handleCloseOwnershipDialog}
        aria-labelledby="ownership-dialog-title"
        aria-describedby="ownership-dialog-description"
      >
        <DialogTitle id="ownership-dialog-title">
          {t('loginsignup.alreadyOwned.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="ownership-dialog-description">
            {t('loginsignup.alreadyOwned.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ mX: 2 }}
            className="bg-hulahunt-primary text-white"
            onClick={handleCloseOwnershipDialog}
            autoFocus
            >
            {t('loginsignup.alreadyOwned.okButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default LoginSignup;