import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Box, Button, Typography } from '@mui/material';
import { Close as CloseIcon, Navigation as NavigationIcon } from '@mui/icons-material';
import { GoogleMap, CircleF, MarkerF, InfoWindowF, PolylineF } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useGoogleMapsScript } from '../../hooks/useGoogleMapsScript';
import { UserLocationMarker } from './UserLocationMarker';

export interface LocationPin {
  latitude: number;
  longitude: number;
  title: string;
  objectId: string | number;
  description?: string;
  status: 'complete' | 'in_progress' | 'not_started';
}

interface HuntLocationMapProps {
  open: boolean;
  onClose: () => void;
  latitude: number;
  longitude: number;
  isItemLocation?: boolean;
  title?: string;
  subtitle?: string;
  showUserLocation?: boolean;
  additionalPins?: LocationPin[];
  doNotShowMapKey?: boolean;
  showDistanceToUser?: boolean;
  onPinClick?: (objectId: string | number) => void;
  onGoToItem?: (objectId: string | number) => void;
  drawLineFromUserLocation?: boolean;
}

const HuntLocationMap: React.FC<HuntLocationMapProps> = ({
  open,
  onClose,
  latitude,
  longitude,
  isItemLocation = false,
  title,
  subtitle,
  showUserLocation = false,
  showDistanceToUser = false,
  additionalPins = [],
  doNotShowMapKey = false,
  onPinClick,
  onGoToItem,
  drawLineFromUserLocation = false,
}) => {
  const { t } = useTranslation();

  const { isLoaded, loadError } = useGoogleMapsScript();

  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [selectedPin, setSelectedPin] = useState<LocationPin | null>(null);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: latitude, lng: longitude });
  const [zoom, setZoom] = useState(13);
  const mapRef = useRef<google.maps.Map>();
  const isMounted = useRef(true);
  const [circleRadius, setCircleRadius] = useState<number>(1000);
  const [isMapReady, setIsMapReady] = useState(false);

  // Add watchPosition ID reference
  const watchPositionId = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset selectedPin when the map is opened
  useEffect(() => {
    if (open) {
      setSelectedPin(null);
    }
  }, [open]);

  const handleNavigate = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(url, '_blank');
  };

  React.useEffect(() => {
    if (showUserLocation) {
      // Initial position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );

      // Watch position for updates
      watchPositionId.current = navigator.geolocation.watchPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error watching user location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 10000,
        }
      );
    }

    // Cleanup function
    return () => {
      if (watchPositionId.current !== null) {
        navigator.geolocation.clearWatch(watchPositionId.current);
      }
    };
  }, [showUserLocation]);

  const handlePinClick = (pin: LocationPin) => {
    if (onPinClick) {
      setSelectedPin(pin);
      onPinClick(pin.objectId);
    }
  };

  const getPinIcon = (status: string) => {
    switch (status) {
      case 'complete':
        return {
          url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
          scaledSize: new window.google.maps.Size(30, 30)
        };
      case 'in_progress':
      case 'not_started':
        return {
          url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
          scaledSize: new window.google.maps.Size(30, 30)
        };
      default:
        return {
          url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
          scaledSize: new window.google.maps.Size(30, 30)
        };
    }
  };

  const handleMapClick = () => {
    setSelectedPin(null);
  };

  const showMapKey = showUserLocation || additionalPins.length > 0;

  const renderMapKey = () => {
    if (!showMapKey || doNotShowMapKey) return null;

    return (
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {showUserLocation && userLocation && (
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
              {/* Updated user location marker map key to match the star shape */}
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    background: '#FFD700',
                    clipPath: 'path("M10 0L12.645 8.355L21 11L12.645 13.645L10 22L7.355 13.645L-1 11L7.355 8.355L10 0Z")',
                  },
                  mr: 1
                }}
              />
              <Typography variant="body2">{t('common.yourLocation')}</Typography>
            </Box>
          )}
         {showUserLocation && userLocation && showDistanceToUser && (
          <Typography
            variant="body2"
            sx={{
              textAlign: 'right',
              position: 'absolute',
              right: 0,
              bottom: 18,
              mr: 3,
            }}
          >
            {t('activeHunt.away', { distance: calculateDistance() })}
          </Typography>
        )}


        {additionalPins.length > 0 && (
          <>
          <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
            <Box
              component="img"
                src="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                sx={{ width: 20, height: 20, mr: 1 }}
              />
              <Typography variant="body2">{t('activeHunt.completedItem')}</Typography>
            </Box>


            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
              <Box
                component="img"
                src="http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
                sx={{ width: 20, height: 20, mr: 1 }}
              />
              <Typography variant="body2">{t('activeHunt.activeItem')}</Typography>
            </Box>
            </>
          )}

        </Box>
      </Box>
    );
  };

  const handleCloseInfoWindow = () => {
    setSelectedPin(null);
  };

  const getBoundsAndCenter = useCallback(() => {
    if (isItemLocation) {
      return { center: { lat: latitude, lng: longitude }, zoom: 15 };
    }

    if (!mapRef.current) return null;
    const bounds = new google.maps.LatLngBounds();

    if (additionalPins.length > 0) {
      additionalPins.forEach(pin => {
        bounds.extend({ lat: pin.latitude, lng: pin.longitude });
      });
    } else {
      // Create a minimum bounding box around the main location
      const minRadius = circleRadius || 1000; // Use circleRadius or default to 1000 meters
      const center = new google.maps.LatLng(latitude, longitude);
      const northEast = google.maps.geometry.spherical.computeOffset(center, minRadius * Math.sqrt(2), 45);
      const southWest = google.maps.geometry.spherical.computeOffset(center, minRadius * Math.sqrt(2), 225);
      bounds.extend(northEast);
      bounds.extend(southWest);
    }

    bounds.extend({ lat: latitude, lng: longitude });

    const center = bounds.getCenter();
    return { bounds, center: { lat: center.lat(), lng: center.lng() } };
  }, [latitude, longitude, additionalPins, circleRadius, isItemLocation]);

  useEffect(() => {
    const result = getBoundsAndCenter();
    if (result && isMounted.current) {
      if (isItemLocation) {
        setMapCenter(result.center);
        setZoom(result.zoom || 13);
      } else {
        setMapCenter(result.center);
        if (mapRef.current && result.bounds) {
          mapRef.current.fitBounds(result.bounds, { top: 50, bottom: 50, left: 50, right: 50 });
        }
      }
    }
  }, [getBoundsAndCenter, circleRadius, isItemLocation]);

  const getCircleCenter = useMemo(() => {
    if (additionalPins.length === 0) {
      return { lat: latitude, lng: longitude };
    }
    const sumLat = additionalPins.reduce((sum, pin) => sum + pin.latitude, 0);
    const sumLng = additionalPins.reduce((sum, pin) => sum + pin.longitude, 0);
    return {
      lat: sumLat / additionalPins.length,
      lng: sumLng / additionalPins.length
    };
  }, [latitude, longitude, additionalPins]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    const result = getBoundsAndCenter();
    if (result) {
      if (isItemLocation) {
        map.setCenter(result.center);
        map.setZoom(result.zoom || 13);
      } else if (result.bounds) {
        map.fitBounds(result.bounds, { top: 50, bottom: 50, left: 50, right: 50 });
      }
    }
    setIsMapReady(true);
  }, [getBoundsAndCenter, isItemLocation]);

  useEffect(() => {
    if (isMapReady && mapRef.current) {
      const center = getCircleCenter;
      let maxDistance = 0;

      if (additionalPins.length > 0) {
        // Calculate the maximum distance from the center to any pin
        additionalPins.forEach(pin => {
          const distance = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(center.lat, center.lng),
            new google.maps.LatLng(pin.latitude, pin.longitude)
          );
          maxDistance = Math.max(maxDistance, distance);
        });

        // Include the main location (latitude, longitude) in the calculation
        const mainLocationDistance = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(center.lat, center.lng),
          new google.maps.LatLng(latitude, longitude)
        );
        maxDistance = Math.max(maxDistance, mainLocationDistance);

        // Set the circle radius to encompass all pins with a 10% margin
        setCircleRadius(maxDistance * 1.1);
      } else {
        setCircleRadius(1000); // Default radius when there are no additional pins
      }
    }
  }, [isMapReady, additionalPins, getCircleCenter, latitude, longitude]);

  const calculateDistance = useCallback(() => {
    if (!userLocation) return null;

    const userLatLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
    const itemLatLng = new google.maps.LatLng(latitude, longitude);

    const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(userLatLng, itemLatLng);
    const distanceInMiles = distanceInMeters * 0.000621371; // Convert meters to miles

    return distanceInMiles.toFixed(1);
  }, [userLocation, latitude, longitude]);

  if (loadError) return <div>{t('common.errorLoadingMap')}</div>;
  if (!isLoaded) return <div>{t('common.loadingMap')}</div>;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ textAlign: 'center', mb: subtitle ? -1 : 2 }}>
        {title || t('activeHunt.huntLocation')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {subtitle && (
        <DialogContentText sx={{ textAlign: 'center', mb: 0, fontStyle: 'italic' }}>
         &quot;{subtitle}&quot;
        </DialogContentText>
      )}
      <DialogContent>
        <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={mapCenter}
            zoom={zoom}
            onClick={handleMapClick}
            onLoad={onMapLoad}
          >
            {drawLineFromUserLocation && userLocation && (
              <PolylineF
                path={[
                  { lat: userLocation.lat, lng: userLocation.lng },
                  { lat: latitude, lng: longitude }
                ]}
                options={{
                  strokeColor: "#4285F4",
                  strokeOpacity: 0.8,
                  strokeWeight: 1,
                  geodesic: true,
                  icons: [{
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      scale: 3,
                      strokeColor: "#4285F4",
                      strokeWeight: 2,
                    },
                    offset: '100%'
                  }]
                }}
              />
            )}
            {isItemLocation ? (
              <MarkerF
                position={{ lat: latitude, lng: longitude }}
                icon={{
                  url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                  scaledSize: new window.google.maps.Size(40, 40)
                }}
              />
            ) : (
              <CircleF
                center={getCircleCenter}
                radius={circleRadius}
                options={{
                  fillColor: '#0000FF',
                  fillOpacity: 0.2,
                  strokeColor: '#0000FF',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            )}
            {showUserLocation && userLocation && (
              <UserLocationMarker position={userLocation} />
            )}
            {additionalPins.map((pin, index) => (
              <MarkerF
                key={index}
                position={{ lat: pin.latitude, lng: pin.longitude }}
                icon={getPinIcon(pin.status)}
                onClick={() => handlePinClick(pin)}
              />
            ))}
            {selectedPin && (
              <InfoWindowF
                position={{ lat: selectedPin.latitude, lng: selectedPin.longitude }}
                options={{
                  minWidth: 225,
                  pixelOffset: new window.google.maps.Size(0, -30),
                }}
                onCloseClick={handleCloseInfoWindow}
              >
                <Box sx={{ width: 200, position: 'relative', overflow: 'hidden' }}>
                  <IconButton
                    size="small"
                    onClick={handleCloseInfoWindow}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: 0,
                      backgroundColor: 'white',
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                      zIndex: 1,
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <Box sx={{ pt: 0, pr: 0 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {selectedPin.title}
                    </Typography>
                    {selectedPin.description && (
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {selectedPin.description.length > 50
                          ? `${selectedPin.description.slice(0, 50)}...`
                          : selectedPin.description}
                      </Typography>
                    )}
                    {selectedPin.status !== 'complete' && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => onGoToItem && onGoToItem(selectedPin.objectId)}
                      >
                        {t('activeHunt.goToItem')}
                      </Button>
                    </Box>
                    )}
                  </Box>
                </Box>
              </InfoWindowF>
            )}
          </GoogleMap>
          {isItemLocation && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<NavigationIcon />}
              onClick={handleNavigate}
              sx={{ position: 'absolute', bottom: 16, left: '50%', transform: 'translateX(-50%)' }}
            >
              {t('activeHunt.navigate')}
            </Button>
          )}
        </Box>


        {renderMapKey()}
      </DialogContent>
    </Dialog>
  );
};

export default HuntLocationMap;