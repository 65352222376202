import React from 'react';
import { Box } from '@mui/material';
import PageLayout from '../../components/common/PageLayout';

const PurchaseCancel: React.FC = () => {
  return (
    <PageLayout title="Purchase Cancelled or Failed">
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    </Box>
    </PageLayout>
  );
};

export default PurchaseCancel;