import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography
} from '@mui/material';
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getLocations, LocationSortColumn, SortOrder } from '../../../services/location/getLocations';
import { getHuntItems } from '../../../services/huntitem/getHuntItems';
import { Location } from '../../../types/Location';
import { HuntItem } from '../../../types/HuntItem';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { ResponsiveTextField, PaginationContainer, ConfirmationDialog } from '../../../components/common';
import { deleteLocation } from '../../../services/location/deleteLocation';
import { LocationModal } from './LocationModal'

export const AdminLocations: React.FC = () => {
  const isMobile = useIsMobile();
  const [locations, setLocations] = useState<Location[]>([]);
  const [huntItems, setHuntItems] = useState<HuntItem[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(isMobile ? 7 : 10);
  const [totalLocations, setTotalLocations] = useState(0);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const [locationSearchString, setLocationSearchString] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<LocationSortColumn>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [isEditLocationModalOpen, setIsEditLocationModalOpen] = useState(false);
  const [isCreateLocationModalOpen, setIsCreateLocationModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{open: boolean, location: Location | null}>({
    open: false,
    location: null,
  });

  useEffect(() => {
    Promise.all([
      getLocations(
        page,
        rowsPerPage,
        locationSearchString,
        sortColumn,
        sortOrder
      ),
      getHuntItems()
    ]).then(([locationsData, huntItemsData]) => {
      setLocations(locationsData.locations);
      setTotalLocations(locationsData.totalLocations);
      setHuntItems(huntItemsData.huntItems);
    });
  }, [page, rowsPerPage, locationSearchString, sortColumn, sortOrder, refetchTrigger]);

  const getHuntItemCountForLocation = (locationId: number) => {
    return huntItems.filter(item => item.locationId === locationId).length;
  };

  const handleCreateLocation = () => {
    setIsCreateLocationModalOpen(true);
  };

  const handleLocationCreated = () => {
    setRefetchTrigger(prev => prev + 1);
  }

  const handleEditLocation = async (location: Location) => {
    try {
      const response = await getHuntItems(1, 1000, undefined, undefined, 'ASC', location.id);
      location.huntItems = response.huntItems;
    } catch (error) {
      console.error('Error fetching hunt items:', error);
    }
    setSelectedLocation(location);
    setIsEditLocationModalOpen(true);
  };

  const handleLocationUpdated = () => {
    setRefetchTrigger(prev => prev + 1);
  }

  const handleDeleteConfirmation = (location: Location | null) => {
    setDeleteConfirmation({ open: true, location });
  };

  const handleDeleteLocation = () => {
    if (deleteConfirmation.location?.id) {
      deleteLocation(deleteConfirmation.location.id).then(() => {
        setRefetchTrigger((prev) => prev + 1);
        setDeleteConfirmation({ open: false, location: null });
      }).catch((error) => {
        console.error('Error deleting location:', error);
      });
    }
  };

  const handleSort = (column: LocationSortColumn) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortColumn(column);
      setSortOrder('ASC');
    }
  };

  return (
    <Box sx={{ p: { xs: 0, sm: 1 } }}>
      <Box sx={{ mb: 3, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateLocation}
          sx={{
            width: { xs: '100%', sm: '300px' },
            alignSelf: 'flex-start'
          }}
        >
          Add Location
        </Button>

        <ResponsiveTextField
          label="Search"
          variant="outlined"
          value={locationSearchString}
          onChange={(e) => setLocationSearchString(e.target.value)}
          sx={{
            flexGrow: 1,
            width: { xs: '100%', sm: 'calc(100% - 300px)' }
          }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort('name')}
                sx={{ cursor: 'pointer', fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.locations.name')} {sortColumn === 'name' && (sortOrder === 'ASC' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('business.name')}
                sx={{ cursor: 'pointer', fontSize: '0.85rem', px: 1 }}
              >
                {t('admin.locations.business')} {sortColumn === 'business.name' && (sortOrder === 'ASC' ? '↑' : '↓')}
              </TableCell>
              {!isMobile && <TableCell>
                {t('admin.locations.huntItemCount')}
              </TableCell>}
              <TableCell align="right" sx={{ fontSize: '0.85rem', px: 1 }}>{t('admin.locations.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{location.name}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>
                  <Typography color={location.business ? 'inherit' : 'grey'} sx={{ fontSize: '0.75rem'}}>
                    {location.business?.name || t('admin.locations.noBusiness')}
                  </Typography>
                </TableCell>
                {!isMobile && <TableCell sx={{ fontSize: '0.75rem', px: 1 }}>{getHuntItemCountForLocation(location.id)}</TableCell>}
                <TableCell align="right" sx={{ fontSize: '0.75rem', px: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <IconButton onClick={() => handleEditLocation(location)} size="small">
                      <EditIcon />
                    </IconButton>
                    {!location.business && (
                      <IconButton onClick={() => handleDeleteConfirmation(location)} size="small">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationContainer
        totalItems={totalLocations}
        itemType="Locations"
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, newPage) => setPage(newPage)}
      />

      <ConfirmationDialog
        open={deleteConfirmation.open}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${deleteConfirmation.location?.name}?`}
        onConfirm={handleDeleteLocation}
        onCancel={() => setDeleteConfirmation({ open: false, location: null })}
        />
      <LocationModal
        open={isEditLocationModalOpen}
        onClose={() => setIsEditLocationModalOpen(false)}
        onLocationSaved={handleLocationUpdated}
        location={selectedLocation}
        mode='edit'
      />
      <LocationModal
        open={isCreateLocationModalOpen}
        onClose={() => setIsCreateLocationModalOpen(false)}
        onLocationSaved={handleLocationCreated}
        mode='create'
      />
    </Box>
  );
};
