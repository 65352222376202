import { useMemo } from 'react';

const useImageUrl = () => {
  const baseUrl = useMemo(() => {
    // Default to dev for images... for now
    // TODO: Support localhost testing with localstack S3 bucket
    let env = process.env.REACT_APP_ENVIRONMENT_NAME || 'dev';

    // Local should point at DEV S3 images bucket
    env = env === 'local' ? 'dev' : env;
    const subdomain = `images-${env}`;
    return `https://${subdomain}.hulahunt.com`;
  }, []);

  const getImageUrl = (imagePath: string) => {
    if (imagePath.startsWith('http')) {
      return imagePath;
    }
    return `${baseUrl}${imagePath.startsWith('/') ? '' : '/'}${imagePath}`;
  };

  return getImageUrl;
};

export default useImageUrl;