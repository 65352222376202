import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
 } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useRole } from '../../hooks/useRole';

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const { logout, isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  return (
    <header
      className={`flex justify-between items-center ${
        isMobile ? 'p-0' : 'p-4'
      }`}
    >
      <div className="flex items-center flex-shrink-0">
        <IconButton
          edge="start"
          aria-label="menu"
          onClick={handleMenuOpen}
          className="text-hulahunt-secondary p-0 mr-2"
          style={{
            width: isMobile ? '2rem' : '2.25rem',
            height: isMobile ? '2rem' : '2.25rem',
            marginRight: '0.5rem',
            marginLeft: '0'
        }}
        >
          <MenuIcon
            style={{
            width: isMobile ? '2rem' : '2.25rem',
            height: isMobile ? '2rem' : '2.25rem'
          }}
          className='text-hulahunt-secondary'
          />
        </IconButton>

        <h1
          className="font-bold
                    text-hulahunt-teal-700
                    font-hulahunt-display
                    whitespace-nowrap"
          onClick={() => navigate('/')}
          style={{
            cursor: 'pointer',
            fontSize: isMobile ? '1.5rem' : '2rem',
            paddingLeft: isMobile ? '0.3rem' : '1rem',
          }}
        >
          {t('app.title')}
        </h1>
      </div>
      {!isAuthenticated ? (
        <nav className="flex items-center space-x-1 sm:space-x-2 ml-2">
          <Button
            onClick={() => navigate('/signup')}
            variant="contained"
            size="small"
            className="bg-hulahunt-primary text-white"
            style={{
              fontSize: isMobile ? '0.75rem' : '1.2rem',
            }}
          >
            {t('app.buttons.signupOrLogin')}
          </Button>
        </nav>
      ) : (
        <div className='flex items-center ml-2' onClick={() => navigate('/profile')} style={{ cursor: 'pointer' }}>
          {user?.picture && (
            <Avatar
              src={user.picture}
              alt={user.firstName || user.email}
              className="mr-1 sm:mr-2"
              style={{ width: '1.5rem', height: '1.5rem' }}
            />
          )}
          <span className="text-xs sm:text-sm whitespace-nowrap">
            {user?.firstName ? `Hi, ${user.firstName}` : user?.email}
          </span>
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => { handleMenuClose(); navigate('/'); }}>{t('app.navMenu.about')}</MenuItem>
        {isAuthenticated && <MenuItem onClick={() => { handleMenuClose(); navigate('/profile'); }}>{t('app.navMenu.profile')}</MenuItem>}
        <MenuItem onClick={() => { handleMenuClose(); navigate('/findhunts'); }}>{t('app.navMenu.findHunts')}</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); navigate('/featuredbusinesses'); }}>{t('app.navMenu.featuredBusinesses')}</MenuItem>
        {isAuthenticated && <MenuItem onClick={() => { handleMenuClose(); navigate('/my-coupons'); }}>{t('app.navMenu.myCoupons')}</MenuItem>}
        {useRole() === 'business' &&
          (<MenuItem
          onClick={() => {
            handleMenuClose();
            navigate('/admin');
          }}
        >
          Business Admin
        </MenuItem>)}
        {useRole() === 'admin' && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate('/admin');
            }}
          >
            Admin
          </MenuItem>
        )}
        {isAuthenticated && (<MenuItem
          onClick={() => {
            handleMenuClose();
            navigate('/myhunts');
          }}
        >
            My Hunts
          </MenuItem>
        )}
        {isAuthenticated && <MenuItem onClick={handleLogout}>{t('app.navMenu.logout')}</MenuItem>}
      </Menu>
    </header>
  );
};

export default Header;