import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Paper,
} from '@mui/material';
import { Close as CloseIcon, NavigateNext as NavigateIcon, Business as BusinessIcon, LocalOffer as CouponIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Coupon } from '../../types/Coupon';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useAuth } from '../../context/AuthContext';
import { format } from 'date-fns';

interface SingleCouponDialogProps {
  open: boolean;
  onClose: () => void;
  coupon: Partial<Coupon>;
}

const SingleCouponDialog: React.FC<SingleCouponDialogProps> = ({ open, onClose, coupon }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const email = user?.email;

  const handleNavigate = () => {
    console.log(`handleNavigate: coupon: ${JSON.stringify(coupon, null, 2)}`);
    if (coupon.location) {
      const location = coupon.location;
      const address = `${location.streetAddress}, ${location.city}, ${location.state} ${location.zipCode}`;
      const encodedAddress = encodeURIComponent(address);
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent sx={{ p: 0 }}>
        <Card sx={{ position: 'relative' }}>
          {coupon.business?.imageUrl && (
            <CardMedia
              component="img"
              height={isMobile ? "150" : "250"}
              image={coupon.business?.imageUrl}
              alt={coupon.business?.name}
              sx={{
              objectFit: 'cover',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              }}
            />
          )}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent sx={{ textAlign: 'center' }}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
              <BusinessIcon color="primary" />
              <Typography variant="h6">{coupon.business?.name}</Typography>
            </Box>
            {coupon.location && (
              <>
              <Box sx={{ mb: 2, mt: -1 }}>
                <Typography variant="body2" align="center" color="text.secondary">
                  {coupon.location.streetAddress}<br />
                  {coupon.location.city}, {coupon.location.state} {coupon.location.zipCode}
                </Typography>
              </Box>
              </>
            )}

            {coupon.business?.welcomeMessage && (
              <Typography variant="body2" color="text.secondary" paragraph sx={{ fontStyle: 'italic' }}>
                {coupon.business.welcomeMessage}
              </Typography>
            )}

            <Divider sx={{ my: 2 }} />

            <Paper
              elevation={2}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                p: 2,
                my: 1
              }}
            >
              {/* Create repeating watermark pattern */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridTemplateRows: 'repeat(3, 1fr)',
                  pointerEvents: 'none',
                }}
              >
                {[...Array(9)].map((_, i) => (
                  <Typography
                    key={i}
                    sx={{
                      color: 'rgba(0, 0, 0, 0.1)',
                      fontSize: '1.4rem',
                      whiteSpace: 'nowrap',
                      transform: 'rotate(-45deg)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {format(new Date(), 'MM/dd/yyyy')}
                  </Typography>
                ))}
              </Box>

              <Box sx={{ position: 'relative' }}>
                {/* Content goes here - moved inside relative container */}
                <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                  <CouponIcon color="secondary" fontSize="large"/>
                  <Typography variant="h6" color="text.primary" sx={{ textShadow: '4px 4px 4px rgba(0, 128, 0, 0.35)' }}>
                    {t('myCoupons.redeemCouponFor', { email: email })}
                  </Typography>
                </Box>

                <Typography variant="body2" fontSize="1.1rem" color="text.secondary" paragraph>
                  {coupon.description}
                </Typography>

                <Box sx={{
                  bgcolor: 'background.paper',
                  p: 2,
                  borderRadius: 1,
                  width: '100%',
                  maxWidth: '300px',
                  mx: 'auto',
                  border: '2px dashed rgba(0, 0, 0, 0.2)',
                  position: 'relative',
                  zIndex: 1, // Ensures the coupon code box appears above watermark
                  '&::before': {  // Add a solid background to fully hide watermark
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'background.paper',
                    borderRadius: 'inherit',
                  }
                }}>
                  <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', textAlign: 'center', position: 'relative' }}>
                    {coupon.couponCode}
                  </Typography>
                </Box>
              </Box>
            </Paper>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<NavigateIcon />}
                onClick={handleNavigate}
                sx={{ width: '100%', maxWidth: '300px' }}
              >
                {t('common.navigate')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default SingleCouponDialog;
