import axios from 'axios';
import { getApiPath } from '../getApiPath';

export const checkCouponCode = async (huntId: number, couponCode: string): Promise<boolean> => {
  try {
    const response = await axios.post(getApiPath(`/hunt/checkCouponCode/${huntId}`), { couponCode }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking coupon code:', error);
    return false;
  }
};
