import axios from 'axios';
import { Coupon } from '../../types/Coupon';
import { getApiPath } from '../getApiPath';

export const getUserCoupons = async (userId: number, filterType?: string, filterId?: number): Promise<Coupon[]> => {
  try {
    const response = await axios.get<Coupon[]>(getApiPath('/coupon/getUserCoupons'), {
      params: {
        userId,
        filterType,
        filterId
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching user coupons:', error);
    throw error;
  }
};
