import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getApiPath } from '../services/getApiPath';
import { useNavigate } from 'react-router-dom';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface PurchaseItem {
  id: number;
  type: string;
}

interface UseStripePurchaseResult {
  isLoading: boolean;
  error: string | null;
  initiateCheckout: (item: PurchaseItem, couponCode?: string) => Promise<void>;
}

export const useStripePurchase = (): UseStripePurchaseResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const initiateCheckout = async (item: PurchaseItem, couponCode?: string) => {
    setIsLoading(true);
    setError(null);

    const token = localStorage.getItem('token');

    if (!token) {
      setError('User is not authenticated');
      setIsLoading(false);
      return;
    }

    try {
      const stripe = await stripePromise;

      // ON the backend, this will handle free hunts and coupon codes, avoiding Stripe altogether.
      const response = await fetch(getApiPath('stripe/create-checkout-session'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          itemId: item.id,
          itemType: item.type,
          couponCode,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const session = await response.json();

      // If the session.id starts with FREE_HUNT, we don't need to redirect to Stripe, just go to the purchase confirmation page
      // This doesn't actually PURCHASE anything, so it can't be "hacked", it just short circuits the purchase process
      // for free hunts.
      if (session.id.startsWith('FREE_HUNT')) {
        navigate(`/purchase-success?session_id=${session.id}`);
        return;
      }

      const result = await stripe!.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, initiateCheckout };
};