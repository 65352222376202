import axios from 'axios';
import { Location, UpdateLocationData } from '../../types/Location';
import { getApiPath } from '../getApiPath';

/**
 * Updates an existing location with optional hunt items.
 *
 * This function sends a PUT request to update an existing location. It can include
 * updated location details, a new image file, and optional hunt items. All data is sent
 * as FormData to support file upload and complex nested objects. Existing hunt items
 * can be updated, and new ones can be added.
 *
 * @param locationId - The ID of the location to update
 * @param locationData - The updated data for the location, including optional hunt items
 * @param locationImageFile - An optional new image file for the location
 * @returns A promise that resolves to the updated Location object
 */
export const updateLocation = async (locationId: number, locationData: UpdateLocationData, locationImageFile: File | null): Promise<Location> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const formData = new FormData();

  // Append each field of locationData to formData
  Object.entries(locationData).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (key === 'huntItems') {
        // Convert huntItems array to JSON string
        formData.append(key, JSON.stringify(value));
      } else if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value.toString());
      }
    }
  });

  if (locationImageFile) {
    formData.append('locationImage', locationImageFile);
  }

  try {
    const response = await axios.put(getApiPath(`/location/updateLocation/${locationId}`), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating location:', error);
    throw error;
  }
};