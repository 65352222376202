import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface PromotionalBannerProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

export const PromotionalBanner: React.FC<PromotionalBannerProps> = ({ message, open, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={7500}
      disableWindowBlurListener={true}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{
        timeout: {
          enter: 800,
          exit: 800
        }
      }}
      sx={{
        zIndex: 2000,
        width: { xs: '95%', md: '50%' },
        position: 'fixed',
        '& .MuiSnackbar-root': {
          position: 'fixed !important'
        }
      }}
    >
      <Alert
        severity="info"
        variant="filled"
        onClose={onClose}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          textAlign: 'center',
          width: '100%',
          position: 'relative',
          backgroundColor: '#000000',
          '& .MuiAlert-icon': {
            color: 'white',
            width: '5%',
            marginLeft: 0,
            marginRight: '0',
          },
          '& .MuiAlert-message': {
            color: 'white',
            width: '90%'
          },
          '& .MuiAlert-action': {
            color: 'white',
            width: '5%',
            marginLeft: '-20px',
            marginRight: '0',
            paddingTop: 0,
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem'
            }
          }
        }}
      >
        {/* Split message on \n into individual components so they appear on separate lines */}
        {message.split('\n').map((line, index, array) => (
          <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
          </React.Fragment>
        ))}
      </Alert>
    </Snackbar>
  );
};