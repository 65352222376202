import React, { useState, useEffect } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import useImageUrl from '../hooks/useImageUrl';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../hooks/useIsMobile';
import { PromotionalBanner } from '../components/common/PromotionalBanner';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const getImageUrl = useImageUrl();
  const [isLandscape, setIsLandscape] = useState(false);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [showPromo, setShowPromo] = useState(true);
  const promoEndDate = t('promotions.promoEndDate');

  useEffect(() => {
    if (promoEndDate && new Date(promoEndDate) < new Date()) {
      setShowPromo(false);
    }
  }, [promoEndDate]);

  useEffect(() => {
    const checkOrientation = () => {
      if (isMobile) {
        setIsLandscape(window.innerWidth > window.innerHeight);
      }
    };

    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, [isMobile]);

  return (
    <>
    <div
      className="min-h-screen bg-cover bg-center bg-fixed flex flex-col"
      style={{ backgroundImage: `url('${getImageUrl('/site/hulahunt_background.jpg')}')`}}
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME !== 'prod' && (
        <div className="fixed top-3 left-1/2 -translate-x-1/2 text-red-500 text-[14px] font-bold z-[2000] m-1">
          {process.env.REACT_APP_ENVIRONMENT_NAME?.toUpperCase()} environment
        </div>
      )}
      <main className="flex-grow flex justify-center items-stretch py-4 px-3">
        <div className="w-full max-w-screen-lg bg-white bg-opacity-90 rounded-lg shadow-lg overflow-hidden flex flex-col">
          <div className='pt-6 pb-3 px-6 flex flex-col h-[calc(100vh-32px)]'>
            <Header />
            <div className="flex-grow overflow-auto">
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </main>
      {isLandscape && (
        <div
          className='fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center'
          style={{ zIndex: 1000 }}
        >
          <div className="text-white text-center">
            <svg className="w-16 h-16 mx-auto mb-4" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            <p className="text-xl font-bold">{t('app.pleaseRotateYourDevice')}</p>
            <p>{t('app.thisAppIsBestViewedInPortraitMode')}</p>
          </div>
        </div>
      )}
    </div>
    <PromotionalBanner
        open={showPromo}
        onClose={() => setShowPromo(false)}
        message={t('promotions.welcome')}
      />
    </>
  );
};

export default MainLayout;
