import React from 'react';
import { MarkerF } from '@react-google-maps/api';

interface UserLocationMarkerProps {
  position: {
    lat: number;
    lng: number;
  };
}

export const UserLocationMarker: React.FC<UserLocationMarkerProps> = ({ position }) => {
  return (
    <MarkerF
      position={position}
      icon={{
        path: 'M12 0L14.645 8.355L23 11L14.645 13.645L12 22L9.355 13.645L1 11L9.355 8.355L12 0Z', // Star path
        fillColor: '#FFD700', // Yellow color
        fillOpacity: 1,
        strokeColor: '#000000',
        strokeWeight: 1,
        scale: 1.5,
        anchor: new google.maps.Point(12, 11), // Center the star
      }}
    />
  );
};