import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress, Button, Alert } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import PageLayout from '../../components/common/PageLayout';
import PasswordField from '../LoginSignup/PasswordField';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../services/resetPassword/resetPassword';
import { useAuth } from '../../context/AuthContext';

const PasswordReset: React.FC = () => {
  const { t } = useTranslation();
  const { setUserAndToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEmail(searchParams.get('email') || '');
    setToken(searchParams.get('token') || '');
  }, [location]);

  const passwordSchema = z.object({
    newPassword: z.string()
      .min(8, { message: t('loginsignup.passwordValidation.length') })
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
        message: t('loginsignup.passwordValidation.requirements'),
      }),
    confirmPassword: z.string(),
  }).refine((data) => data.newPassword === data.confirmPassword, {
    message: t('passwordReset.passwordsMismatch'),
    path: ['confirmPassword'],
  });

  type PasswordResetFormData = z.infer<typeof passwordSchema>;

  const { control, handleSubmit } = useForm<PasswordResetFormData>({
    resolver: zodResolver(passwordSchema),
  });

  const onSubmit = async (data: PasswordResetFormData) => {
    setIsLoading(true);
    setError(null);
    try {
      const { token: resetToken, user } = await resetPassword(token, data.newPassword, data.confirmPassword);
      if (resetToken && user) {
        setUserAndToken(user, resetToken);
        navigate('/findhunts');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(t('passwordReset.tokenExpired'));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PageLayout title={t('passwordReset.title')}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, maxWidth: 400, margin: 'auto' }}>
        <Typography><b>{t('passwordReset.email')}:</b> {email}</Typography>

        {error && (
          <Alert severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginTop: 5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mT: 1 }}>
            <PasswordField
              control={control}
              name="newPassword"
              label={t('passwordReset.newPassword')}
              autoComplete="new-password"
            />
            <PasswordField
              control={control}
              name="confirmPassword"
              label={t('passwordReset.confirmPassword')}
              autoComplete="new-password"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {t('passwordReset.submitChange')}
            </Button>
          </Box>
        </form>

        <Button
          variant='outlined'
          color='secondary'
          fullWidth
          onClick={() => navigate('/login')}
        >
          {t('passwordReset.backToLogin')}
        </Button>
      </Box>
    </PageLayout>
  );
};

export default PasswordReset;