import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { Hunt } from '../../../types/Hunt';
import { HuntItem } from '../../../types/HuntItem';
import { Location } from '../../../types/Location';
import { useIsMobile } from '../../../hooks/useIsMobile';

interface HuntItemsTableProps {
  formData: Partial<Hunt>;
  setFormData: React.Dispatch<React.SetStateAction<Partial<Hunt>>>;
  availableHuntItems: HuntItem[];
  locations: Location[];
  selectedLocationId: number | null;
  setSelectedLocationId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedHuntItemId: number | '';
  setSelectedHuntItemId: React.Dispatch<React.SetStateAction<number | ''>>;
  handleRemoveHuntItem: (itemId: number | undefined) => void;
  onAddHuntItem: (newItem: HuntItem) => void;
  setAvailableHuntItems: React.Dispatch<React.SetStateAction<HuntItem[]>>;
}

export const HuntItemsTable: React.FC<HuntItemsTableProps> = ({
  formData,
  availableHuntItems,
  locations,
  selectedLocationId,
  setSelectedLocationId,
  selectedHuntItemId,
  setSelectedHuntItemId,
  handleRemoveHuntItem,
  onAddHuntItem,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const filteredHuntItems = selectedLocationId === null
    ? availableHuntItems
    : availableHuntItems.filter(item =>
        selectedLocationId === 0
          ? !item.locationId
          : item.locationId === selectedLocationId
      );

  const handleAddHuntItem = () => {
    if (selectedHuntItemId) {
      const itemToAdd = availableHuntItems.find(item => item.id === selectedHuntItemId);
      if (itemToAdd && itemToAdd.id) {
        onAddHuntItem(itemToAdd);
        setSelectedHuntItemId('');
      }
    }
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('admin.hunts.itemTitle')}</TableCell>
            <TableCell align="right">{t('common.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formData.huntItems && formData.huntItems.length > 0 ? (
            formData.huntItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell align="right">
                  <IconButton
                    type="button"
                    aria-label="delete"
                    size="small"
                    onClick={() => handleRemoveHuntItem(item.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} align="center">
                <em>{t('admin.hunts.noHuntItems')}</em>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom fontSize={isMobile ? 14 : 16}>
          {t('admin.hunts.addNewHuntItems')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 2 }}>
          <FormControl sx={{ minWidth: 200, mr: 2, mb: 2, mt: 1 }} fullWidth={isMobile}>
            <InputLabel id="location-select-label" sx={{ mt: -1,display: 'flex', alignItems: 'center' }}>
              {t('admin.hunts.selectLocation')}
            </InputLabel>
            <Select
              labelId="location-select-label"
              id="location-select"
              value={selectedLocationId === null ? '' : selectedLocationId}
              label={t('admin.hunts.selectLocation')}
              onChange={(e) => setSelectedLocationId(e.target.value as number)}
              size="small"
              sx={{ height: '40px' }}
            >
              {locations.map((location) => (
                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200, mr: 2, mb: 2, mt: 1 }} fullWidth={isMobile}>
            <InputLabel id="add-hunt-item-label" sx={{ mt: -1, display: 'flex', alignItems: 'center' }}>
              {t('admin.hunts.selectHuntItem')}
            </InputLabel>
            <Select
              labelId="add-hunt-item-label"
              id="add-hunt-item"
              value={selectedHuntItemId}
              label={t('admin.hunts.addHuntItem')}
              onChange={(e) => setSelectedHuntItemId(e.target.value as number)}
              size="small"
              sx={{ height: '40px' }}
              disabled={selectedLocationId === null}
            >
              {filteredHuntItems.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="button"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddHuntItem}
            size="medium"
            fullWidth={isMobile}
            sx={{ height: '40px', mb: 2 }}
            disabled={!selectedHuntItemId}
          >
            {t('admin.hunts.addHuntItem')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};