import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Link } from '@mui/material';
import { Search, Room, LocalOffer, Business } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../components/common/PageLayout';
import { styled } from '@mui/material/styles';
import useImageUrl from '../../hooks/useImageUrl';
import WhatMakesUsDifferentModal from './WhatMakesUsDifferentModal';
import { alpha } from '@mui/material/styles';

const FeatureCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: 8,
  transition: 'all 0.3s ease-in-out',
  cursor: 'default',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 2,
  border: `1px solid ${alpha(theme.palette.grey[300], 0.5)}`,
  boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.05)}`,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 8px 30px ${alpha(theme.palette.common.black, 0.1)}`,
    borderColor: theme.palette.secondary.light,
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: 'auto',
  alignSelf: 'center',
  color: theme.palette.secondary.main,
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius * 1.5,
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    transform: 'scale(1.05)',
    transition: 'transform 0.2s ease-in-out',
  },
}));

const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const getImageUrl = useImageUrl();
  const [showWhatMakesUsDifferent, setShowWhatMakesUsDifferent] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <PageLayout>
      <Box className="text-center relative">
        <Box className="mb-3 rounded-lg overflow-hidden shadow-lg relative lg:h-72 md:h-48">
          <img
            src={getImageUrl('https://images-prod.hulahunt.com/site/hawaii.jpg')}
            alt="Hawaii"
            className="w-full h-full object-cover"
            style={{
              transform: `translateY(${scrollY * 0.5}px)`,
              transition: 'transform 0.5s ease-out',
            }}
          />
          <Box
            className="absolute inset-0 opacity-100"
          />
        </Box>

        <Typography
          variant="body1"
          component="h1" // Using h1 for SEO while keeping body1 styling
          className="text-md text-hulahunt-teal-800 mb-4"
          style={{ fontSize: 'clamp(1rem, 2.5vw, 1.1rem)' }}
        >
          {t('app.description.prefix')}{' '}
          <Link
            component="a"
            href="/featuredbusinesses"
            className="text-hulahunt-secondary italic hover:text-hulahunt-primary transition-colors duration-300"
          >
            {t('app.description.offers')}
          </Link>
        </Typography>

        {/* Single CTA Button */}
        <Box className="flex justify-center mb-4">
            <Button
                component="a"
                href="/findhunts"
                variant="contained"
                size="large"
                className="bg-hulahunt-primary text-white normal-case w-[90%] md:w-[300px] hover:bg-hulahunt-secondary transition-colors duration-300 shadow-lg hover:shadow-xl"
                >
                {t('app.buttons.startAdventure')}
            </Button>
        </Box>

        <Typography variant="body2" className="mb-4">
          <Link
            component="button"
            onClick={() => setShowWhatMakesUsDifferent(true)}
            className="text-hulahunt-secondary italic hover:text-hulahunt-primary transition-colors duration-300"
          >
            {t('whatMakesUsDifferent.title')}
          </Link>
        </Typography>


        {/* How it works section cards*/}
        <Box className="mb-4 px-2">
          <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 max-w-7xl mx-auto">
            {[
              {
                key: 'find',
                icon: <Search className="text-hulahunt-secondary text-3xl" />,
              },
              {
                key: 'explore',
                icon: <Room className="text-hulahunt-secondary text-3xl" />
              },
              {
                key: 'coupons',
                icon: <LocalOffer className="text-hulahunt-secondary text-3xl" />,
                action: () => navigate('/featuredbusinesses'),
                actionText: t('howItWorks.steps.coupons.viewFeaturedBusinesses')
              },
              {
                key: 'business',
                icon: <Business className="text-hulahunt-secondary text-3xl" />,
                action: () => window.location.href = 'mailto:derick@hulahunt.com',
                actionText: t('howItWorks.steps.business.contactUs')
              }
            ].map(({ key, icon, action, actionText }) => (
              <FeatureCard key={key}>
                <Box className="flex items-center justify-center mb-3">
                  <Box className="flex items-center">
                    {icon}
                    <Typography variant="h6" className="font-semibold text-hulahunt-teal-700 ml-2">
                      {t(`howItWorks.steps.${key}.title`)}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  className="text-hulahunt-teal-600 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: t(`howItWorks.steps.${key}.expandedDescription`)
                  }}
                />
                {action && actionText && (
                  <ActionButton
                    onClick={action}
                    className="mt-auto mx-auto"
                  >
                    {actionText}
                  </ActionButton>
                )}
              </FeatureCard>
            ))}
          </Box>
        </Box>
      </Box>

      <WhatMakesUsDifferentModal
        open={showWhatMakesUsDifferent}
        onClose={() => setShowWhatMakesUsDifferent(false)}
      />
    </PageLayout>
  );
};

export default LandingPage;
