import React from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PaginationContainerProps {
    totalItems: number;
    itemType: string;
    role?: string;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: unknown, newPage: number) => void;
  }

  export const PaginationContainer: React.FC<PaginationContainerProps> = ({
    totalItems,
    itemType,
    role = '',
    page,
    rowsPerPage,
    onPageChange
  }) => {
    const { t } = useTranslation();

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        mb: 2,
        mt: -2
      }}>
        <Typography
          variant="caption"
          sx={{
            color: '#444',
            fontSize: '0.75rem'
          }}
        >
          {t('common.pagination.totalCountLabel', {
            role: role ? role.charAt(0).toUpperCase() + role.slice(1) : '',
            itemType
          })}: {totalItems}
        </Typography>
        <Pagination
          count={Math.ceil(totalItems / rowsPerPage)}
          page={page}
          siblingCount={0}
          onChange={onPageChange}
        />
      </Box>
    );
  };