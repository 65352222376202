import axios from 'axios';
import { Business } from '../../types/Business';
import { getApiPath } from '../getApiPath';

export const updateBusiness = async (businessId: number, formData: FormData): Promise<Business> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }
  const response = await axios.put(getApiPath(`/business/updateBusiness/${businessId}`), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};