import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile';

interface EllipsisTypographyProps extends TypographyProps {
  text: string | undefined;
  mobileLineLimit: number;
}

export const EllipsisTypography: React.FC<EllipsisTypographyProps> = ({
  text,
  mobileLineLimit,
  ...typographyProps
}) => {
  const isMobile = useIsMobile();
  return (
    <Typography
      {...typographyProps}
      sx={{
        ...(isMobile && {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: mobileLineLimit,
          WebkitBoxOrient: 'vertical',
        }),
        ...typographyProps.sx,
      }}
    >
      {text}
    </Typography>
  );
}

export default EllipsisTypography;