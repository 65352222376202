import axios from 'axios';
import { HuntItem } from '../../types/HuntItem';
import { getApiPath } from '../getApiPath';

interface GetHuntItemsResponse {
  huntItems: HuntItem[];
  currentPage: number;
  totalPages: number;
  totalHuntItems: number;
  locationId: number;
}

export type SortColumn = 'title' | 'itemType' | 'createdAt';
export type SortOrder = 'ASC' | 'DESC';

export const getHuntItems = async (
  page: number = 1,
  limit: number = 1000, // Get all hunt items in one page if no limit passed
  searchString?: string,
  sortColumn?: SortColumn,
  sortOrder: SortOrder = 'ASC',
  locationId?: number | null,
): Promise<GetHuntItemsResponse> => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await axios.get<GetHuntItemsResponse>(getApiPath('/huntitem/getHuntItems'), {
      params: {
        page,
        limit,
        searchString,
        sortColumn,
        sortOrder,
        locationId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        throw new Error(error.response.data.message || 'An error occurred while fetching hunt items');
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('No response received from server');
      } else {
        console.error('Error setting up the request:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};