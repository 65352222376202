// These are actually imported and used elsewhere, but eslint doesn't like them.
export enum UserHuntStatus {
  // eslint-disable-next-line no-unused-vars
  Purchased = "purchased",
  // eslint-disable-next-line no-unused-vars
  InProgress = "in_progress",
  // eslint-disable-next-line no-unused-vars
  Finished = "finished"
}

export type UserHunt = {
  id: number;
  userId: number;
  huntId: number;
  startTime: Date;
  endTime: Date;
  maxHuntHours: number;
  finishedTime: Date;
  status: UserHuntStatus;
  createdAt: Date;
  updatedAt: Date;
  userEmail: string;
  huntName: string;
  huntDescription: string;
  huntImageUrl: string;
  completedItemCount?: number;
  totalItemCount?: number;
};
