import { Coupon } from './Coupon';

// Export an array of allowed business types
export const allowedBusinessTypes = ['Food & Beverage', 'Retail', 'Hospitality & Travel', 'Health & Wellness', 'Entertainment & Recreation', 'Other'];

export interface Location {
  id: number;
  name: string;
  description?: string;
  imageUrl?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
  areaName?: string;
}

export interface Business {
  id: number;
  name: string;
  description?: string;
  imageUrl?: string;
  contactEmail?: string;
  phoneNumber?: string;
  website?: string;
  welcomeMessage?: string;
  isVerified: boolean;
  businessType?: string;
  locations?: Location[];
  coupons?: Coupon[]; // Now using the imported Coupon type
}

export type SortColumn = 'name' | 'createdAt';
export type SortOrder = 'ASC' | 'DESC';