import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getBusinesses } from '../../../../services/business/getBusinesses';
import { createBusiness } from '../../../../services/business/createBusiness';
import { updateBusiness } from '../../../../services/business/updateBusiness';
import { Business } from '../../../../types/Business';
import { useGoogleMapsScript } from '../../../../hooks/useGoogleMapsScript';
import { Coupon, NewCoupon } from '../../../../types/Coupon';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import { useAuth } from '../../../../context/AuthContext';
import { BusinessInfoForm } from '../shared/BusinessInfoForm';
import { BusinessFormData } from '../shared/types';
import { LocationInfoForm } from '../shared/LocationInfoForm';
import { CouponInfoForm } from '../shared/CouponInfoForm';
import { createBusinessSchema } from '../shared/validation';

const createFormValuesFromBusiness = (fetchedBusiness: Business): BusinessFormData => {
  return {
    name: fetchedBusiness.name,
    contactEmail: fetchedBusiness.contactEmail || '',
    phoneNumber: fetchedBusiness.phoneNumber || '',
    website: fetchedBusiness.website || '',
    description: fetchedBusiness.description || '',
    welcomeMessage: fetchedBusiness.welcomeMessage || '',
    businessImage: fetchedBusiness.imageUrl || null,
    businessType: fetchedBusiness.businessType || 'Other',
    location: {
      name: fetchedBusiness.locations?.[0]?.name || '',
      streetAddress: fetchedBusiness.locations?.[0]?.streetAddress || '',
      city: fetchedBusiness.locations?.[0]?.city || '',
      state: fetchedBusiness.locations?.[0]?.state || '',
      zipCode: fetchedBusiness.locations?.[0]?.zipCode || '',
      areaName: fetchedBusiness.locations?.[0]?.areaName || '',
      description: fetchedBusiness.locations?.[0]?.description || '',
      latitude: fetchedBusiness.locations?.[0]?.latitude || 0,
      longitude: fetchedBusiness.locations?.[0]?.longitude || 0,
      locationImage: fetchedBusiness.locations?.[0]?.imageUrl || null,
    },
  };
};

export const BusinessUserPage: React.FC = () => {
  const { t } = useTranslation();
  const [business, setBusiness] = useState<Business | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const businessSchema = createBusinessSchema(t);
  const [businessImageFile, setBusinessImageFile] = useState<File | null>(null);
  const [locationImageFile, setLocationImageFile] = useState<File | null>(null);
  const { user } = useAuth();
  const [originalValues, setOriginalValues] = useState<BusinessFormData | null>(null);

  const { isLoaded, loadError } = useGoogleMapsScript();

  const [hasUnsavedCouponChanges, setHasUnsavedCouponChanges] = useState(false);

  const [pendingCoupons, setPendingCoupons] = useState<(Coupon | NewCoupon)[]>([]);

  const { control, handleSubmit, formState: { errors, isValid, isDirty }, setValue, trigger, reset, getValues } = useForm<BusinessFormData>({
    resolver: zodResolver(businessSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      contactEmail: user?.email || '',
      phoneNumber: '',
      website: '',
      description: '',
      welcomeMessage: '',
      businessImage: null,
      businessType: '',
      location: {
        name: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        areaName: '',
        description: '',
        latitude: 0,
        longitude: 0,
        locationImage: null,
      },
    },
  });

  useEffect(() => {
    getBusinesses(1, 1).then((data) => {
      if (data.businesses.length > 0) {
        const fetchedBusiness = data.businesses[0];
        setBusiness(fetchedBusiness);

        const formValues = createFormValuesFromBusiness(fetchedBusiness);
        reset(formValues);
        setOriginalValues(formValues);
        trigger();
      } else {
        reset({ contactEmail: user?.email || '' });
      }
      setIsLoading(false);
    });
  }, [reset, trigger, user]);


  const handleBusinessImageCapture = (file: File | null) => {
    setBusinessImageFile(file);
  };

  const handleLocationImageFileChange = (file: File | null) => {
    setLocationImageFile(file);
  };

  const onSubmit = async (data: BusinessFormData) => {
    setIsLoading(true);

    const businessData = {
      ...data,
      locations: [{
        ...data.location,
        locationImage: undefined
      }],
      coupons: business ? business.coupons : pendingCoupons,
      businessImage: undefined
    };

    const formDataToSend = new FormData();
    formDataToSend.append('business', JSON.stringify(businessData));

    if (businessImageFile) {
      formDataToSend.append('businessImage', businessImageFile);
    }

    if (locationImageFile) {
      formDataToSend.append('locationImage', locationImageFile);
    }

    try {
      if (business) {
        await updateBusiness(business.id, formDataToSend);
      } else {
        await createBusiness(formDataToSend);
      }
      const updatedData = await getBusinesses(1, 1);
      if (updatedData.businesses.length > 0) {
        const fetchedBusiness = updatedData.businesses[0];
        setBusiness(fetchedBusiness);

        const formValues = createFormValuesFromBusiness(fetchedBusiness);
        reset(formValues);
        setOriginalValues(formValues);

        setBusinessImageFile(null);
        setLocationImageFile(null);
      }
    } catch (error) {
      console.error('Error saving business:', error);
    } finally {
      setIsLoading(false);
    }
    setHasUnsavedCouponChanges(false);
  };

  const handleCouponsChange = (updatedCoupons: (Coupon | NewCoupon)[]) => {
    if (business) {
      const updatedBusiness = { ...business, coupons: updatedCoupons as Coupon[] };
      setBusiness(updatedBusiness);
    } else {
      setPendingCoupons(updatedCoupons);
    }
    setHasUnsavedCouponChanges(true);
  };

  const handleRevertChanges = async () => {
    setIsLoading(true);
    try {
      const data = await getBusinesses(1, 1);
      if (data.businesses.length > 0) {
        const fetchedBusiness = data.businesses[0];
        setBusiness(fetchedBusiness);

        const formValues = createFormValuesFromBusiness(fetchedBusiness);
        reset(formValues);
      } else {
        reset();
        setValue('location.latitude', 0);
        setValue('location.longitude', 0);
        setBusiness(null);
      }
    } catch (error) {
      console.error('Error fetching business data:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
    trigger();
  };

  const hasChanges = () => {
    const hasImageChanges = businessImageFile !== null || locationImageFile !== null;
    return isDirty || hasImageChanges || hasUnsavedCouponChanges;
  };

  const SubmitButtons = () => (
    <Grid container spacing={2} sx={{ mt: -2, mb: 4 }}>
      <Grid item xs={12} md={6}>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || !isValid || !hasChanges()}
          fullWidth
        >
          {isLoading ? <CircularProgress size={24} /> : (business ? t('admin.businesses.businessUser.updateBusiness') : t('admin.businesses.businessUser.createBusiness'))}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          variant="outlined"
          onClick={handleRevertChanges}
          disabled={!hasChanges()}
          fullWidth
        >
          {t('common.revertAllChanges')}
        </Button>
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        mt: 4
      }}>
        <CircularProgress />
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('admin.businesses.businessUser.savingChanges')}
        </Typography>
      </Box>
    );
  }

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
      {!business && (
        <Typography variant="h6" sx={{ mb: 3, textAlign: 'center', fontStyle: 'italic' }}>
          {t('admin.businesses.businessUser.createBusinessToStart')}
        </Typography>
      )}

      <SubmitButtons />

      <BusinessInfoForm
        control={control}
        errors={errors}
        onBusinessImageFileChange={handleBusinessImageCapture}
        originalValues={originalValues}
      />

      <LocationInfoForm
        control={control}
        errors={errors}
        originalValues={originalValues}
        onLocationImageFileChange={handleLocationImageFileChange}
        setValue={setValue}
        trigger={trigger}
        getValues={getValues}
      />

      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>{t('admin.businesses.businessUser.yourCoupons')}</Typography>
        <CouponInfoForm
          t={t}
          coupons={business?.coupons || pendingCoupons}
          business={business}
          onCouponsChange={handleCouponsChange}
        />
      </Paper>

      <SubmitButtons />
    </Box>
  );
};
