import axios from 'axios';
import { getApiPath } from '../getApiPath';

interface UserIssueReport {
  name: string;
  emailFrom: string;
  issueDescription: string;
}

/**
 * Sends a user issue report email.
 *
 * This function sends a POST request to report a user issue. It includes
 * the user's name, email, and a description of the issue.
 *
 * @param issueData - The data for the issue report
 * @returns A promise that resolves to the server response
 */
export const sendUserIssueReportEmail = async (issueData: UserIssueReport): Promise<{ message: string }> => {
  try {
    const response = await axios.post(getApiPath('/sendUserIssueEmail'), issueData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending user issue report:', error);
    throw error;
  }
};