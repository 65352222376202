import { useRole } from './useRole';
import Roles from '../types/Roles';

type Role = typeof Roles[keyof typeof Roles];

const roleHierarchy: Record<Role, Role[]> = {
  [Roles.Admin]: [Roles.Admin, Roles.Business, Roles.Consumer],
  [Roles.Business]: [Roles.Business, Roles.Consumer],
  [Roles.Consumer]: [Roles.Consumer],
};

export function useHasRole(requiredRole: Role | undefined): boolean {
  const userRole = useRole();

  if (!requiredRole) {
    return true;
  }

  if (!userRole) {
    return roleHierarchy[requiredRole]?.includes(Roles.Consumer) ?? false;
  }

  return roleHierarchy[userRole]?.includes(requiredRole) ?? false;
}